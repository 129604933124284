import React from 'react';
import { inject, observer } from 'mobx-react';
import { AdminModel } from 'graphql/models/admin';
import { RouteComponentProps } from 'react-router';
import { UIStore, AdminStore, UserStore } from 'stores';

import { CenterBanner, TopContentBar, CustomSelect, TableLoader, NoData } from 'components';

import {
  STORE_UI,
  STORE_ADMIN,
  STORE_USER,
  CREATE_SUPER_ADMIN_MODAL,
  EDIT_SUPER_ADMIN_MODAL,
  DELETE_ACCEPTANCE_MODAL,
  SETTINGS_TABS,
  FORM_MODAL_WIDTH
} from 'appConstants';

import { history } from 'index';
import classNames from 'classnames';
import styles from './SuperAdminsPage.module.scss';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_ADMIN]: AdminStore;
  [STORE_USER]: UserStore;
}

const selectItems = [
  {title: 'Edit', value: 'edit'},
  {title: 'Delete', value: 'delete'}
];

const mySelectItems = [
  {title: 'Edit', value: 'edit'}
];

@inject(STORE_UI, STORE_ADMIN, STORE_USER)
@observer

class SuperAdminsPage extends React.Component<Props, {}>{

  componentDidMount(): void {
    this.props[STORE_ADMIN].loadAdminsList();
  }

  createNewSuperAdmin = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: CREATE_SUPER_ADMIN_MODAL,
      title: 'Add admin user',
    });
  };

  editSuperAdmin = (superAdmin: AdminModel) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: EDIT_SUPER_ADMIN_MODAL,
      title: 'Edit admin user',
      props: {
        superAdminData: superAdmin
      }
    });
  };

  deleteSuperAdmin = (superAdmin: AdminModel) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: DELETE_ACCEPTANCE_MODAL,
      title: 'Delete admin user',
      props: {
        description: "Are you sure you want to delete this admin user? You won’t be able to undo this."
      },
      eventProps: {
        onSubmit: () => this.onSubmitDeleteSuperAdmin(superAdmin.email)
      }
    });
  };

  onSubmitDeleteSuperAdmin = async (superAdminEmail: string) => {
    this.props[STORE_UI].setLoadModalData();
    await this.props[STORE_ADMIN].deleteSuperAdmin(superAdminEmail);
    this.props[STORE_UI].setLoadModalData(false);

    if(!this.props[STORE_UI].fetchError){
      this.props[STORE_UI].closeModal();
    }
  };

  selectActions(action: string, superAdmin: AdminModel){
    switch(action) {
      case 'edit':  this.editSuperAdmin(superAdmin);
        break;
      case 'delete':  this.deleteSuperAdmin(superAdmin);
        break;
    }
  }

  onTabAction = (val: string) => {
    history.push("/admin/"+val);
  };

  getSelectItems(adminEmail: string) {
    if (this.props[STORE_USER].user !== null) {
      let myUserEmail = this.props[STORE_USER].user.username;
      return myUserEmail === adminEmail ? mySelectItems : selectItems
    } else {
      return selectItems;
    }
  }

  renderSuperAdminsItems(){
    let superAdmins = this.props[STORE_ADMIN].adminsList || [];
    if(superAdmins.length){
      return superAdmins.map((superAdmin)=>
        <tr key={superAdmin.id}>
          <td>{superAdmin.firstName+" "+superAdmin.lastName}</td>
          <td>{superAdmin.email}</td>
          <td className={styles.actionCell}>
            <CustomSelect
              items={this.getSelectItems(superAdmin.email)}
              onClickOption={(action)=>{this.selectActions(action, superAdmin)}}
            />
          </td>
        </tr>
      );
    } else return <NoData columnSpan={3} />
  }

  renderSuperAdmins() {
    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
        <tr>
          <th className={styles.cuCol3}>Name</th>
          <th>Email</th>
          <th className={styles.cuCol2}>Action</th>
        </tr>
        </thead>
        <tbody>
          {this.props[STORE_ADMIN].loadAdminsState?<TableLoader columnSpan={3} />:this.renderSuperAdminsItems()}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <>
        <CenterBanner
          mainTitle={"Settings"}
          tabs={SETTINGS_TABS}
          activeTab={"super-admins"}
          onClickTab={this.onTabAction}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={"Super admins"}
            actionButtonName={"Create new"}
            actionButtonEvent={this.createNewSuperAdmin}
          />
          {this.renderSuperAdmins()}
        </div>
      </>
    );
  }

}
export default SuperAdminsPage;