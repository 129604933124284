import { Auth } from 'aws-amplify';
export const awsExports = {
  Auth: {
    region: process.env.REACT_APP_GOGNITO_REGION,
    userPoolId: process.env.REACT_APP_GOGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    production: false,
    authenticationFlowType: 'CUSTOM_AUTH',
    scope: 'openid profile email',
    oauth: {
      domain:
        process.env.REACT_APP_COMPANY_PREFIX +
        '-' +
        process.env.REACT_APP_NODE_ENV +
        '.auth.' +
        process.env.REACT_APP_GOGNITO_REGION +
        '.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
      redirectSignOut: process.env.REACT_APP_REDIRECT_URL,
      responseType: 'token',
    },
  },
  API: {
    aws_appsync_graphqlEndpoint: '',
    aws_appsync_region: process.env.REACT_APP_GOGNITO_REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    graphql_headers: async () => {
      return { Authorization: ((await Auth.currentSession()) as any).accessToken.jwtToken };
    },
    endpoints: [
      {
        name: 'default',
        endpoint: process.env.REACT_APP_GRAPHQL_URL,
      },
      {
        name: 'admin',
        endpoint: process.env.REACT_APP_ADMIN_GRAPHQL_URL,
      },
    ],
  },
};
