import React, { ChangeEvent } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  TopContentBar,
  CustomFieldAdv,
  CustomFormSelect,
  TableLoader,
  NoData,
  CustomCheckbox,
  CenterBanner
} from 'components';
import { Formik, FieldArray, FormikActions, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router';
import { UIStore, HrStore, UserStore, EmployerStore } from 'stores';
import scrollToElement from 'scroll-to-element';

import {
  STORE_UI,
  STORE_HR,
  STORE_USER,
  STORE_EMPLOYER,
  HR_NSU_STATUS_OPTIONS,
  HR_APPROVED_STATUS_OPTIONS,
  HR_REJECTED_STATUS_OPTIONS,
  HR_LEAVERS_STATUS_OPTIONS,
  COUNT_SCROLL_ITEMS
} from 'appConstants';

import { history } from 'index';
import classNames from 'classnames';
import { SelectOption, employeeData } from 'interfaces';
import styles from './HrEmployeesPage.module.scss';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_HR]: HrStore;
  [STORE_USER]: UserStore;
  [STORE_EMPLOYER]: EmployerStore;
}

interface FormValues {
  employeeList: employeeData[];
}

interface searchCriteria {
  searchText: string,
  employerId: number,
  status: string
}

export interface State {
  enableSubmitBtn: boolean
  activeTab: string
  checked: boolean | undefined
  checkDirty: boolean
}

@inject(STORE_UI, STORE_HR, STORE_USER, STORE_EMPLOYER)
@observer

class HrEmployeesPage extends React.Component<Props, State> {

  formHandleSubmit = () => {};

  formReset = () => {};

  HR_STATUS_OPTIONS = HR_NSU_STATUS_OPTIONS;
  defaultStatus = HR_NSU_STATUS_OPTIONS[0].value;

  state = {
    enableSubmitBtn: false,
    checked: false,
    activeTab: 'new-sign-ups',
    checkDirty: false
  };

  mainTitle: string = '';
  initialValues: FormValues = {
    employeeList: []
  };

  payrollGroupsOptions: SelectOption[] = [];

  searchCriteria = {
    employerId: Number(this.props[STORE_EMPLOYER].currentEmployer.id),
    searchText: '',
    status: ''
  };

  searchText: string = '';

  formData: any;

  scrollTop: number = 0;
  scrollBottom: number = 0;
  isLoadedItems: boolean = false;
  pageNumber: number = 1;

  nextListEnd: number = COUNT_SCROLL_ITEMS;
  nextListStart: number = 0;

  async componentDidMount() {
    const { tabId } = this.props.match.params;
    this.setState({ activeTab: tabId });

    const { username } = this.props[STORE_USER].user;
    await this.props[STORE_EMPLOYER].loadEmployerByHrEmail(username);
    if (tabId !== 'new-sign-ups') {
      await this.props[STORE_HR].loadNewSignUpsCount(Number(this.props[STORE_EMPLOYER].currentEmployer.id));
    }
    this.props[STORE_HR].loadHrTabs();

    this.initialState();
    this.setState({ checked: false });
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentDidUpdate(nextProps: any) {
    if (this.props.match.params.tabId !== nextProps.match.params.tabId) {
      this.initialState();
      this.setState({ checked: false });
    }
  }

  componentWillUnmount(): void {
    this.setState({});
    this.initialValues = {
      employeeList: []
    };
    this.formHandleSubmit = () => {};
    this.formReset = () => {};

    document.removeEventListener('scroll', this.trackScrolling);
  }

  initialState() {
    const { tabId } = this.props.match.params;
    this.setDefaultStatus(tabId);

    if (tabId !== 'leavers') {
      this.setPayrollGroupsOptions();
    }

    this.searchCriteria = {
      employerId: Number(this.props[STORE_EMPLOYER].currentEmployer.id),
      searchText: '',
      status: this.defaultStatus
    };

    this.findEmployees(this.searchCriteria);
    this.nextListEnd = COUNT_SCROLL_ITEMS;
    this.nextListStart = 0;
  }

  setDefaultStatus(tabId: string) {
    switch (tabId) {
      case 'new-sign-ups':
        this.HR_STATUS_OPTIONS = HR_NSU_STATUS_OPTIONS;
        break;
      case 'approved':
        this.HR_STATUS_OPTIONS = HR_APPROVED_STATUS_OPTIONS;
        break;
      case 'rejected':
        this.HR_STATUS_OPTIONS = HR_REJECTED_STATUS_OPTIONS;
        break;
      case 'leavers':
        this.HR_STATUS_OPTIONS = HR_LEAVERS_STATUS_OPTIONS;
        break;
    }

    this.defaultStatus = this.HR_STATUS_OPTIONS[0].value;
  }

  getTabTitle() {
    const { tabId } = this.props.match.params;
    switch (tabId) {
      case 'new-sign-ups':
        return `New sign-ups (${this.props[STORE_HR].newSignUpsCount})`;
      case 'approved':
        return 'Approved';
      case 'rejected':
        return 'Rejected';
      case 'leavers':
        return 'Leavers';
      default:
        return '';
    }
  }

  setPayrollGroupsOptions() {
    let payrollGroups = this.props[STORE_EMPLOYER].currentEmployer.payrollGroups || [];
    this.payrollGroupsOptions = payrollGroups.map((group) => {
      return { value: group.id, label: group.name }
    });
  }

  async findEmployees(searchCriteria: searchCriteria) {
    this.formReset = () => {
    };
    this.props[STORE_HR].setEmployeeLoad(true);
    await this.props[STORE_HR].findEmployees(searchCriteria);

    this.initialValues = {
      employeeList: toJS(this.props[STORE_HR].employees)
    };

    this.props[STORE_HR].setEmployeeLoad(false);
  }

  onTabAction = (val: string) => {
    this.setState({ activeTab: val });
    this.setState({ enableSubmitBtn: false });
    history.push('/hr/' + val);
  };

  onSubmit = async (submittedValues: FormValues, actions: FormikActions<FormValues>) => {
    this.props[STORE_HR].setEmployeeLoad(true);

    const formattedEmployees = this.props[STORE_HR].getEmployeesForSubmit(submittedValues, this.defaultStatus);
    await this.props[STORE_HR].updateEmployees(formattedEmployees);

    this.setState({ checked: false });

    if (this.props[STORE_HR].fetchError) {
      this.props[STORE_UI].showErrorPopup(this.props[STORE_HR].fetchError);
      this.props[STORE_HR].setEmployeeLoad(false);
    } else {
      await this.props[STORE_HR].loadNewSignUpsCount(Number(this.props[STORE_EMPLOYER].currentEmployer.id));
      this.props[STORE_HR].loadHrTabs();
      this.findEmployees(this.searchCriteria);
      this.setState({ enableSubmitBtn: false });
      this.props[STORE_HR].setEmployeeLoad(true);
    }
  };

  getOrderCreateDate(customAttributes: { key: string, value: string }[]) {
    let orderCreatedDate = customAttributes.find((currentValue) => {
      return currentValue.key === 'orderCreatedDate';
    });
    return orderCreatedDate ? this.props[STORE_UI].getFormattedDate(orderCreatedDate.value) : ''
  }

  selectHandler(name: string, value: SelectOption, form: FormikProps<FormValues>, employee: any, fieldType: string) {
    form.setFieldValue(name, value);
    if (employee.checker) {
      form.values.employeeList.forEach((item, index) => {
        if (item.checker) {
          form.setFieldValue(`employeeList[${index}].${fieldType}`, value);
        }
      });
    }

    this.setState({
      enableSubmitBtn: true
    });
  }

  renderPayrollGroupField(form: FormikProps<FormValues>, index: number, employee: any) {
    let hrPayrollGroupControlName = `employeeList[${index}].payrollGroup`;
    let hrPayrollGroupDefaultValue = this.payrollGroupsOptions ? this.payrollGroupsOptions.find(option => option.value === employee.payrollGroupId) : '';
    return (
      <td className={styles.payrollCol}>
        <CustomFieldAdv
          name={hrPayrollGroupControlName}
          title=''
          placeholder='–Please select–'
          form={form}
          value={form.values.employeeList[index].payrollGroup}
          defaultValue={hrPayrollGroupDefaultValue}
          component={CustomFormSelect}
          className={styles.tableFormItem}
          options={this.payrollGroupsOptions}
          onSelect={(name, value) => this.selectHandler(name, value, form, employee, 'payrollGroup')}
          onBlur={() => {
            form.setFieldTouched(hrPayrollGroupControlName)
          }}
          onKeyDown={(e: KeyboardEvent) => {
            this.props[STORE_UI].handleFormKeyPress(e, form)
          }}
        />
      </td>
    )
  }

  renderStatusField(form: FormikProps<FormValues>, index: number, employee: any) {
    let hrStatusGroupControlName = `employeeList[${index}].employeeStatus`;

    return (
      <td className={styles.statusCol}>
        <CustomFieldAdv
          name={hrStatusGroupControlName}
          title=''
          placeholder='Select...'
          form={form}
          component={CustomFormSelect}
          className={styles.tableFormItem}
          options={this.HR_STATUS_OPTIONS}
          value={form.values.employeeList[index].employeeStatus}
          defaultValue={this.HR_STATUS_OPTIONS[0]}
          onSelect={(name, value) => this.selectHandler(name, value, form, employee, 'employeeStatus')}
          onBlur={() => {
            form.setFieldTouched(hrStatusGroupControlName)
          }}
          onKeyDown={(e: KeyboardEvent) => {
            this.props[STORE_UI].handleFormKeyPress(e, form)
          }}
        />
      </td>
    )
  }

  checkboxHandler(e: ChangeEvent<Element>, form: FormikProps<FormValues>, index: number) {
    form.handleChange(e);
    if (form.values.employeeList[index].checker) {
      this.setState({ checked: false });
    }
  }

  renderCheckerField(form: FormikProps<FormValues>, index: number) {
    let checkerName = `employeeList[${index}].checker`;

    return (
      <td className={styles.checkboxCol}>
        <CustomFieldAdv
          title=''
          name={checkerName}
          form={form}
          component={CustomCheckbox}
          className={styles.tableFormItem}
          checked={form.values.employeeList[index].checker || ''}
          onChange={(e) => {
            this.checkboxHandler(e, form, index)
          }}
        />
      </td>
    )
  }

  handleLoadNext(page: number, direction: string) {
    // @ts-ignore
    let lastElementId = '';
    if (direction === 'bottom') {
      lastElementId = '#item' + this.formData.values.employeeList[this.nextListEnd - 1].id;
    } else {
      lastElementId = '#item' + this.formData.values.employeeList[this.nextListStart].id;
    }
    this.nextListEnd = page * COUNT_SCROLL_ITEMS;
    this.nextListStart = this.nextListEnd - COUNT_SCROLL_ITEMS * 2;
    if (this.nextListStart <= 0) {
      this.nextListStart = 0;
    }
    this.forceUpdate();
    scrollToElement(lastElementId,
      {
        offset: 0,
        ease: 'linear',
        duration: 1
      }
    );
  };

  isBottom(el: any) {
    if (this.scrollBottom > el.getBoundingClientRect().bottom) {
      this.scrollBottom = el.getBoundingClientRect().bottom;
      this.isLoadedItems = true;
      return el.getBoundingClientRect().bottom - 500 <= window.innerHeight;
    } else {
      this.scrollBottom = el.getBoundingClientRect().bottom;
      return false
    }
  }

  isTop(el: any) {
    if (this.scrollTop < el.getBoundingClientRect().top) {
      this.scrollTop = el.getBoundingClientRect().top;
      return el.getBoundingClientRect().top + 500 >= 0;
    } else {
      this.scrollTop = el.getBoundingClientRect().top;
      return false
    }
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('header');
    if (wrappedElement == null || this.formData.values.employeeList.length <= COUNT_SCROLL_ITEMS) {
      return
    }
    ;
    if (this.scrollTop === 0) {
      this.scrollTop = wrappedElement.getBoundingClientRect().top;
      this.scrollBottom = wrappedElement.getBoundingClientRect().bottom;
    }

    if (this.isBottom(wrappedElement)) {
      this.pageNumber += 1;
      this.handleLoadNext(this.pageNumber, 'bottom');
    }

    if (this.isTop(wrappedElement)) {
      if (this.pageNumber > 1) {
        this.pageNumber -= 1;
        this.handleLoadNext(this.pageNumber, 'top');
      }
    }
  };

  renderEmployeeItems() {
    if (!this.initialValues.employeeList.length) {
      return <tbody><NoData columnSpan={7} /></tbody>
    }

    const { tabId } = this.props.match.params;
    return (
      <tbody id='header'>
        <Formik
          initialValues={this.initialValues}
          onSubmit={this.onSubmit}
          enableReinitialize={false}
        >
          {form => {
            this.formData = form;
            this.formHandleSubmit = form.handleSubmit;
            this.formReset = form.resetForm;

            return (
              <FieldArray name='employeeList'>
                {() => (
                  this.formData.values.employeeList.slice(this.nextListStart, this.nextListEnd).map((employee: any, index: number) => {
                    let gIndex = this.nextListStart + index;
                    return (
                      <tr key={gIndex} id={'item' + employee.id}>

                        {this.renderCheckerField(form, gIndex)}

                        <td>{this.getOrderCreateDate(employee.customAttributes)}</td>

                        <td>{employee.employeeId}</td>

                        <td><strong>{employee.name}</strong></td>

                        <td>{employee.nino}</td>

                        {tabId !== 'leavers' ? this.renderPayrollGroupField(form, gIndex, employee) : null}

                        {this.renderStatusField(form, gIndex, employee)}

                      </tr>
                    )
                  }
                  )
                )}
              </FieldArray>
            )
          }
          }
        </Formik>
      </tbody>
    )
  }

  checkAll() {
    this.formData.values.employeeList.forEach((item: employeeData, index: number) => {
      this.formData.setFieldValue(`employeeList[${index}].checker`, !this.state.checked);
    });
    this.setState({ checked: !this.state.checked });
  }

  renderEmployees() {
    const { tabId } = this.props.match.params;
    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
          <tr>
            <th className={styles.checkboxCol}>
              <CustomCheckbox
                name='checkAll'
                title=''
                className={styles.tableFormItem}
                checked={this.state.checked}
                onChange={() => {
                  this.checkAll()
                }}
              />
            </th>

            <th className={styles.colDateJoined}>Date joined</th>

            <th>Employee ID</th>

            <th>Name</th>

            <th className={styles.colNino}>NI Number</th>

            {tabId !== 'leavers' ? <th className={styles.colPayGroupSelect}>Payroll group</th> : null}

            <th className={styles.colStatusSelect}>Status</th>
          </tr>
        </thead>
        {this.props[STORE_HR].loadEmployeesState ?
          <tbody><TableLoader columnSpan={7} /></tbody> : this.renderEmployeeItems()}
      </table>
    );
  }

  searchHandler(text: string) {
    if (this.state.enableSubmitBtn) {
      this.setState({
        enableSubmitBtn: false
      });
    }

    this.formReset();
    this.searchCriteria.searchText = text;
    this.findEmployees(this.searchCriteria);
  }

  checkSearchPosibility() {
    setTimeout(() => {
      if (!this.props[STORE_HR].loadEmployeesState) {
        this.searchHandler(this.searchText);
      } else {
        this.checkSearchPosibility();
      }
    }, 300);
  }

  onSearchEvent = (event: any) => {
    this.searchText = event.target.value.trim();
    if (!this.props[STORE_HR].loadEmployeesState) {
      this.searchHandler(this.searchText);
    } else {
      this.checkSearchPosibility();
    }
  };

  render() {
    return (
      <>
        <CenterBanner
          mainTitle={'HR - ' + this.props[STORE_EMPLOYER].currentEmployer.name}
          subTitle={this.props[STORE_EMPLOYER].currentEmployer.clientId}
          tabs={this.props[STORE_HR].hrTabs}
          activeTab={this.state.activeTab}
          onClickTab={this.onTabAction}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={this.getTabTitle()}
            actionButtonName={this.state.enableSubmitBtn ? 'Apply changes' : ''}
            actionButtonEvent={(e) => {
              e.preventDefault();
              this.formHandleSubmit();
            }}
            placeholderSearch={'Search...'}
            onChangeEvent={this.onSearchEvent}
          />
          {this.renderEmployees()}
        </div>
      </>
    );
  }
}

export default HrEmployeesPage;
