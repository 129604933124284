import React from 'react';
import {Link} from 'react-router-dom';
import styles from './FooterIntro.module.scss';

const FooterIntro: React.FC = () => {
  return (
    <footer className={styles.cFooter}>
      <div className={styles.cContent}>
        <div className={styles.cFooterTop}>
          <div/>
          <Link to={"/"}>
            <img className={styles.footerLogo} src="/images/logo-footer.svg" alt="LnG"/>
          </Link>
        </div>
        <div className={styles.cFooterBottom}>
          <ul className={styles.bottomMenu}>
            <li><Link to={"/"}>Accessibility</Link></li>
            <li><Link to={"/"}>Security</Link></li>
            <li><Link to={"/"}>Legal and regulatory</Link></li>
{/*            <li><a href={"/terms-and-conditionals"} rel="noopener noreferrer" target={"_blank"}>Terms & Conditions</a>
            </li>*/}
            <li><a href={"/privacy-policy"} rel="noopener noreferrer" target={"_blank"}>Privacy policy</a></li>
            <li><Link to={"/"}>Cookie policy</Link></li>
          </ul>
          <div className={styles.copyright}>© Legal & General Portfolio Management Services 2019</div>
        </div>
      </div>
    </footer>
  );
};
export default FooterIntro;