import React from 'react';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { CenterBanner, TopContentBar, CustomField, CustomFormSelect, CustomButton, Loader } from 'components';


import { Form, Formik, FormikActions, FormikProps } from "formik";
import { RouteComponentProps } from 'react-router';
import { UIStore, HrStore, UserStore, EmployerStore } from 'stores';

import {
  STORE_UI,
  STORE_HR,
  STORE_USER,
  STORE_EMPLOYER,
  VALIDATION_RULES,
  MAX_INDEX_OF_CSV_COLUMN,
  MANUAL_CONNECTION_METHOD,
  SFTP_CONNECTION_METHOD
} from 'appConstants';

import { history } from 'index';
import styles from './HrSftpIntegration.module.scss';
import classNames from 'classnames';
import { SelectOption } from "../../interfaces";
import { CsvColumn } from 'graphql/models/employer';
import { CsvMappingItem, SftpSettings, HrSftpConfigInput } from 'graphql/mutations/inputs/UpdateHrSftpIntegrationInput';


export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_HR]: HrStore;
  [STORE_USER]: UserStore;
  [STORE_EMPLOYER]: EmployerStore;
}

enum CsvColumnKeys {
  MemberName = 'memberName',
  NiNumber = 'niNumber',
  PayrollGroup = 'payrollGroup',
  Status = 'status',
  LeaverReason = 'leverReason',
}

interface SftpSettingsFields {
  connectionMethod: SelectOption;
  publicKey: string;
}

interface CsvMappingColumnKeys {
  [CsvColumnKeys.MemberName]: SelectOption,
  [CsvColumnKeys.NiNumber]: SelectOption,
  [CsvColumnKeys.PayrollGroup]: SelectOption,
  [CsvColumnKeys.Status]: SelectOption,
  [CsvColumnKeys.LeaverReason]?: SelectOption,
}

type FormValues = SftpSettingsFields & CsvMappingColumnKeys;


@inject(STORE_UI, STORE_HR, STORE_USER, STORE_EMPLOYER)
@observer

class HrSftpIntegrationPage extends React.PureComponent<Props, {}>{

  formHandleSubmit = () => { };

  csvColumnIndexValidation = (fieldName: string) => Yup.object().shape({
    value: Yup.string().nullable().test(`${fieldName} validation`, 'must be unique', (value) => {
      const values = Object.keys(this.csvMapping).filter(x => x !== fieldName).map(x => String((this.csvMapping as any)[x].value || ''));
      return values.indexOf(value as string) === -1;
    })
  })

  VALIDATION_SCHEMA = Yup.object().shape({
    connectionMethod: Yup.object().shape({
      value: Yup.string()
        .required('The field is required'),
    }
    ),
    publicKey: VALIDATION_RULES.publicKey,

    [CsvColumnKeys.MemberName]: this.csvColumnIndexValidation(CsvColumnKeys.MemberName),
    [CsvColumnKeys.NiNumber]: this.csvColumnIndexValidation(CsvColumnKeys.NiNumber),
    [CsvColumnKeys.PayrollGroup]: this.csvColumnIndexValidation(CsvColumnKeys.PayrollGroup),
    [CsvColumnKeys.Status]: this.csvColumnIndexValidation(CsvColumnKeys.Status),
    [CsvColumnKeys.LeaverReason]: this.csvColumnIndexValidation(CsvColumnKeys.LeaverReason),

  });

  configExists = false;

  connectionMethodValues = [
    { value: SFTP_CONNECTION_METHOD, label: 'CSV Upload - SFTP' },
    { value: MANUAL_CONNECTION_METHOD, label: 'Manual' },
  ];

  state = {
    enableSubmitBtn: false,
    isManual: false
  };

  getSelectOption = (value: string | number | null, label: string) => ({ value: value as string, label });

  getCsvMappingSelectOption = (option: any) => this.getSelectOption(option, option);

  OPTIONAL_FIELD_IS_NOT_SET_LABEL = 'Not set';

  initialValues: FormValues = {
    connectionMethod: this.connectionMethodValues[0],
    publicKey: '',
    [CsvColumnKeys.MemberName]: this.getCsvMappingSelectOption(1),
    [CsvColumnKeys.NiNumber]: this.getCsvMappingSelectOption(2),
    [CsvColumnKeys.PayrollGroup]: this.getCsvMappingSelectOption(3),
    [CsvColumnKeys.Status]: this.getCsvMappingSelectOption(4),
    [CsvColumnKeys.LeaverReason]: this.getSelectOption(null, this.OPTIONAL_FIELD_IS_NOT_SET_LABEL),
  };

  csvMappingIndexValues = [...new Array(MAX_INDEX_OF_CSV_COLUMN)].map((x, i) => ({ value: (i + 1).toString(), label: (i + 1).toString() }));
  csvMappingOptionalIndexValues = [
    this.getSelectOption(null, this.OPTIONAL_FIELD_IS_NOT_SET_LABEL),
    ...this.csvMappingIndexValues
  ]

  csvMapping: CsvMappingColumnKeys = { ...this.initialValues };

  sftpUsername: string = '';

  async componentDidMount() {
    try {
      const { username } = this.props[STORE_USER].user;
      await this.props[STORE_EMPLOYER].loadEmployerByHrEmail(username, 'hrSftpIntegrationConfig');
      await this.props[STORE_HR].loadNewSignUpsCount(Number(this.props[STORE_EMPLOYER].currentEmployer.id));

      this.sftpUsername = this.props[STORE_EMPLOYER].currentEmployer.clientId as string
      const hrSftpConfig = this.props[STORE_EMPLOYER].currentEmployer.hrSftpIntegrationConfig;

      if (hrSftpConfig) {
        this.processExistingData(hrSftpConfig.csvMapping, hrSftpConfig.sftpSettings && hrSftpConfig.sftpSettings.publicKey)
      }
    } catch (error) {
      console.error('Error during sftp request', error);
    }
  }

  processExistingData(csvMapping: CsvColumn[] | undefined, publicKey: string | undefined) {
    let shouldRerender = false

    if (csvMapping && csvMapping.length) {
      shouldRerender = true
      this.configExists = true;

      csvMapping.forEach(x => {
        if (x.columnIndex) {
          (this.initialValues as any)[x.columnKey] = this.getCsvMappingSelectOption((x.columnIndex as any));
        }
        this.csvMapping = { ...this.initialValues };
      })
    }

    if (publicKey) {
      shouldRerender = true
      this.initialValues.publicKey = publicKey;
    }

    shouldRerender && this.forceUpdate();
  }

  onTabAction = (val: string) => {
    history.push("/hr/" + val);
  };

  onSubmit = async (submittedValues: FormValues, actions: FormikActions<FormValues>) => {
    this.props[STORE_UI].setLoadModalData();
    actions.setStatus();

    const csvMapping: Array<CsvMappingItem> = Object.values(CsvColumnKeys)
      .map(x => ({
        columnKey: x,
        columnIndex: (submittedValues as any)[x] && (submittedValues as any)[x].value,
      }))

    const { id } = this.props[STORE_EMPLOYER].currentEmployer;
    const { username } = this.props[STORE_USER].user;
    const sftpSettings: SftpSettings = {
      username: this.sftpUsername,
      publicKey: submittedValues.publicKey
    }

    const updateHrSftpIntegrationInput: HrSftpConfigInput = {
      employerId: id,
      sftpSettings: sftpSettings,
      csvMapping: csvMapping,
    }

    await this.props[STORE_HR].updateHrSftpIntegrationConfig(updateHrSftpIntegrationInput);
    this.props[STORE_UI].setLoadModalData(false);
    await this.props[STORE_EMPLOYER].loadEmployerByHrEmail(username, 'hrSftpIntegrationConfig');
    const hrSftpConfig = this.props[STORE_EMPLOYER].currentEmployer.hrSftpIntegrationConfig;

    if (hrSftpConfig) {
      this.processExistingData(hrSftpConfig.csvMapping, hrSftpConfig.sftpSettings && hrSftpConfig.sftpSettings.publicKey)
    }

    if (this.props[STORE_HR].fetchError) {
      actions.setStatus({ fetchError: this.props[STORE_EMPLOYER].fetchError })
    } else {
      actions.resetForm(submittedValues);
    }
  };


  testMappingHandler = async () => {
    const { id } = this.props[STORE_EMPLOYER].currentEmployer;
    await this.props[STORE_HR].handleMappingTest(id)
  }

  csvMappingSelectHandler(name: string, selectedValue: SelectOption, form: any) {
    form.setFieldValue(name, { label: selectedValue.label, value: selectedValue.value && Number(selectedValue.value) });
    (this.csvMapping as any)[name] = selectedValue;
  };

  connectionMethodSelectHandler(name: string, selectedOption: SelectOption, form: any) {
    form.setFieldValue(name, selectedOption)
    const isManual = selectedOption.value === MANUAL_CONNECTION_METHOD
    this.setState({
      isManual,
    })
  }

  renderManualIntegrationContent() {
    return (<div className={classNames(styles.sftpConfigurationForm, styles.longParagraph)}>
      <p>We email you 7 days prior to pay date to remind of any new sign-ups that you haven’t validated yet. You need to change the status of each new sign-up to Approved, or Rejected if they are not an active/eligible employee in your organisation. If the employee’s employment is terminated, or if they are no longer eligible for a UK ISA, you need to change their status to Leaver.</p>
    </div>)
  }

  renderCsvColumnIndexSelect(field: string, label: string, form: FormikProps<FormValues>, optional: boolean = false) {
    return (<div className={styles.cControlsLeft} key={field}>
      <label htmlFor={field} className={styles.csvColumnLabel}>{label}</label>

      <CustomField
        name={field}
        title="CSV Mapping"
        placeholder="choose position"
        form={form}
        component={CustomFormSelect}
        defaultValue={(this.initialValues as any)[field]}
        className={classNames(styles.leftPositionPadd, styles.columnIndexSelect)}
        options={optional ? this.csvMappingOptionalIndexValues : this.csvMappingIndexValues}
        onSelect={(name, value) => this.csvMappingSelectHandler(name, value, form)}
        onKeyDown={(e: KeyboardEvent) => { this.props[STORE_UI].handleFormKeyPress(e, form) }}
        instantlyShowErrorMsg={true}
      />
    </div>);
  }

  renderCsvMappingDropDowns(form: any) {
    const isDisabled = !form.dirty || !form.isValid;
    const saveButtonLabel = this.configExists ? 'Update' : 'Save';

    return (<><div className={styles.topContentBar}>
      <h3 className={styles.tcbTitle}>CSV Mapping</h3>
    </div>
      <div className={styles.sftpConfigurationForm}>
        <div className={styles.csvMappingContainer}>
          <div>
            {this.renderCsvColumnIndexSelect(CsvColumnKeys.MemberName, 'Member name', form)}
            {this.renderCsvColumnIndexSelect(CsvColumnKeys.NiNumber, 'NI number', form)}
            {this.renderCsvColumnIndexSelect(CsvColumnKeys.PayrollGroup, 'Payroll group', form)}
          </div>
          <div>
            {this.renderCsvColumnIndexSelect(CsvColumnKeys.Status, 'Status', form)}
            {this.renderCsvColumnIndexSelect(CsvColumnKeys.LeaverReason, 'Leaver reason', form, true)}
          </div>
        </div>
      </div>

      {form.status && <p className={styles.error}>{this.props[STORE_HR].fetchError}</p>}
      <div className={styles.cButtonRight}>
        <CustomButton size={"xxs"} type="button" buttonType="primary" className={styles.testMappingButton} disabled={!this.configExists} onClick={this.testMappingHandler}>Test mapping</CustomButton>

        {this.props[STORE_UI].loadModalData ? <Loader className={styles.loader} /> :
          <CustomButton size={"xxs"} type="submit" buttonType="primary" disabled={isDisabled}>{saveButtonLabel}</CustomButton>
        }
      </div></>)
  }

  renderSftpIntegrationForm() {
    const employer = this.props[STORE_EMPLOYER].currentEmployer;
    const sftpUrl = employer && employer.hrSftpIntegrationConfig && employer.hrSftpIntegrationConfig.sftpUrl;

    return (<Formik
      initialValues={this.initialValues}
      validationSchema={this.VALIDATION_SCHEMA}
      onSubmit={this.onSubmit}
    >{form => {

      return <Form>
        <div className={classNames(styles.cPopupContent, styles.minHeight, styles.sftpConfigurationForm)} >
          <div>
            <div className={classNames(styles.cControlsLeft, styles.sftpSettingsContainer)}>
              <CustomField
                name="connectionMethod"
                title="Connection method"
                placeholder="choose connection method"
                form={form}
                component={CustomFormSelect}
                defaultValue={this.initialValues.connectionMethod}
                className={classNames(styles.leftPositionPadd, styles.shortSelect)}
                options={this.connectionMethodValues}
                onSelect={(name, value) => this.connectionMethodSelectHandler(name, value, form)}
                onKeyDown={(e: KeyboardEvent) => { this.props[STORE_UI].handleFormKeyPress(e, form) }}
              />
              {!this.state.isManual && (<><div>
                <span className={styles.sftpAddress}>
                  SFTP Address
                </span>
                {sftpUrl || <Loader className={styles.loader} />}
              </div>
                <div>
                  <span className={styles.sftpAddress}>
                    SFTP Username
                </span>
                  {this.sftpUsername || '-'}
                </div>
                <CustomField
                  name="publicKey"
                  title="Public Key"
                  placeholder="Enter the public key..."
                  form={form}
                  autoFocus
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e: KeyboardEvent) => { this.props[STORE_UI].handleFormKeyPress(e, form) }}
                /></>)}
            </div>
          </div>
          {this.state.isManual ? this.renderManualIntegrationContent() : this.renderCsvMappingDropDowns(form)}
        </div>
      </Form>
    }}
    </Formik>);
  };

  render() {
    return (
      <>
        <CenterBanner
          mainTitle={"HR - " + this.props[STORE_EMPLOYER].currentEmployer.name}
          subTitle={this.props[STORE_EMPLOYER].currentEmployer.clientId}
          tabs={this.props[STORE_HR].hrTabs}
          activeTab={"sftp-integration"}
          onClickTab={this.onTabAction}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={"HR Integration"}
            actionButtonName={this.state.enableSubmitBtn ? "Apply changes" : ""}
            actionButtonEvent={(e) => { e.preventDefault(); this.formHandleSubmit() }}
          />
          {this.renderSftpIntegrationForm()}
        </div>
      </>
    );
  }
}

export default HrSftpIntegrationPage;
