import * as Yup from 'yup';

export const LOGIN_VALIDATION_ERROR = 'The employer contract has been discontinued.';
export const LOGIN_VALIDATION_TIMEOUT_ERROR =
  'You have exceeded the number of login attempts, please try again in 30 minutes or contact the Service Desk.';
export const LOGIN_SUPER_ADMIN_ERROR = 'L&G admins need to login via Unu.';

const NAME_REGEX = /^[^!@#$%^&*(),\\/?":{}|<>[\]0-9]+\.?$/;

export const VALIDATION_RULES = {
  role: Yup.string().required('The field is required'),
  select: Yup.string().required('The field is required'),
  email: Yup.string()
    .required('The field is required')
    .trim('The field can not start and end with a space')
    .email('Must be a valid email format'),
  name: Yup.string()
    .required('The field is required')
    .trim('The field can not start and end with a space')
    .min(3, 'Min length of the field is 3 character')
    .max(256, 'Max length of the field is 256 character'),
  firstName: Yup.string()
    .required('The field is required')
    .matches(NAME_REGEX, 'Invalid first name')
    .trim('The field can not start and end with a space')
    .min(3, 'Min length of the field is 3 character')
    .max(256, 'Max length of the field is 256 character'),
  lastName: Yup.string()
    .required('The field is required')
    .matches(NAME_REGEX, 'Invalid last name')
    .trim('The field can not start and end with a space')
    .min(3, 'Min length of the field is 3 character')
    .max(256, 'Max length of the field is 256 character'),
  url: Yup.string()
    .required('The field is required')
    .trim('The field can not start and end with a space')
    .min(3, 'Min length of the field is 3 characters')
    .url('The field must be in URL format. Please check and edit to fit the format'),
  accountNumber: Yup.string()
    .required('The field is required')
    .matches(/^[0-9]*$/, 'Bank account number must consist of 8 digits'),
  sortCode: Yup.string()
    .required('The field is required')
    .matches(/^[0-9-]*$/, 'Length of the field is 6 character'),
  publicKey: Yup.string().required('The field is required'),
  fileName: Yup.string().required('The field is required'),
};
