import React from 'react';
import { RouteComponentProps } from 'react-router';
import {LoginSignupHolder, PasswordlessForm} from 'components';
import {inject, observer} from 'mobx-react';
import {UserStore} from 'stores';
import { Location } from 'history';
import {STORE_USER} from 'appConstants';

export interface Props extends RouteComponentProps<any> {
  [STORE_USER]: UserStore;
}

export interface LocationState {
  from: Location;
}

@inject(STORE_USER)
@observer

class LoginPage extends React.PureComponent<Props, {}> {
  render() {
    const pathname = (this.props.location.state as LocationState)?.from?.pathname || '/';

    return (
      <LoginSignupHolder>
        <PasswordlessForm pathname={pathname} signIn={this.props[STORE_USER].signIn}/>
      </LoginSignupHolder>
    );
  }
}
export default LoginPage;