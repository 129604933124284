import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';

const Footer: React.FC = () => {
  return (
    <footer className={styles.cFooter}>
      <div className={styles.cFooterTop}>
        <ul className={styles.footerSubMenu}>
          <li><Link to={"/"}>My account</Link></li>
          <li><Link to={"/"}>Support centre</Link></li>
          <li><Link to={"/"}>My quotes</Link></li>
          <li><Link to={"/"}>Contact</Link></li>
        </ul>
        <Link to={"/"}>
          <img src="/images/logo-footer.svg" alt="LnG"/>
        </Link>
      </div>
      <div className={styles.cFooterBottom}>
        <ul className={styles.bottomMenu}>
          {/*   <li><a href={"/terms-and-conditionals"} rel="noopener noreferrer" target={"_blank"}>Terms & Conditions</a>
          </li>*/}
          <li><a href={"/privacy-policy"} rel="noopener noreferrer" target={"_blank"}>Privacy policy</a></li>
        </ul>
        <div className={styles.copyright}>© Legal & General Group plc 2018</div>
      </div>
    </footer>
  );
};
export default Footer;