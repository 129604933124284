export const allPaychainAdmins = ` 
{
  allPaychainAdmins {
    id
    email
    firstName
    lastName
  }
}
`;

export const getAuditFile = `
  query getAuditFile($token: String!){
    getAuditFile(
      token: $token
    ) 
  }
  
`;