import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import {CustomSelect} from 'components';
import {history} from 'index';

interface Props {
  showNotifications?: boolean;
  showProfileMenu?: boolean;
  isPaychainAdmin?: boolean;
  isEmployerAdmin?: boolean;
  isHRAdmin?: boolean;
  isPayrollAdmin?: boolean;
  routPath?: string;
  logout: () => void;
}

const selectItems = [
  {title: 'Logout', value: 'logout'},
];

const selectItemsAdmin = [
  {title: 'My Settings', value: 'mySettings'},
  {title: 'Logout', value: 'logout'},
];

class Header extends React.Component<Props, {}> {

  showRolesSwitcher() {
    return (
      <ul className={styles.cRolesSwitcher}>
        {this.props.isEmployerAdmin &&
        <li>
          <Link
            className={this.props.routPath === "/employer" ? styles.active : ""}
            to={"/employer/bank-accounts"}
          >
            Admin
          </Link>
        </li>}

        {this.props.isHRAdmin &&
        <li>
          <Link
            className={this.props.routPath === "/hr" ? styles.active : ""}
            to={"/hr/new-sign-ups"}
          >
            HR
          </Link>
        </li>}

        {this.props.isPayrollAdmin &&
        <li>
          <Link
            className={this.props.routPath === "/payroll" ? styles.active : ""}
            to={"/payroll/home"}
          >
            Payroll</Link>
        </li>}
      </ul>
    );
  }

  showProfileMenu(){
    return (
      <CustomSelect
        items={this.props.isPaychainAdmin ? selectItemsAdmin : selectItems}
        imgUrl={"/images/chevron-down.svg"}
        className={styles.cuPosition}
        onClickOption={(action) => {
          this.selectActions(action)
        }}
      />
    )
  }

  selectActions(action: string) {
    switch (action) {
      case 'logout':
        this.props.logout();
        break;
      case 'mySettings':
        history.push("/admin/super-admins");
        break;
    }
  }

  render() {

    return (
      <header className={styles.cHeader}>
        <div className={styles.cLeft}>
          <Link
            className={styles.logo}
            to={"/"}
          >
            <img src="/images/logo-desktop.svg" alt="logo"/>
          </Link>
          {this.showRolesSwitcher()}
        </div>

        <div className={styles.cRight}>
          {this.props.showProfileMenu && this.showProfileMenu()}
        </div>
      </header>
    );
  }
}

export default Header;