import classNames from 'classnames';
import React from 'react';
import {DebounceInput} from 'react-debounce-input';
import styles from './TopContentBar.module.scss';

interface Props {
  mainTitle: string;
  actionButtonName?: string;
  actionButtonNameSecond?: string;
  actionButtonEvent?: (e: React.MouseEvent) => void;
  actionButtonSecondEvent?: (e: React.MouseEvent) => void;
  placeholderSearch?: string;
  onChangeEvent?: any;
}

class TopContentBar extends React.Component<Props, {}> {

  showActionButton() {
    if (this.props.actionButtonName) {
      return (
        <div className={styles.btnWrap}>
          <a className={styles.btn} onClick={this.props.actionButtonEvent} href="/">
            {this.props.actionButtonName}
          </a>
        </div>
      )
    }
  }

  showActionButtonSecond() {
    if(this.props.actionButtonNameSecond){
      return (
        <div className={styles.btnWrap}>
          <a className={classNames(styles.btn, styles.payrollbtn)}  onClick={this.props.actionButtonSecondEvent} href="/">
            {this.props.actionButtonNameSecond}
          </a>
        </div>
      )
    }
  }

  showSearch() {
    if (this.props.placeholderSearch) {
      return (
        <div className={styles.searchWrap}>
          <DebounceInput
            className={styles.input}
            onChange={this.props.onChangeEvent}
            placeholder={this.props.placeholderSearch}
            minLength={1}
            debounceTimeout={300}
          />
        </div>
      )
    }
  }

  render() {
    return (
      <div className={styles.topContentBar}>
        <h3 className={styles.tcbTitle}>{this.props.mainTitle}</h3>
        <div className={styles.tcbActions}>
          {this.showActionButton()}
          {this.showActionButtonSecond()}
          {this.showSearch()}
        </div>
      </div>
    );
  }
}

export default TopContentBar;