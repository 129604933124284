import * as React from 'react';
import * as Yup from 'yup';
import {CustomField, CustomFormSelect, AcceptButton} from 'components';
import {Form, Formik, FormikActions, FieldArray} from "formik";
import {inject, observer} from "mobx-react";
import classNames from 'classnames';
import {SelectOption} from 'interfaces';
import {STORE_UI, STORE_EMPLOYER, VALIDATION_RULES, AuthRole} from "appConstants";
import {UIStore, EmployerStore} from "stores";
import {RouteComponentProps} from "react-router";
import {generate} from 'shortid';
import CreatePayrollGroupsInput from "../../../graphql/mutations/inputs/CreatePayrollGroupsInput";
import {EmployerModel} from "../../../graphql/models/employer";
import styles from './CreatePayrollGroupModal.module.scss';


interface FormValues {
  payrollGroupName: string;
  bankAccount: BankAccounts[];
  payrollAdmins: AdminsFormValues[];
}

interface BankAccounts {
  id: string,
  label: string,
  value: string,
}

interface AdminsFormValues {
  selectId: string,
  label: string,
  value: string
}

interface AdminsOptions {
  optionId: number,
  label: string,
  value: string,
  selectedControl: string
}

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
  onCancel: () => void;
  employer: EmployerModel;
  userName: string;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  payrollGroupName: VALIDATION_RULES.name,
  bankAccount: VALIDATION_RULES.select,
  payrollAdmins: Yup.array().of(
    Yup.object().shape({
        value: VALIDATION_RULES.select,
      }
    )
  )
});


@inject(STORE_UI, STORE_EMPLOYER)
@observer

export default class CreatePayrollGroupModal extends React.Component <Props, {}> {

  initialValues: FormValues = {
    payrollGroupName: '',
    bankAccount: [],
    payrollAdmins: [{selectId: generate(), label: "", value: ""}]
  };

  selectCount: number = 1;
  bankAccountsList = [{label: "", value: ""}];
  payrollAdminsList: AdminsOptions[] = [];

  componentDidMount(): void {
    this.selectCount = 1;
    this.loadBankAccountSelectData();
    this.loadPayrollAdminsSelectData();
  }

  loadBankAccountSelectData() {
    let bankAccounts = this.props.employer.bankAccounts || [];

    this.bankAccountsList = bankAccounts.map((account)=>{
      let sortingCode = this.props[STORE_UI].sortingCodeFormat(account.sortingCode);
      return {label: sortingCode+" "+account.accountNumber, value: account.id}
    });
  }

  loadPayrollAdminsSelectData() {
    let employerUsers = this.props.employer.employerUsers || [];
    let payrollAdmins = employerUsers.filter((user) => {
      return user.roles.includes(AuthRole.ROLE_PAYROLL_ADMIN);
    });

    this.payrollAdminsList = payrollAdmins.map((admin, index) => {
      return {optionId: index, label: admin.email, value: admin.id, selectedControl: ""}
    });
  }

  onSubmit = async ({ payrollGroupName, bankAccount, payrollAdmins }: FormValues, actions: FormikActions<FormValues>) => {

    this.props[STORE_UI].setLoadModalData();
    actions.setStatus();

    let adminsIds = payrollAdmins.map((admin: AdminsFormValues) => admin.value);

    const payrollGroupData: CreatePayrollGroupsInput = {
      name: payrollGroupName,
      bankAccountId: (bankAccount as any).value,
      employerId: this.props.employer.id,
      payrollAdmins: adminsIds
    };

    await this.props[STORE_EMPLOYER].createPayrollGroup(payrollGroupData, this.props.userName);
    this.props[STORE_UI].setLoadModalData(false);

    if(this.props[STORE_EMPLOYER].fetchError){
      actions.setStatus({ fetchError: this.props[STORE_EMPLOYER].fetchError })
    } else {
      this.props.onCancel();
    }
  };

  selectHandler(name: string, value: SelectOption, form: any, selectId: string) {
    let controlValue = {
      selectId: selectId,
      label: value.label,
      value: value.value
    };

    form.setFieldValue(name, controlValue);

    let oldIndex = this.payrollAdminsList.findIndex(item => item.selectedControl === selectId);
    if(oldIndex!==-1){
      this.payrollAdminsList[oldIndex].selectedControl = "";
    }

    let currentIndex = this.payrollAdminsList.findIndex(item => item.value === value.value);
    this.payrollAdminsList[currentIndex].selectedControl = selectId;
  };

  getPayrollAdminsOptions(name: string, selectId: string) {
    return this.payrollAdminsList.filter((item) => {
      return item.selectedControl === selectId || item.selectedControl === ""
    });
  }

  addAdmin(push: (obj: any) => void, e: React.MouseEvent) {
    e.preventDefault();
    push({selectId: generate(), label: "", value: ""});
  }

  renderAddAdminButton(push: (obj: any) => void) {
    if(this.selectCount <  this.payrollAdminsList.length) {
      return (
        <a
          className={styles.cuLink}
          onClick={(e) => {
            this.addAdmin(push, e)
          }}
          href="/"
        >+ Add another</a>
      )
    } else return ;
  }

  removeAdmin(remove: (index: number) => void, index: number, selectId: string, e: React.MouseEvent) {
    this.payrollAdminsList.forEach((item, index)=>{
      if (item.selectedControl === selectId) {
        this.payrollAdminsList[index].selectedControl = "";
      }
    });
    e.preventDefault();
    remove(index);
    this.selectCount--;
  }

  renderRemoveAdminButton(remove: (index: number) => void, index: number, selectId: string) {
    if(this.selectCount > 1) {
      return (
        <a
          className={classNames(styles.deleteBtn)}
          onClick={(e) => {
            this.removeAdmin(remove, index, selectId, e)
          }}
          href="/"
        >-</a>
      )
    } else return ;
  }

  renderAdminControls(form: any, remove: (index: number) => void) {
    return form.values.payrollAdmins.map((admin: any, index: number) => {

      this.selectCount = 1+index;
      let controlName: string = `payrollAdmins[${index}]`;
      let options = this.getPayrollAdminsOptions(controlName, admin.selectId);
      let defaultValue = options ? options.find(option => option.selectedControl === admin.selectId) : '';

      return (
        <div className={styles.cControlsLeft} key = {admin.selectId}>
          <CustomField
            name = {controlName}
            title=""
            placeholder="Enter payroll admin..."
            form={form}
            component={CustomFormSelect}
            defaultValue={defaultValue}
            className={styles.leftPositionPadd}
            options={options}
            onSelect={(name, value)=> this.selectHandler(name, value, form, admin.selectId)}
            onBlur={()=>{form.setFieldTouched(controlName)}}
            onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
          />
          {this.renderRemoveAdminButton(remove, index, admin.selectId)}
        </div>
      )
    });
  }

  render() {
    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.onSubmit}
      >{ form => {
        const isDisabled = !form.dirty || !form.isValid;
        return <Form>
          <div className={classNames(styles.cPopupContent, styles.minHeight)}>
            <div>
              <div className={styles.cControlsLeft}>
                <CustomField
                  name="payrollGroupName"
                  title="Payroll group name"
                  placeholder="Enter payroll group name..."
                  form={form}
                  autoFocus
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
                <CustomField
                  name = "bankAccount"
                  title="Bank account"
                  placeholder="Enter bank account..."
                  form={form}
                  component={CustomFormSelect}
                  defaultValue={undefined}
                  className={styles.leftPosition}
                  options={this.bankAccountsList}
                  onSelect={form.setFieldValue}
                  onBlur={()=>{form.setFieldTouched("bankAccount")}}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
              </div>

              <FieldArray name="payrollAdmins">{({push, remove}) => (
                  <div>
                    <label htmlFor="payrollAdmins[0]">
                      Payroll admin(s)
                    </label>
                    <div className={styles.cScroll}>
                      {this.renderAdminControls(form, remove)}
                      <div className={styles.cLinks}>
                        {this.renderAddAdminButton(push)}
                      </div>
                      {form.status && <p className={styles.error}>{form.status.fetchError}</p>}
                    </div>
                  </div>
                )}
              </FieldArray>
            </div>

            <AcceptButton
              isDisabled={isDisabled}
              buttonName={"Create"}
              loadModalData={this.props[STORE_UI].loadModalData}
            />

          </div>
        </Form>}}
      </Formik>
    )
  }
}
