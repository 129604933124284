import * as React from "react";
import styles from "./CustomFieldAdv.module.scss";
import classNames from 'classnames';
import {Field, getIn} from "formik";
import {ReactNode} from "react";
import {SelectOption} from 'interfaces';
import {CustomInput, CustomCheckbox} from 'components';

interface Props {
  name: string;
  title: string;
  placeholder?: string;
  className?: string;
  inputStyle?: string;
  inputSize?: string;
  size?: string;
  form: any;
  withSeparator?: boolean;
  component?: ReactNode;
  options?: SelectOption[];

  [x: string]: any;

  onChange?: (e: React.ChangeEvent) => void;
  // for select component onChange should be replaced to onSelect, as component works with value (not with event)
  onSelect?: (name: string, value: SelectOption) => void;
}

class CustomFieldAdv extends React.Component <Props, {}> {
  onSelectChange = (val: SelectOption) => this.props.onSelect && this.props.onSelect(this.props.name, val)

  render() {
    const {
      name,
      title,
      placeholder,
      size,
      inputSize,
      inputStyle,
      className, form,
      onBlur,
      onChange,
      withSeparator,
      component,
      onSelect,
      instantlyShowErrorMsg,
      ...props
    } = this.props;
    const onChangeEvent = onSelect
      ? this.onSelectChange
      : onChange;
    // for checkbox label should be moved inside element and hidden on Field level
    const hiddenLabel = component === CustomCheckbox;
    let errorMsg = form.errors[name] && form.touched[name] ? form.errors[name] : null;

    let errorMsgGroup = getIn(form.errors, name);

    if (errorMsgGroup && errorMsgGroup.value !== undefined && (instantlyShowErrorMsg || form.touched[name])) {
      errorMsg = errorMsgGroup.value;
    }


    return (
      <div className={classNames(styles.field, className)}>
        <label>
          {!hiddenLabel && (
            <>{title}{withSeparator && ':'}</>
          )}
          <Field
            {...props}
            id={name}
            className={styles.name}
            component={component ? component : CustomInput}
            name={name}
            placeholder={placeholder}
            onChange={onChangeEvent}
            onBlur={onBlur}
            title={title}
          />
        </label>
        <div className={styles.error}>{errorMsg}</div>
      </div>
    )
  }
}

export default CustomFieldAdv;
