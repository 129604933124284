import React from 'react';
import styles from './AcceptButton.module.scss';
import { CustomButton, Loader } from '../index';

export interface Props {
  isDisabled: boolean;
  buttonName: string;
  loadModalData: boolean;
}

export default class AcceptButton extends React.Component<Props, {}> {
  render() {
    return (
      <div className={styles.cButtonRight}>
        {this.props.loadModalData ? <Loader className={styles.loader}/> :
          <CustomButton
            size={"xxs"}
            type="submit"
            buttonType="primary"
            disabled={this.props.isDisabled}
          >
            {this.props.buttonName}
          </CustomButton>
        }
      </div>
    );
  }
};
