import { observable, action } from 'mobx';
import { ChangeEvent } from 'react';

import { InputFileReader } from '../utils/fileReader';
import {
  STORE_UI,
  FORM_MODAL_WIDTH,
  CSV_UPLOADED_MODAL,
  TEST_CSV_MAPPING_MODEL,
  CsvUploadTableShowOptions,
  ERROR_MODAL,
} from '../appConstants';
import { EmployeeApprovalCsvUploadResult, EmployeeModel, FindEmployeeApprovalCsvInfo } from '../graphql/models/hr';
import HrService from '../services/hrService';
import EmployeeSearchCriteriaInput from '../graphql/mutations/inputs/EmployeeSearchCriteriaInput';
import UpdateEmployeeInput from '../graphql/mutations/inputs/UpdateEmployeeInput';
import { employeeData } from '../interfaces';
import { HrSftpConfigInput } from '../graphql/mutations/inputs/UpdateHrSftpIntegrationInput';

import { RootStore } from './RootStore';

interface EmployeesFormValues {
  employeeList: Array<employeeData>;
}

export class HrStore {
  @observable fetchError = '';
  @observable newSignUpsCount = '0';
  @observable employees: Array<EmployeeModel> = [];
  @observable loadEmployeesState = false;
  @observable loadNewSignUpsState = false;
  @observable hrTabs: Array<{ title: string; value: string }> = [];
  @observable loadingEmployeeFileInfo = false;
  @observable employeeFileInfo: FindEmployeeApprovalCsvInfo = {
    uploadLink: '',
  };
  @observable uploadingEmployeeFile = false;
  @observable employeeFileUploadInfo: EmployeeApprovalCsvUploadResult = {
    success: undefined,
  };

  constructor(public rootStore: RootStore, public hrService: HrService) {}

  @action setEmployeeLoad(val = false) {
    this.loadEmployeesState = val;
  }

  @action getEmployeesForSubmit(submittedValues: EmployeesFormValues, defaultStatus: string) {
    if (defaultStatus === 'Leaver') {
      return this.getEmployeesForSubmitLeaver(submittedValues, defaultStatus);
    } else {
      return this.getEmployeesForSubmitRegular(submittedValues, defaultStatus);
    }
  }

  @action getEmployeesForSubmitRegular(submittedValues: EmployeesFormValues, defaultStatus: string) {
    const changedEmployees = submittedValues.employeeList.filter(employee => {
      return !!employee.payrollGroup || (employee.employeeStatus && employee.employeeStatus.value !== defaultStatus);
    });

    return changedEmployees.map(employee => {
      const formattedEmployee: UpdateEmployeeInput = {
        id: employee.id ? employee.id : '',
        status:
          employee.employeeStatus && employee.employeeStatus.value ? employee.employeeStatus.value : defaultStatus,
        payrollGroupId:
          employee.payrollGroup && employee.payrollGroup.value ? employee.payrollGroup.value : employee.payrollGroupId,
      };
      return formattedEmployee;
    });
  }

  @action getEmployeesForSubmitLeaver(submittedValues: EmployeesFormValues, defaultStatus: string) {
    const changedEmployees = submittedValues.employeeList.filter(
      employee => employee.employeeStatus && employee.employeeStatus.value !== defaultStatus,
    );

    return changedEmployees.map(employee => {
      const formattedEmployee: UpdateEmployeeInput = {
        id: employee.id ? employee.id : '',
        status:
          employee.employeeStatus && employee.employeeStatus.value ? employee.employeeStatus.value : defaultStatus,
        leaveReason: employee.leaveReason && employee.leaveReason.value ? employee.leaveReason.value : null,
      };
      return formattedEmployee;
    });
  }

  @action loadHrTabs() {
    const newSignUpsTitle = 'New sign-ups (' + this.newSignUpsCount + ')';
    this.hrTabs = [
      { title: 'HR Integration', value: 'sftp-integration' },
      { title: newSignUpsTitle, value: 'new-sign-ups' },
      { title: 'Approved', value: 'approved' },
      { title: 'Rejected', value: 'rejected' },
      { title: 'Leavers', value: 'leavers' },
      { title: 'Employees', value: 'employees' },
    ];
  }

  @action async loadNewSignUpsCount(employerId: number) {
    const criteria = {
      employerId: employerId,
      searchText: '',
      status: 'New',
    };
    this.loadNewSignUpsState = true;
    const employees = (await this.hrService.findEmployees(criteria)) || [];
    this.newSignUpsCount = employees.length.toString();
    this.loadNewSignUpsState = false;
  }

  @action async findEmployees(criteria: EmployeeSearchCriteriaInput) {
    this.employees = (await this.hrService.findEmployees(criteria)) || [];
    if (criteria.searchText === '' && criteria.status === 'New') {
      this.newSignUpsCount = this.employees.length.toString();
      this.loadHrTabs();
    }
  }

  @action async updateEmployees(employees: Array<UpdateEmployeeInput>) {
    try {
      await this.hrService.updateEmployees(employees);
      this.fetchError = '';
    } catch (error: any) {
      this.fetchError = error.errors[0].message;
    }
  }

  @action async updateHrSftpIntegrationConfig(input: HrSftpConfigInput) {
    try {
      await this.hrService.updateHrSftpIntegrationConfig(input);
      this.fetchError = '';
    } catch (error: any) {
      this.fetchError = error.errors[0].message;
    }
  }

  @action
  async chooseHrSftpBatchFile(event: ChangeEvent<HTMLInputElement>, employerId: string) {
    try {
      const fileData = (await InputFileReader.getContentFromFileInputEvent(
        event,
        InputFileReader.defaultMaxFileSize,
      )) as string;
      const data = await this.hrService.validateHrSftpIntegrationCsvMapping(fileData, employerId);
      const csvErrorCount = data.resultItems.reduce((accumulator, item) => accumulator + item.errors.length, 0);

      this.rootStore[STORE_UI].openModal({
        width: FORM_MODAL_WIDTH,
        componentKey: CSV_UPLOADED_MODAL,
        title: 'CSV uploaded',
        props: {
          submitData: data,
          showOption: CsvUploadTableShowOptions.HrSftpConfig,
          csvErrorCount: csvErrorCount,
          onChange: (e: ChangeEvent<HTMLInputElement>) => this.chooseHrSftpBatchFile(e, employerId),
        },
      });
    } catch (e) {
      this.rootStore[STORE_UI].openModal({
        width: FORM_MODAL_WIDTH,
        componentKey: ERROR_MODAL,
        title: 'Error',
        props: {
          description: (e as Error).message,
        },
      });
    }
  }

  // eslint-disable-next-line
  @action async handleMappingTest(employerId: string) {
    const curriedChooseHrSftpBatchFile = (e: ChangeEvent<HTMLInputElement>) =>
      this.chooseHrSftpBatchFile(e, employerId);
    this.rootStore[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: TEST_CSV_MAPPING_MODEL,
      title: 'CSV uploaded',
      props: {
        onChange: curriedChooseHrSftpBatchFile,
      },
    });
  }

  @action
  async loadEmployeeFileInfo(employerId: number): Promise<void> {
    this.loadingEmployeeFileInfo = true;
    this.employeeFileInfo = await this.hrService.findEmployeeFileInfo(employerId);
    this.loadingEmployeeFileInfo = false;
  }

  @action
  async onChangeEmployeeFile(event: ChangeEvent<HTMLInputElement>, url: string, employerId: number): Promise<void> {
    this.uploadingEmployeeFile = true;

    if (event.target.files && event.target.files.length) {

      if (this.isCsvFileName(event)) {
        const fileData = await InputFileReader.getContentFromFileInputEvent(event);

        await this.uploadEmployeeFile(fileData, url, employerId);
      } else {
        this.rootStore[STORE_UI].openModal({
          width: FORM_MODAL_WIDTH,
          componentKey: ERROR_MODAL,
          title: 'Error',
          props: {
            description: 'Parsing failed. Please make sure the file has valid CSV content',
          },
        });
      }
    }

    this.uploadingEmployeeFile = false;
  }

  @action
  async uploadEmployeeFile(file: string | ArrayBuffer | null, url: string, employerId: number): Promise<void> {
    this.uploadingEmployeeFile = true;
    return this.hrService.uploadEmployeeFile(file, url)
      .then(async () => {
        this.employeeFileUploadInfo = await this.hrService.processEmployeeFile(employerId);
        if (this.employeeFileUploadInfo.success) {
          this.employeeFileInfo.csvInfo = this.employeeFileUploadInfo.resultItem;
        }
        return;
      })
      .catch(() => {
        this.rootStore[STORE_UI].openModal({
          width: FORM_MODAL_WIDTH,
          componentKey: ERROR_MODAL,
          title: 'Error',
          props: {
            description: 'An unexpected error occurred. Please reload and try again.',
          },
        });
      })
      .finally(() => this.uploadingEmployeeFile = false);
  }

  private isCsvFileName(event: ChangeEvent<HTMLInputElement>): boolean {
    return (event.target && event.target.files &&
      event.target.files[0] &&
      !!event.target.files[0].name &&
      event.target.files[0].name.indexOf('.csv') !== -1) ?? false;
  }
}
