import * as React from 'react';
import * as Yup from 'yup';
import {CustomField, CustomButton, CustomInput, Loader} from 'components';
import {Form, Formik, FormikActions} from "formik";
import {inject, observer} from "mobx-react";
import classNames from 'classnames';
import styles from './CreateEmployerModal.module.scss';
import {STORE_UI, STORE_EMPLOYER, VALIDATION_RULES} from "appConstants";
import {UIStore, EmployerStore} from "stores";
import {RouteComponentProps} from "react-router";
import CreateEmployerInput from "../../../graphql/mutations/inputs/CreateEmployerInput";

interface FormValues {
  name: string;
}

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
  onCancel: () => void;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  name: VALIDATION_RULES.name
});

@inject(STORE_UI, STORE_EMPLOYER)
@observer
export default class CreateEmployerModal extends React.Component <Props, {}> {

  onSubmit = async ({ name }: FormValues, actions: FormikActions<FormValues>) => {
    this.props[STORE_UI].setLoadModalData();
    actions.setStatus();
    const employerData: CreateEmployerInput = {
      name: name
    };
    await this.props[STORE_EMPLOYER].createEmployer(employerData);
    this.props[STORE_UI].setLoadModalData(false);
    if(this.props[STORE_EMPLOYER].fetchError){
      actions.setStatus({ fetchError: this.props[STORE_EMPLOYER].fetchError })
    } else {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <Formik
        initialValues={{ name: '' }}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.onSubmit}
      >{ form => {
        const isDisabled = !form.dirty || !form.isValid;
        return <Form>
          <div className={classNames(styles.cPopupContent, styles.minHeight)}>
            <div>
              <div className={styles.cControlsLeft}>
                <CustomField
                  name="name"
                  title="Company name"
                  placeholder="Enter company..."
                  form={form}
                  autoFocus
                  value={form.values.name}
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
                <div className={classNames(styles.field, styles.leftPosition)}>
                  <label>
                    Enabled products
                    <CustomInput
                      value={"Workplace ISA"}
                      disabled={true}
                    />
                  </label>
                </div>
              </div>
              {form.status && <p className={styles.error}>{form.status.fetchError}</p>}
            </div>

            <div className={styles.cButtonRight}>
              {this.props[STORE_UI].loadModalData?<Loader className={styles.loader}/>:
              <CustomButton size={"xxs"} type="submit" buttonType="primary" disabled={isDisabled}>Create</CustomButton>
              }
            </div>
          </div>
        </Form>}}
      </Formik>
    );
  }
}
