import React from 'react';
import { inject, observer } from 'mobx-react';
import { EmployerUserModel } from 'graphql/models/employer';
import { RouteComponentProps } from 'react-router';
import { UIStore, EmployerStore, UserStore } from 'stores';

import { CenterBanner, TopContentBar, CustomSelect, TableLoader, NoData } from 'components';

import {
  STORE_UI,
  STORE_EMPLOYER,
  STORE_USER,
  EMPLOYER_DETAILS_TABS,
  EmployerUsersRoles,
  FORM_MODAL_WIDTH,
  CREATE_EMPLOYER_USER_MODAL,
  EDIT_EMPLOYER_USER_MODAL,
  DELETE_ACCEPTANCE_MODAL,
} from 'appConstants';

import { history } from 'index';
import classNames from 'classnames';
import styles from './EmployerUsersRolesPage.module.scss';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
  [STORE_USER]: UserStore;
}

const selectItems = [
  {title: 'Edit', value: 'edit'},
  {title: 'Delete', value: 'delete'}
];

const mySelectItems = [
  {title: 'Edit', value: 'edit'}
];

@inject(STORE_UI, STORE_EMPLOYER, STORE_USER)
@observer

class EmployerUsersRolesPage extends React.Component<Props, {}>{

  componentDidMount(): void {
    const {username} = this.props[STORE_USER].user;
    this.props[STORE_EMPLOYER].loadEmployerByAdminEmail(username, "employerUsers");
  }

  createNewEmployerUser = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: CREATE_EMPLOYER_USER_MODAL,
      title: 'New user',
      props: {
        employerId: this.props[STORE_EMPLOYER].currentEmployer.id,
        userName: this.props[STORE_USER].user.username,
      }
    });
  };

  editUser = (user: EmployerUserModel) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: EDIT_EMPLOYER_USER_MODAL,
      title: 'Edit user',
      props: {
        employerId: this.props[STORE_EMPLOYER].currentEmployer.id,
        userName: this.props[STORE_USER].user.username,
        employerUser: user
      }
    });
  };

  deleteUser = (user: EmployerUserModel) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: DELETE_ACCEPTANCE_MODAL,
      title: 'Delete user',
      props: {
        description: "Are you sure you want to delete this admin user? You won’t be able to undo this."
      },
      eventProps: {
        onSubmit: () => this.onSubmitDeleteEmployerUser(user.id, this.props[STORE_EMPLOYER].currentEmployer.id)
      }
    });
  };

  onSubmitDeleteEmployerUser = async (employerUserId: string, employerId: string) => {
    this.props[STORE_UI].setLoadModalData();
    await this.props[STORE_EMPLOYER].deleteAdminUser(employerUserId, employerId, this.props[STORE_USER].user.username);
    this.props[STORE_UI].setLoadModalData(false);

    if(!this.props[STORE_UI].fetchError){
      this.props[STORE_UI].closeModal();
    }
  };

  selectActions(action: string, user: EmployerUserModel){
    switch(action) {
      case 'edit':  this.editUser(user);
        break;
      case 'delete':  this.deleteUser(user);
        break;
    }
  }

  onTabAction = (val: string) => {
    history.push("/employer/"+val);
  };

  renderRoles(roles: string[]){
    roles = roles || [];
    return roles.map((role: string) => EmployerUsersRoles[role]).join(', ');
  };

  getSelectItems(adminEmail: string) {
    // according to line 46, this isn't even possible! user can never be null for this page to load!
    let myUserEmail = this.props[STORE_USER].user !== null ? this.props[STORE_USER].user.username : '';
    return myUserEmail === adminEmail ? mySelectItems : selectItems
  }

  renderSuperAdminsItems(){
    let users = this.props[STORE_EMPLOYER].currentEmployer.employerUsers || [];
    if(users.length){
      return users.map((user)=>
        <tr key={user.id}>
          <td>{user.firstName+" "+user.lastName}</td>
          <td>{user.email}</td>
          <td>{this.renderRoles(user.roles)}</td>
          <td className={styles.actionCell}>
            <CustomSelect
              items={this.getSelectItems(user.email)}
              onClickOption={(action)=>{this.selectActions(action, user)}}
            />
          </td>
        </tr>
      );
    } else return <NoData columnSpan={4} />
  }

  renderUsers() {
    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th className={styles.cuCol2}>Action</th>
        </tr>
        </thead>
        <tbody>
          {this.props[STORE_EMPLOYER].loadEmployerState?<TableLoader columnSpan={4} />:this.renderSuperAdminsItems()}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <>
        <CenterBanner
          homeUrl={this.props[STORE_USER].homeUrl}
          mainTitle={this.props[STORE_EMPLOYER].currentEmployer.name}
          subTitle={this.props[STORE_EMPLOYER].currentEmployer.clientId}
          tabs={EMPLOYER_DETAILS_TABS}
          activeTab={"users-roles"}
          onClickTab={this.onTabAction}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={"Users and roles"}
            actionButtonName={"Add new"}
            actionButtonEvent={this.createNewEmployerUser}
          />
          {this.renderUsers()}
        </div>
      </>
    );
  }

}
export default EmployerUsersRolesPage;