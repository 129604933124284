export const allEmployers = ` 
{
  allEmployers {
    id
    name
    clientId
  }
}
`;

export const employerById = ` 
query Employer($id: ID!){
  employer(
    id: $id
  ) {
    id
    name
    clientId
    employerUsers {
      id
      email
      firstName
      lastName
      roles
    },
    bankAccounts {
      id
      accountNumber
      sortingCode
      companyName
    },
  }
} 
`;

export const employerByPayrollAdminEmailQuery = ` 
query employerByPayrollAdminEmail($employerId: ID!, $email: String!){
  employerByPayrollAdminEmail(
    employerId: $employerId,
    email: $email
  ) {
    id
    name
    clientId
    payrollGroups {
      id
      name
      externalPayrollGroupId
      employerId
      payrollAdmins {
        id
        email
      }
      payPeriod {
        intervalType
        startDate
        cutoffTime
        nextPayDateAt
        updatedAt
       }
    }
  }
} 
`;

export const employerNameByPayrollAdminEmail = ` 
query employerByPayrollAdminEmail($employerId: ID!, $email: String!){
  employerByPayrollAdminEmail(
    employerId: $employerId,
    email: $email
  ) {
    id
    name
  }
} 
`;

export const integrationsByPayrollAdminEmail = ` 
query employerByPayrollAdminEmail($employerId: ID!, $email: String!){
  employerByPayrollAdminEmail(
    employerId: $employerId,
    email: $email
  ) {
    id
    name
    clientId
    payrollIntegrationConfig {
      connectionMethod
      sftpUrl
      sftpSettings {
        username
        publicKey
      }
      csvMapping {
        columnKey
        columnIndex
      }
    }
  }
} 
`;

export const employerByIdUsersRoles = ` 
query employerByIdUsersRoles($id: ID!){
  employer(
    id: $id
  ) {
    id
    name
    clientId
    employerUsers {
      id
      email
      firstName
      lastName
      roles
    }
  }
} 
`;

export const employerByIdBankAccounts = ` 
query employerByIdBankAccounts($id: ID!){
  employer(
    id: $id
  ) {
    id
    name
    clientId
    bankAccounts {
      id
      accountNumber
      sortingCode
      companyName
    },
  }
} 
`;

export const employerByIdPayrollGroups = ` 
query employerByIdPayrollGroups($id: ID!){
  employer(
    id: $id
  ) {
    id
    name
    payrollGroups {
      id
      name
      externalPayrollGroupId
      employerId
      payrollAdmins {
        id
        email
      }
    }
  }
} 
`;

export const employerByEmployerAdminUsername = ` 
query employerByEmployerAdminEmail($email: String){
  employerByEmployerAdminEmail(
    email: $email
  ) {
    id
    name
    clientId
    employerUsers {
      id
      email
      firstName
      lastName
      roles
    },
    bankAccounts {
      id
      accountNumber
      sortingCode
      companyName
    },
    payrollGroups {
      id
      name
      externalPayrollGroupId
      employerId
      bankAccount {
        id
        accountNumber
        sortingCode
      }
      payrollAdmins {
        id
        email
      }
    }
  }
} 
`;

export const employerUsersRolesByEmployerUsername = ` 
query employerByEmployerAdminEmail($email: String){
  employerByEmployerAdminEmail(
    email: $email
  ) {
    id
    name
    clientId
    employerUsers {
      id
      email
      firstName
      lastName
      roles
    },
  }
} 
`;

export const employerBankAccountsByEmployerUsername = ` 
query employerByEmployerAdminEmail($email: String){
  employerByEmployerAdminEmail(
    email: $email
  ) {
    id
    name
    clientId
    bankAccounts {
      id
      accountNumber
      sortingCode
      companyName
    },
  }
} 
`;

export const employerByHrEmailQuery = ` 
query employerByHrAdminEmail($email: String){
  employerByHrAdminEmail(
    email: $email
  ) {
    id
    name
    clientId
    payrollGroups {
      id
      name
      externalPayrollGroupId
      employerId
    }
  }
} 
`;

export const employerHrSftpConfigByHrEmailQuery = ` 
query employerByHrAdminEmail($email: String){
  employerByHrAdminEmail(
    email: $email
  ) {
    id
    clientId
    name
    hrSftpIntegrationConfig {
      sftpUrl
      sftpSettings {
        username
        publicKey
      }
      csvMapping {
        columnKey
        columnIndex
      }
    }
  }
} 
`;

export const employerByIdName = ` 
query employerByIdPayrollGroups($id: ID!){
  employer(
    id: $id
  ) {
    id
    name
  }
} 
`;