import React from 'react';
import styles from './PasswordlessForm.module.scss';
import {CustomInput, CustomButton, Loader} from 'components';
import {
  VALIDATION_RULES,
  LOGIN_VALIDATION_ERROR,
  LOGIN_VALIDATION_TIMEOUT_ERROR,
  LOGIN_SUPER_ADMIN_ERROR
} from 'appConstants';
import * as Yup from 'yup';
import { Formik, Form, Field, FormikActions } from 'formik';
import { history } from 'index';
import { Auth } from 'aws-amplify';

Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });

const VALIDATION_SCHEMA = Yup.object().shape({
  email: VALIDATION_RULES.email
});

interface FormValues {
  email: string;
  code: string;
}

interface Props {
  signIn: (user: any) => void;
  pathname: string;
}

interface State {
  isActiveCodeScreen: boolean;
  userObject: any;
  checkCodeFailed: boolean;
}

const initialState = {
  isActiveCodeScreen: false,
  userObject: {},
  checkCodeFailed: false
};

class PasswordlessForm extends React.PureComponent<Props, State> {
  state: State = initialState;

  renderUnknownError() {
    return (
      <>
        Unknown error occurred, <br/>please contact support via <a className={styles.cuLink}
                                                                   href='mailto:customercare@landg.com'>
        customercare@landg.com
      </a>
      </>
    )
  }

  onSignIn = ({email}: FormValues, actions: FormikActions<FormValues>) => {
    email = email.toLowerCase();
    actions.setStatus();
    Auth.signIn(email)
      .then(res => {
          if(res.signInUserSession === null){
              this.setState({isActiveCodeScreen: true, userObject: res})
              return;
          }
          this.props.signIn(res);
          history.push(this.props.pathname);
      })
      .catch((err) => {
        let pos: number = err.message.indexOf(LOGIN_VALIDATION_ERROR);
        if (pos === -1) {
          pos = err.message.indexOf(LOGIN_VALIDATION_TIMEOUT_ERROR);
        }
        if (pos === -1) {
          pos = err.message.indexOf(LOGIN_SUPER_ADMIN_ERROR);
        }
        if (pos !== -1) {
          let message: string = err.message.slice(pos);
          actions.setStatus({fetchError: message});
        } else {
          actions.setStatus({fetchError: 'Unknown'});
        }
      })
      .finally(() => actions.setSubmitting(false))
  };

  onVerify = ({ code }: FormValues, actions: FormikActions<FormValues>) => {
    actions.setStatus();
    Auth.sendCustomChallengeAnswer(this.state.userObject, code).then(res => {
      if(res.signInUserSession === null){
        this.setState({userObject: res});
        actions.setStatus({ fetchError: 'Confirmation code you provided is not valid' });
        return;
      }
      this.props.signIn(res);
      history.push(this.props.pathname);
    })
      .catch((err) => {
        let pos: number = err.message.indexOf(LOGIN_VALIDATION_TIMEOUT_ERROR);
        if (pos !== -1) {
          let message: string = err.message.slice(pos);
          actions.setStatus({fetchError: message});
        } else {
          actions.setStatus({fetchError: 'Confirmation code you provided is not valid'});
        }
        this.setState({checkCodeFailed: true});
      })
      .finally(() => actions.setSubmitting(false));
  };

  isEmptyCode(isActiveCodeScreen: Boolean, code: String){
    return (isActiveCodeScreen && code === '');
  }

  handleFormKeyPress(e: KeyboardEvent, form: any) {
    const isDisabled = !form.dirty || !form.isValid;
    if (e.key === 'Enter' && !isDisabled) {
      form.submitForm();
    }
  };

  renderUnuLoginButton() {
    return (
      <div>
        <div className={styles.orSection}>or</div>
        <CustomButton
          buttonType='primary'
          type='button'
          buttonColor='green'
          fullWidth
          onClick={() => {
            history.push('/sso/saml/unu');
          }}
        >
          Sign in with Coll8
        </CustomButton>
      </div>
    )
  }

  render() {
    const {isActiveCodeScreen, checkCodeFailed} = this.state;
    return (
      <div className={styles.form}>
        <h1 className={styles.cuTitle}>{isActiveCodeScreen ? 'Confirm email' : 'Login to SalaryChain'}</h1>
        <p
          className={styles.cuSubTitle}>{isActiveCodeScreen ? `We've emailed you a confirmation code - please enter it here` : 'You have been invited to SalaryChain'}</p>
        <Formik
          initialValues={{code: '', email: ''}}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={isActiveCodeScreen ? this.onVerify : this.onSignIn}
        >
          {form => {
            if (form.isSubmitting) {
              return (<Loader/>)
            }
            return (
            <Form>
              {isActiveCodeScreen &&
                <Field
                name='code'
                component={CustomInput}
                type='text'
                id='code'
                inputSize={'l'}
                autoFocus
                placeholder='6-digit admin code'
                value={form.values.code}
                onChange={form.handleChange}
                onKeyDown={(e:KeyboardEvent)=>{this.handleFormKeyPress(e, form)}}
              />}
              {!isActiveCodeScreen &&
                <Field
                  name='email'
                  component={CustomInput}
                  type='email'
                  id='email'
                  inputSize={'l'}
                  autoFocus
                  placeholder='Enter your email'
                  value={form.values.email}
                  onChange={form.handleChange}
                  onKeyDown={(e: KeyboardEvent) => {
                    this.handleFormKeyPress(e, form)
                  }}
                />}
              {form.errors.email && (<p className={styles.error}>{form.errors.email}</p>)}
              {!form.errors.email && (form.status && <p className={styles.error}>
                {form.status.fetchError === 'Unknown' ? this.renderUnknownError() : form.status.fetchError}
              </p>)}
              <CustomButton
                className={styles.marginTop}
                disabled={!form.dirty || form.isSubmitting || this.isEmptyCode(isActiveCodeScreen, form.values.code) || checkCodeFailed}
                buttonType='primary'
                type='submit'
                buttonColor='green'
                fullWidth>
                Sign in
              </CustomButton>
              {!isActiveCodeScreen && !form.isSubmitting && this.renderUnuLoginButton()}
            </Form>);
          }}
        </Formik>
      </div>
    );
  }
}

export default PasswordlessForm;
