export const EMPLOYER_TABS = [
  { title: 'Bank accounts', value: 'bank-accounts' },
  { title: 'Admin users', value: 'admins' },
];

export const SETTINGS_TABS = [
  { title: 'Downloads', value: 'downloads' },
  { title: 'Super admins', value: 'super-admins' },
];

export const EMPLOYER_DETAILS_TABS = [
  { title: 'Bank accounts', value: 'bank-accounts' },
  { title: 'Users & roles', value: 'users-roles' },
  { title: 'Payroll groups', value: 'payroll-groups' },
];

export const PAYROLL_GROUPS_TABS = [
  { title: 'Payroll groups', value: 'groups' },
  { title: 'Integration', value: 'integration' },
];
