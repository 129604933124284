import React from 'react';
import {inject, observer} from 'mobx-react';
import {RouteComponentProps} from 'react-router';
import {AdminStore, UIStore, UserStore} from 'stores';
import {CenterBanner, Loader, TopContentBar} from 'components';
import {AuditFileType, SETTINGS_TABS, STORE_ADMIN, STORE_UI, STORE_USER} from 'appConstants';

import {history} from 'index';
import classNames from 'classnames';
import styles from './AdminDownloadsPage.module.scss';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_ADMIN]: AdminStore;
  [STORE_USER]: UserStore;
}

@inject(STORE_UI, STORE_ADMIN, STORE_USER)
@observer

class AdminDownloadsPage extends React.Component<Props, {}>{
  onTabAction = (val: string) => {
    history.push('/admin/'+val);
  };

  renderDownloadsList() {
    const imageUrl = '/images/file-csv.svg';
    return (
      <>
        <tr key={1}>
          <td>
            <img className={styles.tableIcon} src={imageUrl} alt=''/>
            <strong>full-cass-audit.csv</strong>
          </td>
          <td className={styles.actionCol}>
            {this.props[STORE_ADMIN].auditLoadState['Full'] ? <Loader/> :
              <button
                onClick={
                  () => {
                    this.props[STORE_ADMIN].generateAuditFile(AuditFileType.Full)
                  }
                }
                className={styles.tableLink}
              >
                Download
              </button>
            }
          </td>
        </tr>
        <tr key={2}>
          <td>
            <img className={styles.tableIcon} src={imageUrl} alt=''/>
            <strong>all-leavers-and-rejected.csv</strong>
          </td>
          <td className={styles.actionCol}>
            {this.props[STORE_ADMIN].auditLoadState['RejectedAndLeavers'] ? <Loader/> :
              <button
                onClick={
                  () => {
                    this.props[STORE_ADMIN].generateAuditFile(AuditFileType.RejectedAndLeavers)
                  }}
                className={styles.tableLink}>
                Download
              </button>
            }
          </td>
        </tr>
      </>
    );
  }

  renderDownloads() {
    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
        <tr>
          <th className={styles.fileCol}>File</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {this.renderDownloadsList()}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <>
        <CenterBanner
          mainTitle={'Settings'}
          tabs={SETTINGS_TABS}
          activeTab={'downloads'}
          onClickTab={this.onTabAction}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={'Audit and MI downloads'}
          />
          {this.renderDownloads()}
        </div>
      </>
    );
  }
}

export default AdminDownloadsPage;
