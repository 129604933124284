import * as React from 'react';
import * as Yup from 'yup';
import {CustomField, CustomButton, CustomFormSelect, Loader} from 'components';
import {Form, Formik, FormikActions, FieldArray} from "formik";
import {inject, observer} from "mobx-react";
import classNames from 'classnames';
import {SelectOption} from 'interfaces';
import {STORE_USER, STORE_UI, STORE_EMPLOYER, VALIDATION_RULES, EmployerUsersRoles} from "appConstants";
import {UIStore, EmployerStore, UserStore} from "stores";
import {RouteComponentProps} from "react-router";
import styles from './EditEmployerUserModal.module.scss';
import UpdateEmployerUserInput from "graphql/mutations/inputs/UpdateEmployerUserInput";
import {EmployerUserModel} from "graphql/models/employer";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  roles: RolesValues[];
}

interface RolesValues {
  id: string,
  label: string,
  value: string,
  selectedControl: string
}

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
  [STORE_USER]: UserStore;
  onCancel: () => void;
  employerId: string;
  employerUser: EmployerUserModel;
  userName: string;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: VALIDATION_RULES.firstName,
  lastName: VALIDATION_RULES.lastName,
  roles: Yup.array().of(
    Yup.object().shape({
        value: VALIDATION_RULES.role,
      }
    )
  )
});

@inject(STORE_UI, STORE_EMPLOYER, STORE_USER)
@observer

export default class EditEmployerUserModal extends React.Component <Props, {}> {

  initialValues: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    roles: [
      {
        id: "0",
        label: "",
        value: "",
        selectedControl: ""
      }
      ]
  };

  selectCount: number = 1;

  rolesList = [
    {id: "0", label: 'Admin', value: 'EmployerAdmin', selectedControl: ""},
    {id: "1", label: 'HR', value: 'HrAdmin', selectedControl: ""},
    {id: "2", label: 'Payroll', value: 'PayrollAdmin', selectedControl: ""}
  ];

  UNSAFE_componentWillMount(): void {
    let initialRoles = this.props.employerUser.roles.map((role, index) => {
      return {id: index.toString(), label: EmployerUsersRoles[role], value: role, selectedControl: `roles[${index}]`}
    });

    // Set form initial values
    this.initialValues = {
      firstName: this.props.employerUser.firstName,
      lastName: this.props.employerUser.lastName,
      email: this.props.employerUser.email,
      roles: initialRoles
    };

    // Set select controls values
    this.rolesList = this.rolesList.map((item) => {
      let selectNameIndex = this.props.employerUser.roles.findIndex(role => role === item.value);
      if(selectNameIndex!==-1){
        item.selectedControl = `roles[${selectNameIndex}]`
      }
      return item
    });
  }

  onSubmit = async ({ email, firstName, lastName, roles }: FormValues, actions: FormikActions<FormValues>) => {
    let rolesArray = roles.map((role: any)=>role.value);
    this.props[STORE_UI].setLoadModalData();
    actions.setStatus();

    const employerUserData: UpdateEmployerUserInput = {
      id: this.props.employerUser.id,
      firstName: firstName,
      lastName: lastName,
      roles: rolesArray
    };

    await this.props[STORE_EMPLOYER].updateAdminUser(employerUserData, this.props.employerId, this.props.userName);
    if (this.props.userName === this.props.employerUser.email) {
      this.props[STORE_USER].updateToken();
    }

    this.props[STORE_UI].setLoadModalData(false);
    if (this.props[STORE_EMPLOYER].fetchError) {
      actions.setStatus({fetchError: this.props[STORE_EMPLOYER].fetchError})
    } else {
      this.props.onCancel();
    }
  };

  selectHandler(name: string, value: SelectOption, form: any) {
    form.setFieldValue(name, value);

    let oldIndex = this.rolesList.findIndex(item => item.selectedControl === name);
    if(oldIndex!==-1){
      this.rolesList[oldIndex].selectedControl = "";
    }

    let currentIndex = this.rolesList.findIndex(item => item.value === value.value);
    this.rolesList[currentIndex].selectedControl = name;
  };

  getRolesList(name: string) {
    return this.rolesList.filter((item: any) => {
      return item.selectedControl === name || item.selectedControl === ""
    });
  }

  renderAddRoleButton(push: (obj: any) => void, roles: RolesValues[]) {
    if (this.selectCount < this.rolesList.length && roles[roles.length - 1].value !== "") {
      return (
        <a
          className={styles.cuLink}
          onClick={(e) => {
            e.preventDefault();
            push({id: ++this.selectCount, label: "", value: "", selectedControl: ""})
          }
          }
          href="/"
        >+ Add another</a>
      )
    } else return ;
  }

  renderRemoveRoleButton(remove: (index: number) => void, index: number, controlName: string) {
    if(this.selectCount > 1) {
      return (
        <a
          className={classNames(styles.deleteBtn)}
          onClick={(e) => {
            let rolesList = this.rolesList || [];
            rolesList.forEach((item, index)=>{
              if (item.selectedControl === controlName) {
                rolesList[index].selectedControl = "";
              }
            });
            e.preventDefault();
            remove(index);
            this.rolesList = rolesList;
            this.selectCount--;
          }
          }
          href="/"
        >-</a>
      )
    } else return ;
  }

  render() {
    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.onSubmit}
      >{ form => {
        const isDisabled = !form.dirty || !form.isValid;
        return <Form>
          <div className={classNames(styles.cPopupContent, styles.minHeight)}>
            <div>
              <div className={styles.cControlsLeft}>
                <CustomField
                  name="firstName"
                  title="First name"
                  placeholder="Enter first name..."
                  form={form}
                  autoFocus
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
                <CustomField
                  name="lastName"
                  title="Last name"
                  placeholder="Enter last name..."
                  form={form}
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
              </div>

              <div className={styles.cControlsLeft}>
                <CustomField
                  disabled={true}
                  name="email"
                  title="Email"
                  placeholder="Enter email..."
                  form={form}
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
                <div className={styles.leftPosition} />
              </div>
              <FieldArray name="roles">{({push, remove}) => (
                <div>
                  <label htmlFor="roles[0]">
                    Role(s)
                  </label>

                  {
                    form.values.roles.map((role: any, index: number) => {
                      this.selectCount = 1+index;
                      const controlName = `roles[${index}]`;
                      let options = this.getRolesList(controlName);
                      return (
                        <div className={styles.cControlsLeft} key = {role.id}>
                          <CustomField
                            name = {controlName}
                            title=""
                            placeholder="Enter role..."
                            form={form}
                            defaultValue={options ? options.find(option => option.selectedControl === controlName) : ''}
                            component={CustomFormSelect}
                            className={styles.leftPositionPadd}
                            options={options}
                            onSelect={(name, value)=>this.selectHandler(name, value, form)}
                            onBlur={()=>{form.setFieldTouched(controlName)}}
                            onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                          />
                          {this.renderRemoveRoleButton(remove, index, controlName)}
                        </div>
                      )
                    })
                  }

                  <div className={styles.cLinks}>
                    {this.renderAddRoleButton(push, form.values.roles)}
                  </div>
                </div>
              )}
              </FieldArray>

              {form.status && <p className={styles.error}>{form.status.fetchError}</p>}
            </div>
            <div className={styles.cButtonRight}>
              {this.props[STORE_UI].loadModalData?<Loader className={styles.loader}/>:
              <CustomButton size={"xxs"} type="submit" buttonType="primary" disabled={isDisabled}>Save</CustomButton>
              }
            </div>
          </div>
        </Form>}}
      </Formik>
    );
  }
}
