import React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { UIStore, EmployerStore, UserStore } from 'stores';

import { CenterBanner, TopContentBar, TableLoader, NoData } from 'components';

import {
  STORE_UI,
  STORE_EMPLOYER,
  STORE_USER,
  EMPLOYER_DETAILS_TABS,
} from 'appConstants';

import { history } from 'index';
import classNames from 'classnames';
import styles from './EmployerBankDetailsPage.module.scss';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
  [STORE_USER]: UserStore;
}

@inject(STORE_UI, STORE_EMPLOYER, STORE_USER)
@observer

class EmployerBankDetailsPage extends React.Component<Props, {}>{

  componentDidMount(): void {
    const {username} = this.props[STORE_USER].user;
    this.props[STORE_EMPLOYER].loadEmployerByAdminEmail(username, 'bankAccounts');
  }

  onTabAction = (val: string) => {
    history.push('/employer/'+val);
  };

  renderBankAccountsItems(){
    let bankAccounts = this.props[STORE_EMPLOYER].currentEmployer.bankAccounts || [];
    if(bankAccounts.length){
      return bankAccounts.map((bankAccount)=>
        <tr key={bankAccount.id}>
          <td>{bankAccount.accountNumber}</td>
          <td>{this.props[STORE_UI].sortingCodeFormat(bankAccount.sortingCode)}</td>
          <td className={styles.colCompany}>{bankAccount.companyName}</td>
        </tr>
      );
    } else return <NoData columnSpan={3} />
  }

  renderBankAccounts() {
    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
        <tr>
          <th className={styles.colAccountNumber}>Account number</th>
          <th className={styles.colSortCode}>Sort code</th>
          <th className={styles.colCompany}>Company name</th>
        </tr>
        </thead>
        <tbody>
          {this.props[STORE_EMPLOYER].loadEmployerState?<TableLoader columnSpan={3} />:this.renderBankAccountsItems()}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <>
        <CenterBanner
          mainTitle={this.props[STORE_EMPLOYER].currentEmployer.name}
          subTitle={this.props[STORE_EMPLOYER].currentEmployer.clientId}
          tabs={EMPLOYER_DETAILS_TABS}
          activeTab={'bank-accounts'}
          onClickTab={this.onTabAction}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={'Bank accounts'}
          />
          {this.renderBankAccounts()}
        </div>
      </>
    );
  }

}
export default EmployerBankDetailsPage;