export * from './stores';
export * from './roles';
export * from './validation';
export * from './tabs';
export * from './modals';
export * from './selects';
export * from './routes';

export enum AuthState {
  SIGN_IN = 'signIn',
  SIGNED_IN = 'signedIn',
  LOADING = 'loading',
}

export const LOGOUT_TIMEOUT = 1000 * 60 * 15;
export const COUNT_SCROLL_ITEMS = 50;

export enum IntervalTypeEnum {
  Weekly = 'Weekly',
  BiWeekly = 'BiWeekly',
  FourWeekly = 'FourWeekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  BiAnnually = 'BiAnnually',
  Annually = 'Annually',
}

export const PaymentStatus: { [key: string]: string } = {
  NEW: 'New',
  PROCESSING: 'Processing',
  PROCESSED: 'Processed',
  DECLINED: 'Declined',
};

export const PaymentFrequency: { [key: string]: string } = {
  SingleLumpSum: 'Lump sum',
  RegularPaymentSum: 'Regular',
};

export enum CsvUploadTableShowOptions {
  PayrollGroupsPayment,
  HrSftpConfig,
}

export enum AuditFileType {
  Full = 'Full',
  RejectedAndLeavers = 'RejectedAndLeavers',
}

export const MAX_AUDIT_DOWNLOAD_RETRIES = 20;
export const TIMEOUT_AUDIT_FULL_REQUESTS = 1000 * 5;
export const TIMEOUT_AUDIT_RAL_REQUESTS = 1000;

export const MAX_INDEX_OF_CSV_COLUMN = 30;
export const MAX_INDEX_OF_CSV_COLUMN_PAYROLL_INTEGRATION = 15;
export const SFTP_CONNECTION_METHOD = 'sftp';
export const MANUAL_CONNECTION_METHOD = 'manual';

export const ADMIN_EMAIL = 'customercare@landg.com';
