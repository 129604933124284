import React from 'react';
import { inject, observer } from 'mobx-react';
import { CenterBanner, TopContentBar, TableLoader, NoData } from 'components';
import { RouteComponentProps } from 'react-router';
import { UIStore, PayrollStore, EmployerStore, UserStore } from 'stores';

import {
  STORE_UI,
  STORE_PAYROLL,
  FORM_MODAL_WIDTH,
  CONFIGURE_PAY_PERIOD_MODAL,
  STORE_USER,
  STORE_EMPLOYER,
  PaymentStatus,
  DELETE_ACCEPTANCE_MODAL,
} from 'appConstants';

import { history } from 'index';
import styles from './PayrollGroupPaymentsPage.module.scss';
import classNames from 'classnames';
import {Payment, PayrollPaymentSlot} from 'graphql/models/payroll';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_PAYROLL]: PayrollStore;
  [STORE_EMPLOYER]: EmployerStore;
  [STORE_USER]: UserStore;
}

@inject(STORE_UI, STORE_PAYROLL, STORE_EMPLOYER, STORE_USER)
@observer

class PayrollGroupPaymentsPage extends React.PureComponent<Props, {}>{

  async componentDidMount() {
    const {employerId, groupId} = this.props.match.params;
    const {username} = this.props[STORE_USER].user;
    const payrollStore = this.props[STORE_PAYROLL];

    payrollStore.clearPayrollGroup();
    await this.props[STORE_EMPLOYER].loadEmployerByPayrollAdminEmail(employerId, username, 'employerName');
    await payrollStore.loadPayrollGroupById(groupId);
    payrollStore.loadEmployeeNewContributionsCount(groupId);
  }

  breadCrumbs = [{title: '', linkTo: ''}];

  onTabAction = (val: string) => {
    const { groupId, employerId } = this.props.match.params;
    history.push('/payroll/'+employerId+'/group/'+groupId+'/'+val);
  };

  configurePayPeriod = (e: React.MouseEvent) => {
    const { groupId } = this.props.match.params;
    e.preventDefault();
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: CONFIGURE_PAY_PERIOD_MODAL,
      title: 'Configure pay period',
      props: {
        payrollGroupId: groupId,
        payPeriod: this.props[STORE_PAYROLL].payrollGroup.payPeriod
      }
    });
  };

  renderFileName(payment: Payment){
    const imageUrl = '/images/file-csv.svg';
    if(!payment) {
      return <strong>No file uploaded yet</strong>;
    }
    if (payment.name) {
      return <>
        <img className={styles.tableIcon} src={imageUrl} alt=''/>
        <strong>{payment.name}</strong>
      </>
    } else return '';
  };

  renderFileUploadLink(caption: string, index: string, paymentSlot: PayrollPaymentSlot, includePeriod = true) {
    const {groupId} = this.props.match.params;
    if (!paymentSlot.paymentUploadLink) return null;
    return <>
      {includePeriod && paymentSlot.payment && <>. </>}
      <label className={styles.tableLink} htmlFor={'uploadFile' + index}>{caption}</label>
      <input
        type='file'
        name='uploadFile'
        className={styles.uploadFile}
        id={'uploadFile' + index}
        accept='.csv'
        onChange={(e) => {
          this.props[STORE_PAYROLL].onChangeFile(e, paymentSlot, groupId)
        }}
      />
    </>
  }

  onSubmitDeletePaymentFile = async (payrollGroupId: string, paymentFileId: string) => {
    this.props[STORE_UI].setLoadModalData();
    await this.props[STORE_PAYROLL].deletePaymentFile(payrollGroupId, paymentFileId);
    this.props[STORE_UI].setLoadModalData(false);

    if (!this.props[STORE_UI].fetchError) {
      this.props[STORE_UI].closeModal();
    }
  };

  deletePaymentFile = (payrollGroupId: string, paymentFileId: string) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: DELETE_ACCEPTANCE_MODAL,
      title: 'Delete payment file',
      props: {
        description: 'Are you sure you want to delete this payment file? You won’t be able to undo this.',
      },
      eventProps: {
        onSubmit: () => this.onSubmitDeletePaymentFile(payrollGroupId, paymentFileId)
      }
    });
  };

  renderDeletePaymentFile(payrollGroupId: string, paymentFileId: string): JSX.Element {
    return <>
      <div className={styles.tableLink} onClick={() => this.deletePaymentFile(payrollGroupId, paymentFileId)}>Delete</div>
    </>;
  }

  renderNewStatusActions(index: string, payrollGroupId: string, paymentSlot: PayrollPaymentSlot): JSX.Element {
    return <>
      <div>
        {this.renderFileUploadLink('Reupload', index, paymentSlot, false)}
      </div>
      <div>
        {this.renderDeletePaymentFile(payrollGroupId, paymentSlot.payment.id)}
      </div>
    </>;
  }

  renderStatus(index: string, paymentSlot: PayrollPaymentSlot) {
    const payment = paymentSlot.payment;
    let showLoader = paymentSlot.payment && this.props[STORE_PAYROLL].loadPaymentFileId === paymentSlot.payment.id;
    const { groupId } = this.props.match.params;

    if (!payment) {
      return this.props[STORE_PAYROLL].loadPaymentFileId ? 'Loading...' : this.renderFileUploadLink('Upload', index, paymentSlot)
    }

    const status = payment.status;
    if (status === 'NEW') {
      return <>
        {showLoader ? ' Loading...' : this.renderNewStatusActions(index, groupId, paymentSlot)}
      </>;
    } else if (status === 'DECLINED') {
      return <>
        {PaymentStatus[status]}{showLoader ? ' Loading...' : this.renderFileUploadLink('Reupload', index, paymentSlot)}
      </>;
    }
    else {
      return PaymentStatus[status];
    }
  }

  renderPayrollGroupPaymentsList(paymentSlots: PayrollPaymentSlot[]){
    const storeUI = this.props[STORE_UI];
    if(paymentSlots.length){
      return paymentSlots.map((item, index)=>
        <tr key={index}>
          <td>{storeUI.getFormattedDateFromTimestamp(item.payDateAt)}</td>

          <td>{this.renderFileName(item.payment)}</td>

          <td>{item.payment ? storeUI.getFormattedDateFromTimestamp(item.payment.uploadedAt) : ''}</td>

          <td>
            <strong>{item.payment ? storeUI.getFormattedMoney(item.payment.amount) : ''}</strong>
          </td>

          <td className={styles.alignRight}>{this.renderStatus(index.toString(), item)}</td>
        </tr>
      );
    }
    return <NoData columnSpan={5} />
  }

  renderPayrollGroupPayments() {
    let paymentSlots = this.props[STORE_PAYROLL].payrollGroup.paymentSlots || [];
    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
        <tr>
          <th>Pay date</th>
          <th className={styles.fileCol}>File</th>
          <th>Upload date</th>
          <th>Amount</th>
          <th className={styles.thStatus}>Status / Action</th>
        </tr>
        </thead>
        <tbody>
        {this.props[STORE_PAYROLL].loadPayrollGroup ? <TableLoader columnSpan={5}/> : (
          this.renderPayrollGroupPaymentsList(paymentSlots)
        )}
        </tbody>
      </table>
    );
  }

  getMainTitle(){
    const storeEmployer = this.props[STORE_EMPLOYER];
    const storePayroll = this.props[STORE_PAYROLL];

    if(storeEmployer.currentEmployer.name && storePayroll.payrollGroup.name){
      return 'Payroll - '+storeEmployer.currentEmployer.name+' - '+storePayroll.payrollGroup.name;
    }

    return 'Payroll';
  };

  render() {
    const storeEmployer = this.props[STORE_EMPLOYER];
    const storePayroll = this.props[STORE_PAYROLL];
    const emptyPayPeriod = {  intervalType: '', startDate: '', cutoffTime: 0, nextPayDateAt: '', updatedAt: ''};

    this.breadCrumbs = [
        {title: storeEmployer.currentEmployer.name, linkTo: '/payroll/'+storeEmployer.currentEmployer.id+'/groups'},
        {title: storePayroll.payrollGroup.name, linkTo: ''},
    ];

    let payPeriod = storePayroll.payrollGroup.payPeriod || emptyPayPeriod;

    return (
      <>
        <CenterBanner
          homeUrl={this.props[STORE_USER].homeUrl}
          mainTitle={this.getMainTitle()}
          tabs={storePayroll.paymentTabs}
          activeTab={'payments'}
          onClickTab={this.onTabAction}
          breadCrumbs={this.breadCrumbs}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={'Pay period ending '+this.props[STORE_UI].getFormattedDate(payPeriod.nextPayDateAt)}
            actionButtonName={storeEmployer.loadEmployerState || storePayroll.loadPayrollGroup ? '':'Configure pay period'}
            actionButtonEvent={this.configurePayPeriod}
          />
          {this.renderPayrollGroupPayments()}
        </div>
      </>
    );
  }
}

export default PayrollGroupPaymentsPage;
