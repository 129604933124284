import React from 'react';
import { inject, observer } from 'mobx-react';
import { CenterBanner, TopContentBar, TableLoader, NoData } from 'components';
import { RouteComponentProps } from 'react-router';
import { PayrollStore, UIStore, EmployerStore, UserStore } from 'stores';
import {
  STORE_UI,
  STORE_PAYROLL,
  STORE_EMPLOYER,
  PAYROLL_GROUPS_TABS,
  STORE_USER
} from 'appConstants';
import { history } from 'index';
import styles from './PayrollGroupsPage.module.scss';
import classNames from 'classnames';


export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_PAYROLL]: PayrollStore;
  [STORE_EMPLOYER]: EmployerStore;
  [STORE_USER]: UserStore;
}

@inject(STORE_UI, STORE_PAYROLL, STORE_EMPLOYER, STORE_USER)
@observer

class PayrollGroupsPage extends React.PureComponent<Props, {}>{

  breadCrumbs = [{title: "", linkTo: ""}];

  onTabAction = (val: string) => {
    const { employerId } = this.props.match.params;
    history.push("/payroll/"+employerId+"/"+val);
  };

  componentDidMount(): void {
    const { employerId } = this.props.match.params;
    const { username } = this.props[STORE_USER].user;
    this.props[STORE_EMPLOYER].loadEmployerByPayrollAdminEmail(employerId, username);
  }

  redirectToPayrollGroup(groupId: string) {
    const { employerId } = this.props.match.params;
    history.push("/payroll/" + employerId + "/group/" + groupId + "/new-contributions")
  }

  getNextPayDate(payPeriod: any) {
    return payPeriod && payPeriod.nextPayDateAt ? this.props[STORE_UI].getFormattedDate(payPeriod.nextPayDateAt) : ""
  }

  renderPayrollGroupsList() {
    let payrollGroups = this.props[STORE_EMPLOYER].currentEmployer.payrollGroups || [];
    if(payrollGroups.length){
      return payrollGroups.map((group, index)=>
        <tr onClick={() => {this.redirectToPayrollGroup(group.id)}} key={index}>
          <td>{group.name}</td>
          <td className={styles.numberCol}>{this.getNextPayDate(group.payPeriod)}</td>
        </tr>
      );
    } else return <NoData columnSpan={2} />
  }

  showPayrollGroups() {
    return (
      <table className={classNames(styles.cuTable)}>
        <thead>
        <tr>
          <th className={styles.companyCol}>Payroll group</th>
          <th className={styles.numberCol}>Next pay date</th>
        </tr>
        </thead>
        <tbody>
          {this.props[STORE_EMPLOYER].loadEmployerState ? <TableLoader columnSpan={2} /> : this.renderPayrollGroupsList()}
        </tbody>
      </table>
    );
  }

  render() {
    this.breadCrumbs = [{title: this.props[STORE_EMPLOYER].currentEmployer.name, linkTo: ""}];
    return (
      <>
        <CenterBanner
          homeUrl={this.props[STORE_USER].homeUrl}
          mainTitle={"Payroll - " + this.props[STORE_EMPLOYER].currentEmployer.name}
          tabs={PAYROLL_GROUPS_TABS}
          activeTab={"groups"}
          onClickTab={this.onTabAction}
          breadCrumbs={this.breadCrumbs}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={"Payroll groups"}
          />
          {this.showPayrollGroups()}
        </div>
      </>
    );
  }
}

export default PayrollGroupsPage;
