import React from 'react';
import {Link} from 'react-router-dom';
import styles from './HeaderIntro.module.scss';


class HeaderIntro extends React.Component<{}, {}> {

  render() {

    return (
      <header className={styles.cHeader}>
        <div className={styles.cLeft}>
          <Link
            className={styles.logo}
            to={"/"}
          >
            <img src="/images/logo-desktop.svg" alt="logo"/>
          </Link>
        </div>

        <div className={styles.cRight}/>
      </header>
    );
  }
}

export default HeaderIntro;