import React from 'react';
import {Link} from 'react-router-dom';
import {STORE_USER} from 'appConstants';
import styles from './CenterBanner.module.scss';
import {inject, observer} from "mobx-react";

interface TabsAttr {
  title: string;
  value: string;
}

interface breadCrumbs {
  title: string;
  linkTo: string;
}

export interface Props {
  homeUrl?: string;
  mainTitle?: string;
  subTitle?: string;
  tabs?: TabsAttr[];
  activeTab?: string;
  onClickTab?: (val: string) => void;
  breadCrumbs?: breadCrumbs[];
}


@inject(STORE_USER)
@observer

class CenterBanner extends React.Component<Props, {}> {

  componentDidMount(): void {
    if (this.props.activeTab) {
      this.setState({activeTab: this.props.activeTab});
    }
  }

  renderTabsItems(){
    if(this.props.tabs){
      const tabs = this.props.tabs;
      return tabs.map((item, index)=>
        <li
          data-testid='tab'
          key={index}
          className={item.value===this.props.activeTab?styles.active:''}
          onClick={()=>{this.props.onClickTab && this.props.onClickTab(item.value)}}
        >
          {item.title}
        </li>
      );
    }
  }

  renderTabs() {
    return (
      <ul className={styles.cTabs} data-testid='tablist'>
        {this.renderTabsItems()}
      </ul>
    )
  }

  renderBreadCrumbs() {
    let bc = this.props.breadCrumbs || [];
    return bc.map((item, index) => {
      if(item.linkTo!==""){
        return <li key={index}><Link to={item.linkTo}>{item.title}</Link></li>
      } else {
        return <li key={index}>{item.title}</li>
      }
    });
  }

  render() {
    return (
      <div className={styles.cBanner}>
        <ul className={styles.cBreadCrumbs}>
          <li className={styles.bcActive}>
            <img className={styles.bcIcon} src='/images/i-home.svg' alt='Home'/>
            <Link className={styles.bkItem} to={this.props.homeUrl?this.props.homeUrl:'/'}>Home</Link>
          </li>
          {this.renderBreadCrumbs()}
        </ul>
        <h1 className={styles.mainTitle}>{this.props.mainTitle}</h1>
        <h2 className={styles.subTitle}>{this.props.subTitle}</h2>
        {this.props.tabs && this.renderTabs()}
      </div>
    );
  }
}
export default CenterBanner;
