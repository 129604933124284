import React from 'react';
import { inject, observer } from 'mobx-react';
import { CenterBanner, TopContentBar, CustomSelect, TableLoader, NoData } from 'components';
import { RouteComponentProps } from 'react-router';
import { UIStore, EmployerStore } from 'stores';
import {
  STORE_UI,
  STORE_EMPLOYER,
  CREATE_EMPLOYER_MODAL,
  EDIT_EMPLOYER_MODAL,
  FORM_MODAL_WIDTH, DELETE_ACCEPTANCE_MODAL
} from 'appConstants';
import { history } from 'index';
import styles from './EmployersPage.module.scss';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
}

const selectItems = [
  {title: 'Edit', value: 'edit'},
  {title: 'Delete', value: 'delete'}
];

@inject(STORE_UI, STORE_EMPLOYER)
@observer

class EmployersPage extends React.PureComponent<Props, {}>{

  componentDidMount(): void {
    this.props[STORE_EMPLOYER].loadEmployersList();
  }

  createNewEmployer = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: CREATE_EMPLOYER_MODAL,
      title: 'New employer',
    });
  };

  editEmployer = () => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: EDIT_EMPLOYER_MODAL,
      title: 'Edit employer',
    });
  };

  onSubmitDeleteEmployer = async (employerId: string) => {
    this.props[STORE_UI].setLoadModalData();
    await this.props[STORE_EMPLOYER].deleteEmployer(employerId);
    this.props[STORE_UI].setLoadModalData(false);

    if (!this.props[STORE_UI].fetchError) {
      this.props[STORE_UI].closeModal();
    }
  };

  deleteEmployer = (employerId: string) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: DELETE_ACCEPTANCE_MODAL,
      title: 'Delete employer',
      props: {
        description: "Are you sure you want to delete this employer? Has the last payroll run been processed? The deletion will be immediate and this action cannot be reversed."
      },
      eventProps: {
        onSubmit: () => this.onSubmitDeleteEmployer(employerId)
      }
    });
  };

  selectActions(action: string, employerId: string) {
    switch (action) {
      case 'edit':
        this.editEmployer();
        break;
      case 'delete':
        this.deleteEmployer(employerId);
        break;
      default:
    }
  }

  renderEmployersList() {
    if (this.props[STORE_EMPLOYER].employersList.length) {
      return this.props[STORE_EMPLOYER].employersList.map((employer) =>
        <tr onClick={() => {
          this.props[STORE_EMPLOYER].clearEmployer();
          history.push("/admin/employers/" + employer.id + "/bank-accounts")
        }} key={employer.id}>
          <td>{employer.name}</td>
          <td>{employer.clientId}</td>
          <td className={styles.cuCol2}>
            <CustomSelect
              items={selectItems}
              onClickOption={
                (action) => {
                  this.props[STORE_EMPLOYER].setEmployer(employer);
                  this.selectActions(action, employer.id)
                }
              }
            />
          </td>
        </tr>
      );
    } else return <NoData columnSpan={4} />
  }

  render() {
    return (
      <>
        <CenterBanner
          mainTitle={"SalaryChain"}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={"Employers"}
            actionButtonName={"Create new"}
            actionButtonEvent={this.createNewEmployer}
          />
          <table className={styles.cuTable}>
            <thead>
              <tr>
                <th className={styles.cuCol5}>Name</th>
                <th>Client ID</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props[STORE_EMPLOYER].loadEmployers ? <TableLoader columnSpan={3} /> : this.renderEmployersList()}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
export default EmployersPage;
