import React from 'react';
import {inject, observer} from 'mobx-react';
import {CenterBanner, TopContentBar, CustomSelect, NoData, TableLoader} from 'components';
import {BankAccountsModel} from 'graphql/models/employer';
import { RouteComponentProps } from 'react-router';
import { UIStore, EmployerStore } from 'stores';
import {
  STORE_UI,
  STORE_EMPLOYER,
  EMPLOYER_TABS,
  CREATE_BANK_ACCOUNT_MODAL,
  EDIT_BANK_ACCOUNT_MODAL,
  DELETE_BANK_ACCOUNT_MODAL,
  FORM_MODAL_WIDTH
} from 'appConstants';
import { history } from 'index';
import styles from './EmployerBankAccountPage.module.scss';
import classNames from 'classnames';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
}

const selectItems = [
  {title: 'Edit', value: 'edit'},
  {title: 'Delete', value: 'delete'}
];

@inject(STORE_UI,  STORE_EMPLOYER)
@observer

class EmployerBankAccountPage extends React.PureComponent<Props, {}>{

  componentDidMount(): void {
    const { employerId } = this.props.match.params;
    this.props[STORE_EMPLOYER].loadEmployer(employerId);
  }

  createNewBankAccount = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: CREATE_BANK_ACCOUNT_MODAL,
      title: 'Add bank account',
      props: {
        employerId: this.props.match.params.employerId
      }
    });
  };

  editBankAccount = (bankAccount: BankAccountsModel) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: EDIT_BANK_ACCOUNT_MODAL,
      title: 'Edit bank account',
      props: {
        employerId: this.props.match.params.employerId,
        bankAccount: bankAccount
      }
    });
  };

  deleteBankAccount = (bankAccount: BankAccountsModel) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: DELETE_BANK_ACCOUNT_MODAL,
      title: 'Delete bank account',
      props: {
        employerId: this.props.match.params.employerId,
        bankAccountId: bankAccount.id
      }
    });
  };

  selectActions(action: string, bankAccount: BankAccountsModel){
    switch(action) {
      case 'edit':  this.editBankAccount(bankAccount);
        break;
      case 'delete':  this.deleteBankAccount(bankAccount);
        break;
      default:
    }
  }

  onTabAction = (val: string) => {
    const { employerId } = this.props.match.params;
    history.push('/admin/employers/'+employerId+'/'+val);
  };

  renderBankAccauntsItems() {
    let bankAccounts = this.props[STORE_EMPLOYER].currentEmployer.bankAccounts || [];
    if(bankAccounts.length){
      return bankAccounts.map((bankAccount)=>
        <tr key={bankAccount.id}>
          <td>{bankAccount.accountNumber}</td>
          <td>{this.props[STORE_UI].sortingCodeFormat(bankAccount.sortingCode)}</td>
          <td>{bankAccount.companyName}</td>
          <td>
            <CustomSelect
              items={selectItems}
              onClickOption={(action)=>{this.selectActions(action, bankAccount)}}
            />
          </td>
        </tr>
      );
    } else return <NoData columnSpan={4} />
  }

  renderBankAccounts() {
    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
        <tr>
          <th className={styles.cuCol4}>Account number</th>
          <th className={styles.cuCol4}>Sort Code</th>
          <th>Company Name</th>
          <th className={styles.cuCol2}>Action</th>
        </tr>
        </thead>
        <tbody>
        {this.props[STORE_EMPLOYER].loadEmployerState ? <TableLoader columnSpan={4}/> : this.renderBankAccauntsItems()}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <>
        <CenterBanner
          mainTitle={this.props[STORE_EMPLOYER].currentEmployer.name}
          subTitle={this.props[STORE_EMPLOYER].currentEmployer.clientId}
          tabs={EMPLOYER_TABS}
          activeTab={'bank-accounts'}
          onClickTab={this.onTabAction}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={'Bank accounts'}
            actionButtonName={'Add new'}
            actionButtonEvent={this.createNewBankAccount}
          />
          {this.renderBankAccounts()}
        </div>
      </>
    );
  }
}
export default EmployerBankAccountPage;