import { observable, action, runInAction } from 'mobx';
import Dinero from 'dinero.js';
import moment from 'moment';

import { ERROR_MODAL, FORM_MODAL_WIDTH } from '../appConstants';

import { RootStore } from './RootStore';

interface ModalEventProps {
  onSubmit?: Function;
}

interface ModalExtraProps {
  [key: string]: any;
}

export interface ModalProps {
  componentKey: string;
  title?: string;
  bodyText?: string;
  width?: number;
  props?: ModalExtraProps;
  eventProps?: ModalEventProps;
  onClose?: () => void;
}

export class UIStore {
  rootStore: RootStore;
  @observable isModalShown = false;
  @observable modal: ModalProps | null = null;
  @observable loadModalData = false;
  @observable fetchError = '';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action handleFormKeyPress(e: KeyboardEvent, form: any) {
    const isDisabled = !form.dirty || !form.isValid;
    if (e.key === 'Enter' && !isDisabled) {
      form.submitForm();
    }
  }

  @action sortingCodeFormat(sortingCode: string) {
    const arr = sortingCode.match(/\w{2}/gm) || [];
    return arr.length ? arr.join('-') : '';
  }

  @action showErrorPopup(error: string) {
    this.openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: ERROR_MODAL,
      title: 'Error',
      props: {
        description: error,
      },
    });
  }

  @action getFormattedDateTime(newDate: string) {
    const utc = moment.utc(newDate, moment.ISO_8601).format('D MMM YYYY HH:mm');
    return newDate ? utc : '';
  }

  @action getFormattedDate(newDate: string) {
    const utc = moment.utc(newDate, moment.ISO_8601).format('D MMM YYYY');
    return newDate ? utc : '';
  }

  @action getFormattedDateFromTimestamp(newDate: number) {
    const utc = moment.utc(newDate * 1000).format('D MMM YYYY');
    return newDate ? utc : '';
  }

  @action getFormattedShortDate(newDate: string) {
    const utc = moment.utc(newDate, moment.ISO_8601).format('DD/MM/YYYY');
    return newDate ? utc : '';
  }

  @action getFormattedFileDate() {
    return moment().format('YYYYMMDDTHHmmss');
  }

  @action getFormattedMoney(amount: number, type?: string) {
    if (amount === 0 && type === 'newContribution') {
      return '£0';
    }

    let format = '';
    const str = amount.toString();
    if (str[str.length - 1] === '0' && str[str.length - 2] === '0') {
      format = '0,0';
    } else {
      format = '0,0.00';
    }
    if (Number.isInteger(amount)) {
      return '£' + Dinero({ amount: amount, currency: 'GBP' }).toFormat(format);
    } else {
      return '£0';
    }
  }

  @action setFetchError(error = '') {
    this.fetchError = error;
  }

  @action setLoadModalData(load = true) {
    this.loadModalData = load;
  }

  @action closeModal = () => {
    if (this.modal?.onClose) {
      this.modal.onClose();
    }
    runInAction(() => {
      this.isModalShown = false;
    });
  };

  @action openModal = (payload: ModalProps) => {
    runInAction(() => {
      this.isModalShown = true;
      this.modal = {
        ...payload,
      };
    });
  };
}

export default UIStore;
