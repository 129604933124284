import React from 'react';
import { inject, observer } from 'mobx-react';
import styles from './Modal.module.scss';
import ReactModal from 'react-modal';
import UIStore from 'stores/UIStore';

import {
  STORE_UI,
  DEFAULT_MODAL_WIDTH,
  CREATE_EMPLOYER_MODAL,
  EDIT_EMPLOYER_MODAL,
  CREATE_ADMIN_USER_MODAL,
  EDIT_ADMIN_USER_MODAL,
  CREATE_BANK_ACCOUNT_MODAL,
  EDIT_BANK_ACCOUNT_MODAL,
  DELETE_BANK_ACCOUNT_MODAL,
  CREATE_SUPER_ADMIN_MODAL,
  EDIT_SUPER_ADMIN_MODAL,
  DELETE_ACCEPTANCE_MODAL,
  CREATE_EMPLOYER_USER_MODAL,
  EDIT_EMPLOYER_USER_MODAL,
  SUCCESS_MODAL,
  CREATE_PAYROLL_GROUP_MODAL,
  EDIT_PAYROLL_GROUP_MODAL,
  CSV_UPLOADED_MODAL,
  CONFIGURE_PAY_PERIOD_MODAL,
  ERROR_MODAL,
  TEST_CSV_MAPPING_MODEL,
  PAYROLL_MODAL
} from 'appConstants';

import {
  CreateEmployerModal,
  EditEmployerModal,
  CreateAdminUserModal,
  EditAdminUserModal,
  CreateBankAccountModal,
  EditBankAccountModal,
  DeleteBankAccountModal,
  CreateSuperAdminModal,
  EditSuperAdminModal,
  DeleteAcceptanceModal,
  CreateEmployerUserModal,
  EditEmployerUserModal,
  CreatePayrollGroupModal,
  EditPayrollGroupModal,
  CsvUploadedModal,
  ConfigurePayPeriodModal,
  SuccessModal,
  ErrorModal,
  TestCsvMappingModal,
  PayrollModal,
} from 'components';


export interface ModalProps {
  [STORE_UI]: UIStore;
}

interface ComponentsMap {
  [key: string]: typeof React.Component
}

const componentsMap: ComponentsMap = {
  [CREATE_EMPLOYER_MODAL]: CreateEmployerModal,
  [EDIT_EMPLOYER_MODAL]: EditEmployerModal,
  [CREATE_ADMIN_USER_MODAL]: CreateAdminUserModal,
  [EDIT_ADMIN_USER_MODAL]: EditAdminUserModal,
  [CREATE_BANK_ACCOUNT_MODAL]: CreateBankAccountModal,
  [EDIT_BANK_ACCOUNT_MODAL]: EditBankAccountModal,
  [DELETE_BANK_ACCOUNT_MODAL]: DeleteBankAccountModal,
  [CREATE_SUPER_ADMIN_MODAL]: CreateSuperAdminModal,
  [EDIT_SUPER_ADMIN_MODAL]: EditSuperAdminModal,
  [DELETE_ACCEPTANCE_MODAL]: DeleteAcceptanceModal,
  [CREATE_EMPLOYER_USER_MODAL]: CreateEmployerUserModal,
  [EDIT_EMPLOYER_USER_MODAL]: EditEmployerUserModal,
  [CREATE_PAYROLL_GROUP_MODAL]: CreatePayrollGroupModal,
  [EDIT_PAYROLL_GROUP_MODAL]: EditPayrollGroupModal,
  [CSV_UPLOADED_MODAL]: CsvUploadedModal,
  [CONFIGURE_PAY_PERIOD_MODAL]: ConfigurePayPeriodModal,
  [SUCCESS_MODAL]: SuccessModal,
  [ERROR_MODAL]: ErrorModal,
  [TEST_CSV_MAPPING_MODEL]: TestCsvMappingModal,
  [PAYROLL_MODAL]: PayrollModal,
};

@inject(STORE_UI)
@observer
export default class Modal extends React.Component <ModalProps, {}> {

  onSubmit = (...args: [any]) => {
    const {modal} = this.props[STORE_UI];
    if (modal && modal.eventProps && modal.eventProps.onSubmit) {
      const {onSubmit} = modal.eventProps;
      onSubmit && onSubmit(...args);
    }
  };

  hideModal(closeModal: () => void) {
    document.body.style.overflow = 'auto';
    closeModal();
  }

  showModal(isModalShown: boolean) {
    if (isModalShown) {
      document.body.style.overflow = 'hidden';
    }
    return isModalShown;
  }

  render() {
    const {modal, isModalShown, closeModal} = this.props[STORE_UI];
    if (!modal || !modal.componentKey) {
      return null
    }
    const {
      title,
      bodyText,
      componentKey,
      width = DEFAULT_MODAL_WIDTH,
      props
    } = modal;
    const CurrentComponent = componentsMap[componentKey];

    return (
      <ReactModal
        {...props}
        className={styles.modal}
        isOpen={this.showModal(isModalShown)}
        onRequestClose={() => {
          this.hideModal(closeModal)
        }}
        shouldFocusAfterRender={false}
        overlayClassName={styles.modalOverlay}
        ariaHideApp={false}
        style={{content: {width}}}
      >
        <button
          className={styles.closeButton}
          onClick={() => {
            this.hideModal(closeModal)
          }}
        >
        </button>
        {title && <h2>{title}</h2>}
        <CurrentComponent onCancel={() => {
          this.hideModal(closeModal)
        }} {...props} onSubmit={this.onSubmit} bodyText={bodyText}/>
      </ReactModal>
  )}
}
