import {InputHTMLAttributes} from "react";
import classNames from "classnames";
import styles from "./CustomCheckbox.module.scss";
import React from "react";

const CustomCheckbox = ({className, checked, title, ...props}: InputHTMLAttributes<HTMLInputElement>) => (
  <div className={styles.checkboxHolder}>
    <label className={classNames(styles.checkbox, className, {[styles.checked]: checked})}>
      <input
        {...props}
        checked={checked}
        hidden
        type='checkbox'
      />
    </label>
    <span className={styles.title}>{title}</span>
  </div>
);

export default CustomCheckbox;
