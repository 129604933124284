import React, {InputHTMLAttributes} from 'react';
import classNames from 'classnames';
import styles from './CustomInput.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputSize?: 'm' | 'l';
}

const CustomInput = ({
 className, inputSize = 'm',
 ...props
}: InputProps) =>
  <input
  {...props}
    className={classNames(
        {
          [styles.medium]: inputSize === "m"
        },
        styles.input, className,
        styles.name
      )
    }
  />;

export default CustomInput;

