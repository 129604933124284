import * as React from 'react';
import {TableLoader, NoData, Loader} from 'components';
import {STORE_UI, STORE_PAYROLL, CsvUploadTableShowOptions} from 'appConstants';
import {UIStore, PayrollStore} from 'stores';
import {inject, observer} from 'mobx-react';
import classNames from 'classnames';
import {CsvUploadResult, PaymentCsvItem, HrSftpCsvItem} from './CsvUploadModels';
import styles from './CsvUploadedModal.module.scss';
import { ChangeEvent } from 'react';

export interface Props {
  [STORE_UI]: UIStore;
  [STORE_PAYROLL]: PayrollStore;
  submitData: CsvUploadResult;
  showOption: CsvUploadTableShowOptions;
  csvErrorCount: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => any,
}

@inject(STORE_UI, STORE_PAYROLL)
@observer

export default class CsvUploadedModal extends React.Component <Props, {}> {
  renderErrors(errors: string[]) {
    return errors.map((error, index) => <div key={index} className={styles.error}>{error}</div>);
  }

  renderCsvUploadTable(option: CsvUploadTableShowOptions) {
    const options = {
      [CsvUploadTableShowOptions.PayrollGroupsPayment]: this.renderPayrollGroupScvUploaded,
      [CsvUploadTableShowOptions.HrSftpConfig]: this.renderHrSftpConfigTable
    }

    return options[option].call(this)
  }

  renderPayrollGroupScvUploadedList() {
    if (!this.props.submitData.resultItems.length) {
      return <NoData columnSpan={7}/>
    }

    return this.props.submitData.resultItems.map((item, index) => {
      let csvItem = item.csvItem as PaymentCsvItem;

      return (<tr key={index}>
        <td>{index + 1}</td>
        <td>
          <strong>{csvItem.name}</strong>
        </td>
        <td>{csvItem.nino}</td>
        <td>{csvItem.regularAmount ? "£" + csvItem.regularAmount : ""}</td>
        <td className={styles.textLeft}>{csvItem.singleAmount ? "£" + csvItem.singleAmount : ""}</td>
        {!this.props.submitData.success && <td className={styles.errorCol}>
          {this.renderErrors(item.errors)}
        </td>}
      </tr>)
    });

  }

  renderHrSftpConfigTableList() {
    if (!this.props.submitData.resultItems.length) {
      return <NoData columnSpan={7}/>
    }

    return this.props.submitData.resultItems.map((item, index) => {
      const csvItem = item.csvItem as HrSftpCsvItem;
      const {name, nino, status, payrollGroup, leaverReason} = csvItem;
      return (<tr key={index}>
        <td>{index + 1}</td>
        <td>
          <strong>{name}</strong>
        </td>
        <td>{nino}</td>
        <td>{status}</td>
        <td>{payrollGroup}</td>
        <td>{leaverReason}</td>
        {!this.props.submitData.success && <td className={styles.errorCol}>
          {this.renderErrors(item.errors)}
        </td>}
      </tr>)
    });
  }

  renderPayrollGroupScvUploaded() {
    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
        <tr>
          <th className={styles.thIndex}/>
          <th className={styles.thName}>Member name</th>
          <th>NI Number</th>
          <th>Regular amount</th>
          <th className={styles.textLeft}>Lump sum amount</th>
          {!this.props.submitData.success && <th className={styles.thError}>Error description</th>}
        </tr>
        </thead>
        <tbody>
        {this.props[STORE_PAYROLL].loadPaymentFileId ? <TableLoader columnSpan={7}/> : (
          this.renderPayrollGroupScvUploadedList()
        )}
        </tbody>
      </table>
    );
  }

  renderHrSftpConfigTable() {
    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
        <tr>
          <th className={styles.thIndex}/>
          <th className={styles.thName}>Member name</th>
          <th>NI Number</th>
          <th>Status</th>
          <th>Payroll group name</th>
          <th>Leaver reason</th>
          {!this.props.submitData.success && <th className={styles.thError}>Error description</th>}
        </tr>
        </thead>
        <tbody>
        {this.props[STORE_PAYROLL].loadPaymentFileId ? <TableLoader columnSpan={7}/> : (
          this.renderHrSftpConfigTableList()
        )}
        </tbody>
      </table>
    );
  }

  plural(word: string, count: number) {
    return `${word}${count > 1 ? 's' : ''}`
  }

  renderErrorCount() {
    return (
      <p>
        <span className={styles.error}>
          {this.props.csvErrorCount} {this.plural('error', this.props.csvErrorCount)} found.
        </span> Please fix and upload again.
      </p>
    )
  }

  renderHeader() {
    return (
      <div className={styles.modalBlock}>
      {!this.props.submitData.success 
        ? <div>
          {!this.props[STORE_PAYROLL].loadPaymentFileId ? this.renderErrorCount() : ""}
        </div>
        :'Please check the data from your file is mapped to the correct columns.'}
      </div>
    )
  }

  render() {
    return (
      <>
        <div className={styles.modalBlock}>
          {this.renderHeader()}
        </div>
        <div className={styles.tableBlock}>
          {this.renderCsvUploadTable(this.props.showOption)}
        </div>
        <div className={styles.cButtonRight}>
          {this.props[STORE_PAYROLL].loadPaymentFileId ? <Loader className={styles.loader}/> :
            <label className={styles.cuButton} htmlFor={"uploadFile"}>Upload again</label>
          }
          <input
            type="file"
            name="uploadFile"
            className={styles.uploadFile}
            id={"uploadFile"}
            accept=".csv"
            onChange={this.props.onChange}
          />
        </div>
      </>
    );
  }
}
