import * as React from 'react';
import {CustomField, CustomButton, CustomMaskField, Loader} from 'components/index';
import {Form, Formik, FormikActions} from "formik";
import { STORE_EMPLOYER, STORE_UI, VALIDATION_RULES} from "appConstants";
import {inject, observer} from "mobx-react";
import {EmployerStore, UIStore} from "stores";
import classNames from "classnames";
import CreateBankAccountInput from "../../../graphql/mutations/inputs/CreateBankAccountInput";
import * as Yup from "yup";
import styles from './CreateBankAccountModal.module.scss';

interface FormValues {
  accountNumber: string;
  sortCode: string;
  companyName: string;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  accountNumber: VALIDATION_RULES.accountNumber,
  sortCode: VALIDATION_RULES.sortCode,
  companyName: VALIDATION_RULES.name
});

export interface Props {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
  onCancel: () => void;
  employerId: string;
}

@inject(STORE_UI, STORE_EMPLOYER)
@observer
export default class CreateBankAccountModal extends React.Component <Props, {}> {

  componentDidMount(): void {
    this.props[STORE_EMPLOYER].clearErrorMsg();
  }

  onSubmit = async ({ accountNumber, sortCode, companyName }: FormValues, actions: FormikActions<FormValues>) => {
    this.props[STORE_UI].setLoadModalData();
    actions.setStatus();

    const bankAccountData: CreateBankAccountInput = {
      accountNumber: accountNumber,
      sortingCode: sortCode.replace(/-/g, ''),
      companyName: companyName
    };

    await this.props[STORE_EMPLOYER].createBankAccount(bankAccountData, this.props.employerId);
    this.props[STORE_UI].setLoadModalData(false);

    if(this.props[STORE_EMPLOYER].fetchError){
      actions.setStatus({ fetchError: this.props[STORE_EMPLOYER].fetchError })
    } else {
      this.props.onCancel();
    }
  };

  render() {
    const initialValues = {
      accountNumber: '',
      sortCode: '',
      companyName: ''
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.onSubmit}
      >{ form => {
        const isDisabled = !form.dirty || !form.isValid;
        return <Form>
          <div className={classNames(styles.cPopupContent, styles.minHeight)}>
            <div>
              <div className={styles.cControlsLeft}>
                <CustomMaskField
                  name="accountNumber"
                  title="Account number"
                  placeholder="Enter number..."
                  form={form}
                  mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,]}
                  autoFocus
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
                <CustomMaskField
                  name="sortCode"
                  title="Sort code"
                  placeholder="Enter sort code..."
                  form={form}
                  mask={[/[0-9]/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/,]}
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
              </div>

              <div className={styles.cControlsLeft}>
                <CustomField
                  name="companyName"
                  title="Company name"
                  placeholder="Enter company name..."
                  form={form}
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
                <div className={styles.leftPosition} />
              </div>
              {form.status && <p className={styles.error}>{form.status.fetchError}</p>}
            </div>
            <div className={styles.cButtonRight}>
              {this.props[STORE_UI].loadModalData?<Loader className={styles.loader}/>:
                <CustomButton size={"xxs"} type="submit" buttonType="primary" disabled={isDisabled}>Create</CustomButton>
              }
            </div>
          </div>
        </Form>}}
      </Formik>
    );
  }
}
