import * as React from 'react';
import {STORE_UI} from 'appConstants';
import {UIStore} from 'stores';
import {inject, observer} from 'mobx-react';
import styles from './TestCsvMappingModal.module.scss';
import {ChangeEvent} from 'react';
import classNames from "classnames";

export interface Props {
  [STORE_UI]: UIStore;
  onChange: (e: ChangeEvent<HTMLInputElement>) => any,
}

@inject(STORE_UI)
@observer

export default class TestCsvMappingModal extends React.Component <Props, {}> {
  render() {
    return (
      <div className={styles.cTestMapping}>
        <div className={styles.testMappingInfo}>
          <p>
            To test CSV mapping, please upload a CSV file with data in the format generated by your payroll software.
            <br/>
            <br/>
            We will display a preview of how that mapping is interpreted once the file has been uploaded.
          </p>
          <p className={classNames(styles.error, styles.errorOffset)}>{this.props[STORE_UI].fetchError}</p>
        </div>
        <div className={styles.cButtonRight}>
          <label className={styles.cuButton} htmlFor={"uploadFile"}>Test mapping</label>
          <input
            type="file"
            name="uploadFile"
            className={styles.uploadFile}
            id={"uploadFile"}
            accept=".csv"
            onChange={(e) => {
              this.props.onChange(e)
            }}
          />
        </div>
      </div>
    );
  }
}
