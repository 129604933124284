import * as React from 'react';
import {CustomButton, Loader} from 'components/index';
import {inject, observer} from "mobx-react";
import {STORE_PAYROLL, STORE_UI} from "appConstants";
import {PayrollStore, UIStore} from "stores";
import styles from './PayrollModal.module.scss';

export interface Props {
  [STORE_UI]: UIStore;
  [STORE_PAYROLL]: PayrollStore;
  payrollGroupId: string;
  pausedChangedContributions: string[];
  onCancel: () => void;
  onSubmit: () => void;
  description: string;
}

@inject(STORE_UI, STORE_PAYROLL)
@observer

export default class PayrollModal extends React.Component <Props, {}> {

  selectedTransactions: any;

  componentDidMount(): void {

    this.selectedTransactions = { payrollGroupId: parseInt(this.props.payrollGroupId),
      employeeContributionIds: Array.from(new Set(JSON.parse(JSON.stringify(this.props.pausedChangedContributions.slice())).map(Number)))
    }

    this.props[STORE_UI].setFetchError();
  }

  componentWillUnmount(): void {
    this.props.onCancel();
  }

  selectedContributions = async () => {
    
    await this.props[STORE_PAYROLL].deletePauseContributions(this.selectedTransactions);
    this.props.onCancel();
    window.location.reload();
  }

  render() {
    return (
    <>
        <div>All selected changed, paused or registered payment requests will be marked as actioned in payroll and removed from this screen. All other payment requests will not be removed.</div>
        <div>
        {this.props[STORE_UI].loadModalData ? <Loader/> :
        <CustomButton size={"xxs"} autoFocus={true} buttonType="primary"
        className= {styles.deleteBtn}
        onClick={this.selectedContributions}>Yes, delete</CustomButton>
        }
    </div>
   </>
    );
  }
}
