import React from 'react';
import classNames from 'classnames';
import styles from './PrivacyPolicyPage.module.scss';

class PrivacyPolicyPage extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className={styles.cTerms}>

        <div className={styles.mainHeading}>
          <h1 className={styles.underline}>Privacy policy</h1>
        </div>

        <div>
          <div className="wysiwyg">
            <p>
              Last updated: October 2019
            </p>

            <p>
              <strong>
                Protecting your personal information is extremely important to us at Legal & General. It’s especially
                important for a large financial company like ours, as our customers trustIt’s especially important for a
                large financial company like ours, as our customers trust us to look after a huge amount of sensitive
                information on everything from their us to look after a huge amount of sensitive information on
                everything from their business affairs to their medical history.
              </strong>
            </p>

            <p>
              The way we collect, use, store and share your information is equally important. Our customers expect us to
              manage their information privately and securely. If we don’t, they’ll lose their trust in us.
            </p>

            <p>
              This policy tells you how we collect and process your personal information. Please take a few minutes to
              read it, and show it to anyone else connected to your product or whose data you have shared with us. This
              policy may be subject to future change.
            </p>

            <h2>What does this policy cover?</h2>
            <p>
              This privacy policy relates to retail customers who request financial advice, obtain quotes,purchase our
              products or enter into agreements with us, and individuals that we cover under employer sponsored schemes.
              We have a separate privacy policy for professional business clients, which can be found on
              our <a
              className={styles.cuLink}
              href="https://www.legalandgeneral.com/institutional/privacy-policy/" rel="noopener noreferrer"
              target="_blank"
            >
              website
            </a>.
            </p>

            <h2>What is personal information?</h2>
            <p>
              When we talk about personal information we mean information about an individual that can identify them,
              like their name, address, e-mail address, telephone number and financial details. It can relate to
              customers (including prospective customers), their appointed representatives (e.g.powers of attorney),
              employees, shareholders, business contacts and suppliers. Any reference to“information” or “data” in this
              policy is a reference to personal information about a living individual.
            </p>

            <h2>What information do we hold?</h2>
            <p>
              We may collect and process the following personal information about you:
            </p>
            <table className={classNames(styles.cuTable, styles.noHover, styles.tTerms)}>
              <thead>
              <tr>
                <th>Type of data</th>
                <th>Description</th>
                <th>Examples of how we use it</th>
              </tr>
              </thead>
              <tbody>

              <tr>
                <td>Contact</td>
                <td>
                  <ul>
                    <li>Who you are</li>
                    <li>Where you live (including previous addresses)</li>
                    <li>How to contact you</li>
                    <li>Third party contacts (e.g. family members or friends)</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Servicing your product</li>
                    <li>Pension tracing</li>
                    <li>Marketing</li>
                    <li>Analysis & profiling</li>
                    <li>Enhancing our product and service offering</li>
                    <li>To provide financial advice</li>
                    <li>Product underwriting and pricing</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Personal Details</td>
                <td>
                  <ul>
                    <li>Age</li>
                    <li>Gender</li>
                    <li>Family details</li>
                    <li>Third party contacts (e.g. family members or friends)</li>
                    <li>Visual images & personal appearance</li>
                    <li>Financial Details</li>
                    <li>Lifestyle and social circumstances</li>
                    <li>Health details</li>
                    <li>Employment Details</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Marketing</li>
                    <li>Analysis & profiling</li>
                    <li>To provide financial advice</li>
                    <li>Product underwriting and pricing</li>
                    <li>Affordability checks(mortgages only)</li>
                    <li>Pension tracing</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Transactional</td>
                <td>
                  <ul>
                    <li>Bank and/or card details</li>
                    <li>How you use your product</li>
                    <li>Changes you make to your product or account</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Servicing your product</li>
                    <li>To provide financial advice</li>
                    <li>Making sure our product sand services are fit for purpose</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Contractual</td>
                <td>
                  <ul>
                    <li>Your claims history</li>
                    <li>Your creditworthiness</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>To provide financial advice</li>
                    <li>Product underwriting and pricing</li>
                    <li>Making sure the product is right for you</li>
                    <li>Affordability checks(mortgages only)</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Consent & preferences</td>
                <td>
                  <ul>
                    <li>Ways you want us to market to you</li>
                    <li>To obtain information from third parties on your behalf</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Marketing</li>
                    <li>Pension tracing</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Technical</td>
                <td>
                  <ul>
                    <li>Details on the devices and technology you use</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Making sure our product sand services are fit for purpose</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Open data & public records</td>
                <td>
                  <ul>
                    <li>Electoral register</li>
                    <li>Land register</li>
                    <li>Other information about you that is openly available on the internet</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>To provide financial advice</li>
                    <li>Product & Service administration</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Documentary data & national identifiers</td>
                <td>
                  <ul>
                    <li>Details about you that are stored in documents like:</li>
                    <li>Your passport</li>
                    <li>Drivers licence</li>
                    <li>Birth certificate</li>
                    <li>National Insurance number</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Identification and verification</li>
                    <li>Prevent financial crime</li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>

            <h2>Where do we get our information from?</h2>
            <ul>
              <li><strong>Directly from you - information you provide when you fill in forms or contact us by phone, e-
                mail etc.</strong> We may record phone calls for our mutual protection and to improve our customer
                service standards.
              </li>
              <li><strong>Information we collect about you or receive from other sources.</strong> This could be
                information you provide to us electronically (through our website or an online portal, for example),
                information we get from your online browsing activity, information from a third party (e.g. pension
                providers) or from publicly available sources such as social media platforms or the electoral register
                (e.g. to detect fraudulent claims). We may also receive information if you have been dealing with a
                financial adviser or broker, or have been introduced to us by another company (e.g. a bank, insurer,
                building society, your employer or their financial adviser). Please refer to our <a
                  className={styles.cuLink} href="https://www.legalandgeneral.com/privacy-policy/cookies.html"
                  target="_blank" rel="noopener noreferrer">cookie policy</a> for more information on how we use
                cookies.
              </li>
            </ul>

            <h2>How do we use your information?</h2>
            <p>We use personal information that we hold about you:</p>
            <ul>
              <li>To carry out our responsibilities resulting from any agreements you’ve entered into with us(e.g. to
                provide financial advice, underwrite and administer your product, including process claims and mortgage
                affordability checks) and to provide you with the information, product sand services that you’ve asked
                from us (e.g. quotes and pension tracing).
              </li>
              <li>To provide you with marketing information about services and products we offer across theLegal &
                General group which may be of interest to you. If you have opted in to receive marketing from us, based
                on your marketing preferences we may deliver this information by post, telephone, e-mail, SMS or
                personalised online marketing via our own systems such as MyAccount, social media platforms and/or other
                third party websites e.g. YouTube. Please note that if you choose not to receive online marketing, you
                will not see personalised messages using your personal data, however you may still see generic online
                advertising about our products and services. We will not sell your data to third parties for them to
                market to you. We may also send marketing to you using our “legitimate interests”, please see below for
                further information.
              </li>
              <li>To tell you about changes to our services and products.</li>
              <li>To comply with any applicable legal or regulatory requirements (including “know your customer” checks,
                or to comply with any applicable regulatory reporting or disclosure requirements).
              </li>
              <li>For carrying out market research, statistical analysis and customer profiling to help us to improve
                our processes, products and services and generate new business (e.g. to under stand digital behaviours,
                identify financial attitudes and develop more engaging communications).
              </li>
              <li>To define our actuarial, pricing and underwriting strategies.</li>
              <li>To run our business in an efficient and proper way. This includes testing our systems, managing our
                financial position, business capability, planning, communications, corporate governance, and audit.
              </li>
              <li>For any other purpose that we’ve agreed with you from time to time.</li>
            </ul>

            <p>
              When you apply for a product or to receive a service from us, the application form you fill out or the
              resulting contract may contain additional conditions relating to the way we use and process your personal
              information. These will apply in addition to the uses described in this document.
            </p>

            <p>
              In some cases, we may use software or systems to make automated decisions (including profiling) based on
              the personal information we have, or collect from others. These may include:
            </p>

            <ul>
              <li>
                <strong>The prevention and detection of fraud and financial crime</strong>
                <br/>
                To perform transaction monitoring, identity verification, money laundering and sanctions checks, and to
                identify politically exposed individuals. We are required by law to perform these activities which may
                be achieved using solely automated means to make decisions about you.
                We may use these activities to decline the services you have requested or to stop providing existing
                services to you.
              </li>

              <li>
                <strong>Providing quotes, calculating premiums and underwriting decisions</strong>
                <br/>
                We may assess a number of factors including information about you and your health, lifestyle information
                such as your postcode, occupation and hazardous pursuits that you perform.These factors will be assessed
                against our pricing and underwriting criteria which may include statistics regarding life expectancy,
                illness, injury and demographic risks. For general insurance the factors may include your claims
                history, where you live, and the value of items you wish to insure. We may use these activities to
                determine the price of your product and whether we should undertake the risk of insuring you - including
                how much insurance should be granted to you, how much you should pay for it and whether or not to insure
                you in the first place.
                <br/>
                For mortgage purposes the factors may include your creditworthiness, assessment of mortgage
                affordability, property value and checks against records held by credit referencing and fraud prevention
                agencies.
              </li>

              <li>
                <strong>The provision of financial advice and recommendations</strong>
                <br/>
                We may perform computer- based assessments with your personal and financial information in order to
                produce your financial advice recommendations. These activities are not solely based on automated
                processing and our qualified financial advisers will always validate the outcome to ensure that the
                decisions are suitable based on the information that you have provided.
              </li>

              <li>
                <strong>The identification of customer vulnerabilities</strong>
                <br/>
                We may use speech analytics on recorded phone calls to help us identify vulnerable customers. These
                profiling activities are not solely based on automated processing. The results are manually reviewed to
                decide if we need to take further action to ensure the fair treatment of customers with identified
                vulnerabilities.
              </li>

              <li>
                <strong>Servicing activities such as (i) Personalising the content and design of communications and
                  online services (such as My Account) and (ii) Determining communications and online services (such as
                  My Account) and (ii) Determining when to provide tailored communications about your Legal & General
                  product (e.g.when to provide tailored communications about your Legal & General product (e.g.as a
                  result of changes in your personal circumstances or lifestyle) and the as a result of changes in your
                  personal circumstances or lifestyle) and the appropriate channel(s) to use appropriate channel(s) to
                  use</strong>
                <br/>
                These may be achieved using profiling in order to predict certain characteristics about you (e.g.your
                economic situation, interests, personal preferences or transactional behaviour). The activities will not
                have a detrimental effect on you.
              </li>
            </ul>

            <h2>Using your information in accordance with data protection laws</h2>
            <p>
              Data protection laws require us to meet certain conditions before we’re allowed to use your personal
              information in the way we describe in this privacy policy. We take these responsibilities extremely
              seriously. To use your personal information, we’ll rely on the following conditions, depending on the
              activities we’re carrying out:
            </p>
            <ul>
              <li>
                <strong>Providing our contracts & services to you: </strong>
                We’ll process your personal information to carry out our responsibilities resulting from any agreements
                you’ve entered into with us and to provide you with the information, products and services you’ve asked
                from us, which may include online services.
              </li>

              <li>
                <strong>Complying with applicable laws: </strong>
                We may process your personal information to comply with any legal obligation we’re subject to.
              </li>

              <li>
                <strong>Legitimate interests: </strong>
                To use your personal data for any other purpose described in this privacy policy, we’ll rely on a
                condition known as "legitimate interests". It’s in our legitimate interests to collect your personal
                data as it provides us with the information that we need to provide our services to you more
                effectively. We may use your information to:
                <ul>
                  <li>Carry out market research and product development, which can include creating customer
                    demographics and/or profiling.
                  </li>
                  <li>Continue to send marketing information, via post only, to customers who purchased a product before
                    25th May 2018 and did not opt-out, until such time as they have reviewed their marketing preferences
                    (which can be done at any time).
                  </li>
                  <li>Send marketing information, via post only, to customers who have a relevant and appropriate
                    relationship with Legal & General.
                  </li>
                  <li>Develop and test the effectiveness of marketing activities.</li>
                  <li>Develop, test and manage our brands, products and services.</li>
                  <li>Study and also manage how our customers use products and services from us and our business
                    partners, including customer surveys.
                  </li>
                  <li>Manage risk for us and our customers.</li>
                </ul>
              </li>
            </ul>

            <p>
              This requires us to carry out an assessment of our interests in using your personal data against the
              interests you have as a citizen and the rights you have under data protection laws.
            </p>

            <p>
              The outcome of this assessment will determine whether we can use your personal data in the ways described
              in this privacy policy (except in relation to marketing, where we’ll always rely ony our consent). We’ll
              always act reasonably and give full and proper consideration to your interests in carrying out this
              assessment.
            </p>

            <ul>
              <li>
                <strong>Consent:</strong> We may provide you with marketing information about our services or products
                where you’ve provided your consent for us to do so.
                <p>You may opt out of marketing at any time by e-mailing or telephoning your customer servicing team.
                  Alternatively, you can also use the <a
                    href="https://www.legalandgeneral.com/existing-customers/contact-us/" className={styles.cuLink}
                    target="_blank" rel="noopener noreferrer">Contact Us</a> section of our website. You can also manage
                  your marketing
                  preferences on our customer self-service systems, My Account.</p>
                <p>Where you have consented, we will contact third parties (e.g. pension providers) to obtain
                  information on your behalf.</p>
              </li>

              <li>
                <strong>Special category (sensitive) data: </strong> Where you have consented, we will process any
                medical& health, racial & ethnic origin, genetic & biometric or sex life & sexual orientation
                information you have provided, and any other sensitive information obtained from a third party (e.g.
                your GP or other medical professional), solely for the purposes of allowing us to underwrite and
                administer your product, provide other services (e.g. financial advice) and deal with claims.
                <p>Alternatively, we may process special category data for reasons of substantial public interest in
                  accordance with applicable law. We will only process data that is needed for specific purposes.</p>
              </li>

              <li>
                <strong>Criminal Conviction Data: </strong> Where you have consented, we will process this type of
                information solely for the purposes of allowing us to underwrite and administer your product and deal
                with claims.
              </li>
            </ul>

            <p>
              Please be aware that the personal information you provide to us, and which we collect about you, is
              required for us to be able to provide our services to you and without it we may not be able to do so.
            </p>

            <h2>How long do we keep your information for?</h2>
            <p>
              We’ll keep your personal information in accordance with our internal retention policies. We’ll determine
              the length of time we keep it for based on the minimum retention periods required bylaw or regulation.
              We’ll only keep your personal information after this period if there’s a legitimate and provable business
              reason to do so.
            </p>

            <p>
              For pension products, we may retain your personal information indefinitely using the legitimate interests
              condition in order to support future enquires from you, your family or financial adviser and our
              regulators.
            </p>

            <p>
              For certain research and statistical activities, we may indefinitely retain minimised personal information
              about you, including medical information, to solely to define our actuarial, underwriting and pricing risk
              strategies. These activities will not be used to make a decision, or take measures, against you.
            </p>

            <h2>Who do we share your personal information with?</h2>
            <p>We’ll only disclose your information to: </p>
            <ul>
              <li>Other companies within the Legal & General Group, third-party suppliers, contractors and service
                providers for the purposes listed under <strong>“How do we use your information”</strong> above.
              </li>
              <li>Selected third parties, so that they can contact you with details of the services that they provide,
                where you have expressly opted-in or consented to the disclosure of your personal data for these
                purposes.
              </li>
              <li>Our regulators, government agencies (e.g. HMRC), law enforcement agencies, credit reference agencies,
                fraud prevention agencies, reassurers, as well as our professional advisers etc. You can find an up to
                date list of reassurers on <a href="https://www.legalandgeneral.com/privacy-policy/consent-controllers/"
                                              target="_blank" className={styles.cuLink} rel="noopener noreferrer">our
                  website</a>.
              </li>
            </ul>

            <p>
              Additionally, we may disclose your personal information to third parties:
            </p>
            <ul>
              <li>In the event that we sell or buy any business or assets, in which case we’ll disclose your personal
                data to the prospective seller or buyer of such business or assets.
              </li>
              <li>If we, or substantially all of our assets, are acquired by a third party, in which case personal data
                held by us about our customers will be one of the transferred assets.
              </li>
              <li>If you have been dealing with a financial adviser, we’ll provide information about your product and,
                where appropriate, with other information about your dealings with us, to enable the adviser to give you
                informed advice.
              </li>
              <li>For employer sponsored schemes, we may share some details of your plan with your employer and any
                professional advisor(s) they appoint on their or your behalf to service and provide accurate financial
                advice about the scheme.
              </li>
              <li>In order to enforce or apply the terms of any contract with you.</li>
              <li>If we’re under a duty to disclose or share your personal data in order to comply with any legal
                obligation or regulatory requirements, or otherwise for the prevention or detection of fraud or crime.
              </li>
              <li>To protect you and Legal & General from financial crime, Legal & General may be required to verify the
                identity of new and sometimes existing customers. This may be achieved by using credit reference
                agencies to search sources of information relating to you (an identity search).This will not affect your
                credit rating. If this fails, Legal & General may need to approach you to obtain documentary evidence of
                identity.
              </li>
              <li>If you make a claim, we’ll share your information (where necessary) with other insurance companies to
                prevent fraudulent claims.
              </li>
              <li>Legal & General Surveying Services may use a third party surveyor to carry out the survey or valuation
                of the property.
              </li>
            </ul>

            <p>
              Additionally, your information, including special category and criminal conviction data, may bed is closed
              to our reassurer and to any other insurance company to whom you apply for products or services.
            </p>

            <p>
              If you’ve been introduced to us by another company (e.g. bank, insurer, building society, your employer or
              their financial adviser), we may share your information with them to enable them to:
            </p>

            <ul>
              <li>Administer and service your product.</li>
              <li>Carry out market research, statistical analysis and customer profiling.</li>
              <li>Where you have consented, send you marketing information by post, telephone, e-mail and SMS about
                their products and those of carefully selected third parties.
              </li>
              <li>Assist you with your application process (aggregator and price comparison websites only).</li>
            </ul>

            <p>
              <strong>Credit Reference Agencies (mortgage customers)</strong>
            </p>

            <p>
              In order to process your mortgage application, we will supply your personal information to credit
              reference agencies (CRAs) and they will give us information about you, such as about your financial
              history. We do this to assess creditworthiness and product suitability, check your identity, manage your
              account, trace and recover debts and prevent criminal activity.
            </p>

            <p>
              A hard footprint will be left on your credit file, which may affect your credit score when an application
              has been submitted. This may or may not affect your ability to obtain credit from other providers.
            </p>

            <p>
              Legal & General will also continue to exchange information about you with CRAs on an on going basis,
              including about your settled accounts and any debts not fully repaid on time. CRAs will share your
              information with other organisations. Your data will also be linked to the data of your spouse, any joint
              applicants or other financial associates.
            </p>

            <p>
              The identities of the CRAs, and the ways in which they use and share personal information, are explained
              in more detail at:
            </p>

            <ul>
              <li>Call Credit - <a href="https://www.transunion.co.uk/crain" target="_blank"
                                   className={styles.cuLink} rel="noopener noreferrer">www.callcredit.co.uk/crain</a>
              </li>
              <li>Equifax - <a href="https://www.equifax.co.uk/crain" target="_blank"
                               className={styles.cuLink} rel="noopener noreferrer">www.equifax.co.uk/crain</a></li>
              <li>Experian - <a href="https://www.experian.co.uk/crain/" target="_blank"
                                className={styles.cuLink} rel="noopener noreferrer">www.experian.co.uk/crain</a></li>
            </ul>

            <h2>Fraud prevention</h2>

            <p>
              The personal information Legal & General collects from you may be shared with fraud prevention agencies
              who will use it to prevent fraud and money laundering and to verify your identity.
            </p>

            <p>
              If false or inaccurate information is provided and fraud is identified, details will be passed to fraud
              prevention agencies. Law enforcement agencies may access and use this information. We may also share
              information about you with other organisations and public bodies, including the police and we may check
              and/or file your details with fraud prevention agencies and databases.
            </p>

            <p>
              If fraud is detected, you could be refused certain services, finance or employment. Further details of how
              your information will be used by us and these fraud prevention agencies can be found by accessing this
              link, <a
              href="https://www.cifas.org.uk/fpn" target="_blank" rel="noopener noreferrer"
              className={styles.cuLink}>www.cifas.org.uk/fpn</a>
            </p>

            <p>
              Legal & General and other organisations may also access and use this information to prevent fraud and
              money laundering, for example, when:
            </p>

            <ul>
              <li>Checking details on applications for credit and credit related or other facilities.</li>
              <li>Managing credit and credit related accounts or facilities.</li>
              <li>Recovering debt.</li>
              <li>Checking details on proposals and claims for all types of insurance.</li>
              <li>Checking details of job applicants and employees.</li>
              <li>Checking sources of income and tax details.</li>
            </ul>

            <p>
              Legal & General and other organisations may access and use from other countries the information recorded
              by fraud prevention agencies. Please contact our Group Financial Crime department if you wish to receive
              the relevant details of the fraud prevention agencies:
            </p>

            <p>
              Address: Group Financial Crime, 7th Floor, Brunel House, 2 Fitzalan Road, Cardiff CF24 0EB
            </p>

            <p>
              Legal & General may also check the details of other parties related to your contract, including
              verification of identity. This includes beneficiaries, trustees, settlors, third party premium
              payers,executors or administrators of your estate, parties with power of attorney and any other beneficial
              owner.
            </p>

            <h2>General insurance only: claims history</h2>
            <p>
              Under the conditions of your product you must tell us about any insurance-related incidents, whether or
              not they give rise to a claim. When you tell us about an incident we’ll pass information relating to it to
              a database.
            </p>

            <p>
              We may search these databases when you apply for insurance, in the event of any incident or claim or at a
              time of renewal, to validate your claims history or that of any other person or property likely to be
              involved in the product or claim.
            </p>

            <h2>Protecting your data outside the UK</h2>
            <p>
              The data that we collect from you may be transferred to, and stored at, a destination outside theUK to
              third-party suppliers, delegates or agents. We’ll take all reasonably necessary steps to make sure that
              your data is treated securely and in accordance with this privacy policy, to ensure your personal
              information is handled with the same protections that we apply ourselves.
            </p>
            <p>
              We’ll only transfer your data to a recipient outside the UK where we’re permitted to do so by law(for
              instance, (A) where the transfer is based on standard data protection clauses adopted or approved by the
              UK’s Information Commissioner’s Office, (B) where the transfer is to a territory that is deemed ‘adequate’
              by the UK, or (C) where the recipient is subject to an approved certification mechanism and the personal
              information is subject to appropriate safeguards, etc.).
            </p>
            <p>
              Unfortunately, sending information via e-mail is not completely secure; anything you send is done so at
              your own risk. Once received, we will secure your information in accordance with our security procedures
              and controls.
            </p>

            <h2>Your rights</h2>
            <p>
              You have rights under data protection law that relate to the way we process your personal data. More
              information on these rights can be found on the Information Commissioner’s website. If you wish to
              exercise any of these rights, please get in touch with your customer services team. Alternatively, you can
              also use the <a href="https://www.legalandgeneral.com/existing-customers/contact-us/"
                              className={styles.cuLink} target="_blank" rel="noopener noreferrer">Contact Us</a> section
              of our website.
            </p>


            <table className={classNames(styles.cuTable, styles.noHover, styles.tTerms)}>
              <thead>
              <tr>
                <th colSpan={2}>Your rights</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>1.</td>
                <td>The right to access the personal data that we hold about you.</td>
              </tr>
              <tr>
                <td>2.</td>
                <td>The right to make us correct any inaccurate personal data we hold about you.</td>
              </tr>
              <tr>
                <td>3.</td>
                <td>
                  The right to make us erase any personal data we hold about you. This right will only apply where for
                  example:
                  <ul>
                    <li>We no longer need to use the personal data to achieve the purpose we collected it for</li>
                    <li>You withdraw your consent if we’re using your personal data based on that consent</li>
                    <li>Where you object to the way we use your data, and there is no overriding legitimate interest.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>4.</td>
                <td>
                  The right to restrict our processing of the personal data we hold about you. This right will only
                  apply where for example:
                  <ul>
                    <li>You dispute the accuracy of the personal data we hold</li>
                    <li>You would like your data erased, but we require to hold it in order to stop its processing</li>
                    <li>You have the right to require us to erase the personal data but would prefer that our processing
                      is restricted instead
                    </li>
                    <li>Where we no longer need to use the personal data to achieve the purpose we collected it for, but
                      you need the data for legal claims.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>5.</td>
                <td>The right to object to our processing of personal data we hold about you (including for the purposes
                  of sending marketing materials to you).
                </td>
              </tr>
              <tr>
                <td>6.</td>
                <td>The right to receive personal data, which you have provided to us, in a structured, commonly used
                  and machine-readable format. You also have the right to make us transfer this personal data to another
                  organisation.
                </td>
              </tr>
              <tr>
                <td>7.</td>
                <td>The right to withdraw your consent, where we’re relying on it to use your personal data (for
                  example, to provide you with marketing information about our services or products).
                </td>
              </tr>
              <tr>
                <td>8.</td>
                <td>
                  For automated decisions (including profiling), you have right to:
                  <ul>
                    <li>Obtain an explanation of the decision and challenge it</li>
                    <li>Request for the decision to be reviewed by a human being.</li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>

            <h2>Contacts and complaints</h2>
            <p>
              If you have any questions about this privacy policy or wish to exercise any of your rights, including
              changing your marketing preferences, please get in touch with your usualLegal & General contact or your
              customer services team. Alternatively, you can also use the <a
              href="https://www.legalandgeneral.com/existing-customers/contact-us/" className={styles.cuLink}
              target="_blank" rel="noopener noreferrer">Contact Us</a> section of our website.
            </p>
            <p>
              If you have any concerns about the way we process your personal data, or are not happy with the way we’ve
              handled a request by you in relation to your rights, you also have the right to make a complaint to the
              Information Commissioner's Office. Their address is:
            </p>
            <p><strong>First Contact Team</strong></p>
            <p><strong>Information Commissioner's Office</strong></p>
            <p><strong>Wycliffe House</strong></p>
            <p><strong>Water Lane</strong></p>
            <p><strong>Wilmslow</strong></p>
            <p><strong>SK9 5AF</strong></p>

            <h2>Data Protection Officer</h2>
            <p>
              Legal & General has appointed a Data Protection Officer to provide independent expert advice andmonitor
              compliance with data protection laws:
            </p>

            <p>Name: Liz Gaspar</p>
            <p>E-mail address: <a className={styles.cuLink}
                                  href="mailto:Data.Protection@landg.com">Data.Protection@landg.com</a></p>
            <p>Address: 1 Coleman Street, London, EC2R 5AA</p>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicyPage;