import React from 'react';
import styles from './LoginSignupHolder.module.scss';

const LoginSignupHolder = ({children} : {children: React.ReactNode;}) => {
  return (
    <div className={styles.loginWrapper}>
      <div className={styles.loginHolder}>
        <img
          alt="Unu"
          src="images/unu-top.png"
          srcSet="images/unu-top@2x.png 2x, images/unu-top@3x.png 3x"/>
        <div className={styles.contentHolder}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default LoginSignupHolder;
