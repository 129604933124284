import { ChangeEvent } from 'react';

export class InputFileReader {
  static readonly defaultMaxFileSize = 4000000;

  static async getContentFromFileInputEvent(
    event: ChangeEvent<HTMLInputElement>,
    fileSizeLimit = 0,
  ): Promise<string | ArrayBuffer | null> {
    return new Promise((res, rej) => {
      const files = event.target.files;
      if (!files) {
        return rej(new Error('No files in the event target'));
      }

      if (fileSizeLimit && files[0].size > this.defaultMaxFileSize) {
        return rej(new Error(`Maximum file size is ${this.defaultMaxFileSize / 1000000} MB`));
      }

      const reader = new FileReader();

      reader.readAsText(files[0]);

      reader.onload = e => {
        res(e.target ? e.target.result : '');
      };

      reader.onerror = error => {
        rej(error);
      };
    });
  }
}
