import * as React from 'react';
import { CustomButton } from 'components/index';
import { STORE_UI } from "appConstants";
import {inject, observer} from "mobx-react";
import styles from './SuccessModal.module.scss';


export interface Props {
  onCancel: () => void;
}

@inject(STORE_UI)
@observer

export default class SuccessModal extends React.Component <Props, {}> {

  deleteEmployer = () =>{
    this.props.onCancel();
  };

  render() {
    return (
      <>
        <div className={styles.centerPart}>
          <img src="/images/i-check.svg" alt=""/>
          <div className={styles.textCenter}>Invite email sent</div>
        </div>
        <div className={styles.cButtonRight}>
          <CustomButton size={"xxs"} autoFocus={true} onClick={this.deleteEmployer} buttonType="primary">Done</CustomButton>
        </div>
      </>
    );
  }
}
