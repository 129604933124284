import * as React from 'react';
import * as Yup from 'yup';
import { CustomField, CustomButton, CustomFormSelect, Loader } from 'components';
import { Form, Formik, FormikActions } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { inject, observer } from "mobx-react";
import classNames from 'classnames';
import { SelectOption } from 'interfaces';
import { STORE_UI, STORE_EMPLOYER, VALIDATION_RULES, STORE_PAYROLL, IntervalTypeEnum } from "appConstants";
import { UIStore, EmployerStore, PayrollStore } from "stores";
import { RouteComponentProps } from "react-router";
import { PayPeriodModel } from "../../../graphql/models/employer";
import moment from 'moment';
import styles from './ConfigurePayPeriodModal.module.scss';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
  [STORE_PAYROLL]: PayrollStore;
  onCancel: () => void;
  payrollGroupId: string;
  payPeriod: PayPeriodModel;
}

const VALIDATION_SCHEMA_OF_MONTH = Yup.object().shape({
  dayOfMonth: VALIDATION_RULES.select,
  cutoffDay: VALIDATION_RULES.select
});

const VALIDATION_SCHEMA_OF_WEEK = Yup.object().shape({
  dayOfWeek: VALIDATION_RULES.select,
  cutoffDay: VALIDATION_RULES.select
});

const VALIDATION_SCHEMA_OF_DATE = Yup.object().shape({
  datePicker: VALIDATION_RULES.select,
  cutoffDay: VALIDATION_RULES.select
});

const shouldDisableDay = (date: Date) => {
  const isBefore = moment().startOf('day').isBefore(date, 'day');
  const day = date.getDay();

  return day !== 0 && day !== 6 && isBefore;
};

interface FormValues {
  intervalType: SelectOption;
  dayOfMonth?: SelectOption;
  dayOfWeek?: SelectOption;
  datePicker?: string;
  cutoffDay?: SelectOption;
}

@inject(STORE_UI, STORE_EMPLOYER, STORE_PAYROLL)
@observer

export default class ConfigurePayPeriodModal extends React.Component<Props, {}> {
  private readonly DATE_PICKER_FORMAT = "dd/MM/yyyy"
  private readonly START_DATE_FORMAT = "YYYY-MM-DD"

  state = {
    startDate: undefined,
    validationSchema: ""
  };

  weekDaysOptions: SelectOption[] = [
    { label: "Monday", value: "1" },
    { label: "Tuesday", value: "2" },
    { label: "Wednesday", value: "3" },
    { label: "Thursday", value: "4" },
    { label: "Friday", value: "5" }
  ];

  monthDaysOptions: SelectOption[] = [];
  cutOffDate: SelectOption[] = [];

  intervalTypeOptions: SelectOption[] = [
    { label: "Weekly", value: IntervalTypeEnum.Weekly },
    { label: "Bi-weekly", value: IntervalTypeEnum.BiWeekly },
    { label: "4-weekly", value: IntervalTypeEnum.FourWeekly },
    { label: "Monthly", value: IntervalTypeEnum.Monthly },
    { label: "Quarterly", value: IntervalTypeEnum.Quarterly },
    { label: "Bi-annually", value: IntervalTypeEnum.BiAnnually },
    { label: "Annually", value: IntervalTypeEnum.Annually }
  ];

  defaultPayPeriodOption: string | undefined = undefined;

  initialValues: FormValues = {
    intervalType: this.intervalTypeOptions[3] as SelectOption,
    cutoffDay: { label: "3 days before", value: "259200" }
  };

   UNSAFE_componentWillMount(): void {
    this.props[STORE_UI].setFetchError();
    this.setMonthDaysOptions();
    this.setCutOffDate();

    if (!this.props.payPeriod) {
      return
    }

    const intervalType = this.intervalTypeOptions.find(item => item.value === this.props.payPeriod.intervalType);
    const cutoffDay = this.cutOffDate.find(item => item.value === this.props.payPeriod.cutoffTime.toString());
    const payDate = moment(this.props.payPeriod.startDate)

    switch (intervalType?.value) {
      case IntervalTypeEnum.Weekly:
      case IntervalTypeEnum.BiWeekly:
      case IntervalTypeEnum.FourWeekly:
        const dayOfWeek = payDate.isoWeekday()
        this.initialValues.dayOfWeek = this.weekDaysOptions.find(item => item.value === dayOfWeek.toString());
        break;
      case IntervalTypeEnum.Monthly:
        const dayOfMonth = payDate.date()
        this.initialValues.dayOfMonth = this.monthDaysOptions.find(item => item.value === dayOfMonth.toString());
        break;
      case IntervalTypeEnum.Quarterly:
      case IntervalTypeEnum.BiAnnually:
      case IntervalTypeEnum.Annually: {
        this.initialValues.datePicker = payDate.toString()
        break;
      }
    }

    if (intervalType) {
      this.initialValues.intervalType = intervalType;
    }

    if (cutoffDay) {
      this.initialValues.cutoffDay = cutoffDay
    }
  }

  setMonthDaysOptions() {
    for (let i = 1; i <= 31; i++) {
      this.monthDaysOptions.push({ label: i.toString(), value: i.toString() });
    }
  }

  setCutOffDate() {
    for (let i = 1; i <= 10; i++) {
      const cuLabel = i === 1 ? i.toString() + " day before" : i.toString() + " days before";
      this.cutOffDate.push({ label: cuLabel, value: (i * 60 * 60 * 24).toString() });
    }
  }

  getStartingPoint(formSubmitData: FormValues): string {
    switch (formSubmitData.intervalType.value) {
      case IntervalTypeEnum.Weekly:
      case IntervalTypeEnum.BiWeekly:
      case IntervalTypeEnum.FourWeekly:
        return formSubmitData.dayOfWeek?.value || ""
      case IntervalTypeEnum.Monthly:
        return formSubmitData.dayOfMonth?.value || ""
      case IntervalTypeEnum.Quarterly:
      case IntervalTypeEnum.BiAnnually:
      case IntervalTypeEnum.Annually:
        return moment(formSubmitData.datePicker || "").format(this.START_DATE_FORMAT)
      default:
        return ""
    }
  }

  onSubmit = async (formSubmitData: FormValues, actions: FormikActions<FormValues>) => {
    this.props[STORE_UI].setLoadModalData();
    actions.setStatus();

    const payrollGroupPayPeriod = {
      payrollGroupId: this.props.payrollGroupId,
      payPeriodSettings: {
        intervalType: formSubmitData.intervalType.value as IntervalTypeEnum,
        startingPoint: this.getStartingPoint(formSubmitData),
        cutoffTime: formSubmitData.cutoffDay ? Number(formSubmitData.cutoffDay.value) : 0
      }
    };

    await this.props[STORE_PAYROLL].updatePayrollGroupPayPeriod(payrollGroupPayPeriod);
    this.props[STORE_UI].setLoadModalData(false);

    if (this.props[STORE_UI].fetchError) {
      actions.setStatus({ fetchError: this.props[STORE_UI].fetchError })
    } else {
      this.props.onCancel();
      this.props[STORE_PAYROLL].loadPayrollGroupById(this.props.payrollGroupId);
    }
  };

  async clearSettings(e: React.MouseEvent) {
    e.preventDefault();
    this.props[STORE_UI].setLoadModalData();

    const payrollGroupPayPeriod = {
      payrollGroupId: this.props.payrollGroupId,
      payPeriodSettings: null
    }

    await this.props[STORE_PAYROLL].updatePayrollGroupPayPeriod(payrollGroupPayPeriod);
    this.props[STORE_UI].setLoadModalData(false);

    if (!this.props[STORE_UI].fetchError) {
      this.props.onCancel();
      this.props[STORE_PAYROLL].loadPayrollGroupById(this.props.payrollGroupId);
    } else {
      this.forceUpdate();
    }
  };

  renderPayPeriodRelatedField(form: any, typeOfControl: string) {
    switch (typeOfControl) {
      case IntervalTypeEnum.Monthly:
        return this.renderControlDayOfMonth(form);
      case IntervalTypeEnum.Weekly:
      case IntervalTypeEnum.BiWeekly:
      case IntervalTypeEnum.FourWeekly:
        return this.renderControlDayOfWeek(form);
      case IntervalTypeEnum.Quarterly:
      case IntervalTypeEnum.BiAnnually:
      case IntervalTypeEnum.Annually:
        return this.renderDatePicker(form);
    }
  };

  renderControlDayOfMonth(form: any) {
    const controlName = "dayOfMonth";
    return (
      <CustomField
        name={controlName}
        title="Day of the month"
        placeholder="Enter day of the month ..."
        form={form}
        component={CustomFormSelect}
        defaultValue={undefined}
        className={styles.leftPosition}
        options={this.monthDaysOptions}
        onSelect={form.setFieldValue}
        onBlur={() => {
          form.setFieldTouched(controlName)
        }}
        onKeyDown={(e: KeyboardEvent) => {
          this.props[STORE_UI].handleFormKeyPress(e, form)
        }}
      />
    )
  }

  renderControlDayOfWeek(form: any) {
    const controlName = "dayOfWeek";
    return (
      <CustomField
        name={controlName}
        title="Day of the week"
        placeholder="Enter day of the week ..."
        form={form}
        component={CustomFormSelect}
        value={""}
        defaultValue={{ label: "Monday", value: "1" }}
        className={styles.leftPosition}
        options={this.weekDaysOptions}
        onSelect={form.setFieldValue}
        onBlur={() => { form.setFieldTouched(controlName) }}
        onKeyDown={(e: KeyboardEvent) => { this.props[STORE_UI].handleFormKeyPress(e, form) }}
      />
    )
  }

  handleChange = (date: any) => {
    this.setState({
      startDate: date
    });
  };

  renderDatePicker(form: any) {
    const controlName = "datePicker";
    let controlVal = form.values[controlName] ? new Date(form.values[controlName]) : undefined;

    return (
      <div className={classNames(styles.cPicker, styles.leftPosition)}>
        <label htmlFor="datePicker">Start date</label>
        <DatePicker
          name={controlName}
          //dateFormat={this.DATE_FORMAT}
          dateFormat={this.DATE_PICKER_FORMAT}
          autoComplete="off"
          placeholderText={"Enter date ..."}
          selected={controlVal}
          onChange={(date) => {
            form.setFieldValue(controlName, date)
          }}
          filterDate={shouldDisableDay}
          className={styles.cuDatePicker}
        />
      </div>
    )
  }

  resetMonth(form: any) {
    this.setState({ validationSchema: VALIDATION_SCHEMA_OF_MONTH });
    form.setFieldValue("dayOfMonth", "");
  }

  resetWeek(form: any) {
    this.setState({ validationSchema: VALIDATION_SCHEMA_OF_WEEK });
    form.setFieldValue("dayOfWeek", "");
  }

  resetDate(form: any) {
    this.setState({ validationSchema: VALIDATION_SCHEMA_OF_DATE });
    form.setFieldValue("datePicker", undefined);
  }

  payPeriodOnSelect = (name: string, value: SelectOption, form: any) => {
    form.setFieldValue(name, value);

    switch (value.value) {
      case IntervalTypeEnum.Weekly:
      case IntervalTypeEnum.BiWeekly:
      case IntervalTypeEnum.FourWeekly:
        this.resetWeek(form);
        break;
      case IntervalTypeEnum.Monthly:
        this.resetMonth(form);
        break;
      case IntervalTypeEnum.Quarterly:
      case IntervalTypeEnum.BiAnnually:
      case IntervalTypeEnum.Annually:
        this.resetDate(form);
        break;
    }
  };

  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={this.initialValues}
        validationSchema={this.state.validationSchema}
        onSubmit={this.onSubmit}
      >{form => {
        const isDisabled = !form.dirty || !form.isValid;
        return <Form>
          <div className={classNames(styles.cPopupContent, styles.minHeight)}>

            <p className={styles.subDescription}>Configure the period and day of the period when you run payroll.</p>

            <div>
              <div className={styles.cControlsLeft}>
                <CustomField
                  name="intervalType"
                  title="Pay period interval"
                  placeholder="Enter pay period..."
                  form={form}
                  component={CustomFormSelect}
                  defaultValue={this.defaultPayPeriodOption}
                  className={styles.leftPosition}
                  options={this.intervalTypeOptions}
                  onSelect={(name, value) => { this.payPeriodOnSelect(name, value, form) }}
                  onBlur={() => { form.setFieldTouched("intervalType") }}
                  onKeyDown={(e: KeyboardEvent) => { this.props[STORE_UI].handleFormKeyPress(e, form) }}
                />
                {this.renderPayPeriodRelatedField(form, form.values.intervalType.value)}
              </div>

              <div className={styles.cControlsLeft}>
                <CustomField
                  name="cutoffDay"
                  title="Cut off date"
                  placeholder="Enter cut off date..."
                  form={form}
                  component={CustomFormSelect}
                  className={styles.leftPosition}
                  options={this.cutOffDate}
                  onSelect={form.setFieldValue}
                  onBlur={() => {
                    form.setFieldTouched("cutoffDay")
                  }}
                  onKeyDown={(e: KeyboardEvent) => {
                    this.props[STORE_UI].handleFormKeyPress(e, form)
                  }}
                />
                <div className={classNames(styles.leftPositionText, styles.subDescription)}>
                  We will send email reminders 3 days
                  prior to this day to the registered payroll.
                </div>
              </div>

              {this.props[STORE_UI].fetchError && <p className={styles.error}>{this.props[STORE_UI].fetchError}</p>}
            </div>

            <div className={styles.cButtonRight}>
              {this.props[STORE_UI].loadModalData ? <Loader className={styles.loader} /> :
                <>
                  <a className={styles.clearLink} href="/" onClick={(e) => this.clearSettings(e)}>Clear settings</a>
                  <CustomButton
                    size={"xxs"}
                    type="submit"
                    buttonType="primary"
                    disabled={isDisabled}
                  >
                    Save
                  </CustomButton>
                </>
              }
            </div>

          </div>
        </Form>
      }}
      </Formik>
    );
  }
}
