import * as React from "react";
import styles from "./CustomMaskField.module.scss";
import classNames from 'classnames';
import {Field} from "formik";
import {ReactNode} from "react";
import { SelectOption } from 'interfaces';
import MaskedInput from "react-text-mask";

interface Props {
  name: string;
  title: string;
  placeholder?: string;
  className?: string;
  inputStyle?: string;
  inputSize?: string;
  size?: string;
  form: any;
  withSeparator?: boolean;
  component?: ReactNode;
  options?: SelectOption[];
  [x:string]: any;
  onChange?: (e: React.ChangeEvent) => void;
  // for select component onChange should be replaced to onSelect, as component works with value (not with event)
  onSelect?: (name: string, value: SelectOption) => void;
}

class CustomMaskField extends React.Component <Props, {}> {
  onSelectChange = (val: SelectOption) => this.props.onSelect && this.props.onSelect(this.props.name, val)

  render() {
    const { name, title, placeholder, size,  inputSize, inputStyle, className, form, onChange, withSeparator, component, onSelect, ...props } = this.props;
    const onChangeEvent = onSelect
      ? this.onSelectChange
      : onChange;
    const errorMsg = form.errors[name] && form.touched[name]?form.errors[name]:null;
    return (
      <div className={classNames(styles.field, className)}>
        <label>
            <>{title}{withSeparator && ':'}</>
          <Field
            {...props}
            id={name}
            className={classNames(styles.name, styles.input, styles.medium)}
            component={MaskedInput}
            name={name}
            placeholder={placeholder}
            value={form.values[name]}
            onChange={onChangeEvent}
            title={title}
          />
        </label>
        <div className={styles.error}>{errorMsg}</div>
      </div>
  )}
}

export default CustomMaskField;
