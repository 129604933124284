export const createEmployer = ` 
  mutation CreateEmployer($input: CreateEmployerInput!){
    createEmployer(
      employer: $input
    ) {
      id
      name
      clientId
    }
  }
`;

export const updateEmployer = ` 
  mutation UpdateEmployer($input: UpdateEmployerInput!){
    updateEmployer(
      employer: $input
    ) {
      id
      name
      clientId
    }
  }
`;

export const deleteEmployer = ` 
  mutation DeleteEmployer($employerId: ID!){
    deleteEmployer(
      employerId: $employerId, 
    ) {
      id
    }
  }
`;

export const createEmployerUser = ` 
  mutation createEmployerUser($employerId: ID!, $input: CreateEmployerUserInput){
    createEmployerUser(
      employerId: $employerId,
      employerUser: $input
    ) {
      id
      email
    }
  }
`;

export const updateEmployerUser = ` 
  mutation UpdateEmployerUser($employerId: ID!, $input: UpdateEmployerUserInput){
    updateEmployerUser(
      employerId: $employerId,
      employerUser: $input
    ) {
      id
      email
    }
  }
`;

export const deleteEmployerUser = ` 
  mutation DeleteEmployerUser($employerId: ID!, $employerUserId: ID!){
    deleteEmployerUser(
      employerId: $employerId, 
      employerUserId: $employerUserId
    ) {
      id
      email
    }
  }
`;

export const createBankAccount = ` 
  mutation CreateBankAccount($employerId: ID!, $input: CreateBankAccountInput!){
    createBankAccount(
      employerId: $employerId,
      account: $input
    ) {
      id
      accountNumber
    }
  }
`;

export const updateBankAccount = ` 
  mutation UpdateBankAccount($input: UpdateBankAccountInput!){
    updateBankAccount(
      account: $input
    ) {
      id
      accountNumber
    }
  }
`;

export const deleteBankAccount = ` 
  mutation DeleteBankAccount($bankAccountId: ID!){
    deleteBankAccount(
      id: $bankAccountId
    )
    {
      id
    }
  }
`;

export const createPayrollGroup = ` 
  mutation CreatePayrollGroup($input: CreatePayrollGroupInput!){
    createPayrollGroup(
      payrollGroup: $input
    ) {
      id
      name
    }
  }
`;

export const updatePayrollGroup = ` 
  mutation UpdatePayrollGroup($input: UpdatePayrollGroupInput!){
    updatePayrollGroup(
      payrollGroup: $input
    ) {
      id
      name
    }
  }
`;

export const deletePayrollGroup = ` 
  mutation DeletePayrollGroup($id: ID!){
    deletePayrollGroup(
      id: $id
    ) {
      id
    }
  }
`;