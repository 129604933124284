import React from "react";
import classNames from "classnames";
import Select from 'react-select';
import { Props as SelectProps } from 'react-select/creatable';
import styles from "./CustomFormSelect.module.scss";

const CustomFormSelect = ({className, onBlur, name, ...props}: SelectProps<any>) => (
  <Select
    {...props}
    id={name}
    className={classNames(styles.select, className)}
    classNamePrefix="selectHolder"
  />
);

export default CustomFormSelect;
