export const findEmployeesQuery = ` 
query findEmployees($criteria: EmployeeSearchCriteriaInput){
  findEmployees(
    criteria: $criteria
  ) {
    id
    name
    nino
    status
    leaveReason
    customAttributes {
      key
      value
    }
    payrollGroupId
    employerId
    employeeId
  }
} 
`;

export const countEmployeesQuery = ` 
query findEmployees($criteria: EmployeeSearchCriteriaInput){
  findEmployees(
    criteria: $criteria
  ) {
    id
  }
} 
`;

export const validateHrSftpIntegrationCsvMapping = ` 
query validateHrSftpIntegrationCsvMapping($input: HrSftpIntegrationCsvMappingValidationInput!){
  validateHrSftpIntegrationCsvMapping(
    input: $input
  ) {
    success
    resultItems {
      csvItem {
        name
        nino
        status
        payrollGroup
        leaverReason
      }
      errors
    } 
  }
} 
`;

export const findEmployeeApprovalCsvInfoQuery = `
query FindEmployeeApprovalCsvInfo($employerId: ID!) {
  findEmployeeApprovalCsvInfo(employerId: $employerId) {
    uploadLink
    csvInfo {
      employerId
      id
      name
      uploadDate
    }
  }
}
`;

export const processEmployeeApprovalCsvFileMutation = `
mutation ProcessEmployeeApprovalCsvFile($employerId: ID!) {
  processEmployeeApprovalCsvFile(employerId: $employerId) {
    success
    error
    resultItem {
      employerId
      id
      name
      uploadDate
    }
  }
}
`;
