import React from 'react';
import {RouteComponentProps} from 'react-router';
import {Loader} from 'components';
import {inject, observer} from 'mobx-react';
import {UserStore} from 'stores';
import {STORE_USER} from 'appConstants';

export interface Props extends RouteComponentProps<any> {
  [STORE_USER]: UserStore;
}

@inject(STORE_USER)
@observer

class SSOPage extends React.PureComponent<Props, {}> {
  componentDidMount() {
    this.props[STORE_USER].federatedSignIn();
  }

  render() {
    return (
      <Loader/>
    );
  }
}

export default SSOPage;