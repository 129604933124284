import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { Router, Route } from 'react-router';
import { createBrowserHistory } from 'history';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { awsExports } from 'aws-exports';
import { RootStore } from 'stores';
import { Amplify, Auth } from 'aws-amplify';
import { Developer } from './utils/developer';

let config = awsExports;

if (process.env.REACT_APP_NODE_ENV === 'local') {
  const developer = new Developer(true, true);

  Auth.currentUserInfo = () =>
    Promise.resolve({
      attributes: { email: developer.email },
    });

  Auth.currentSession = () => developer.currentSession();
  Auth.currentAuthenticatedUser = () => Promise.resolve(developer);

  config = {
    ...awsExports,
    API: {
      ...awsExports.API,
      graphql_headers: async () => ({ Authorization: 'iheartrest' }),
    },
  };
}

// configure Amplify
Amplify.configure(config);

// prepare MobX stores
export const history = createBrowserHistory();
const rootStore = new RootStore(history);

const RootProvider = (
  <Provider {...rootStore}>
    <Router history={history}>
      <Route path="/" component={App} />
    </Router>
  </Provider>
);

ReactDOM.render(RootProvider, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
