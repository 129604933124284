export const updateEmployees = ` 
  mutation updateEmployees($employees: [UpdateEmployeeInput!]!){
    updateEmployees(
      employees: $employees
    ) {
      id
    }
  }
`;

export const updateHrSftpIntegrationConfig = `
mutation UpdateHrSftpIntegrationConfig($input: UpdateHrSftpIntegrationConfigInput!) {
  updateHrSftpIntegrationConfig (input: $input) {
    sftpSettings {
        username
        publicKey
    }
    sftpUrl
    csvMapping {
        columnKey
        columnIndex
    }
  }
}
`;