export enum AuthRole {
  ROLE_PAYCHAIN_ADMIN = 'PaychainAdmin',
  ROLE_EMPLOYER_ADMIN = 'EmployerAdmin',
  ROLE_HR_ADMIN = 'HrAdmin',
  ROLE_PAYROLL_ADMIN = 'PayrollAdmin',
}

export const EmployerUsersRoles: { [key: string]: string } = {
  EmployerAdmin: 'Admin',
  HrAdmin: 'HR',
  PayrollAdmin: 'Payroll',
};
