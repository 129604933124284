import React, {ButtonHTMLAttributes} from 'react';
import classNames from 'classnames';
import styles from './CustomButton.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
  buttonType?: 'default' | 'primary' | 'link' | 'linkAlt' | 'rightArrow';
  size?: 'm' | 's' | 'xs' | 'xxs';
  buttonColor?: 'default' | 'green';
}

const CustomButton = ({
  children,
  buttonColor = 'default',
  className,
  fullWidth,
  disabled,
  buttonType = 'default',
  size = 'm',
  ...props
}: ButtonProps) =>
  <button
    {...props}
    disabled={disabled}
    className={classNames(
      {
        [styles.small]: size === 's',
        [styles.xsmall]: size === 'xs',
        [styles.xxsmall]: size === 'xxs',
        [styles.primary]: buttonType === 'primary',
        [styles.rightArrow]: buttonType === 'rightArrow',
        [styles.link]: buttonType === 'link',
        [styles.linkAlt]: buttonType === 'linkAlt',
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
        [styles.greenButton]: buttonColor === 'green',
      },
      styles.button,
      className
    )}
  >
    {children}
  </button>;

export default CustomButton;

