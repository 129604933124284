export const createPaychainAdmin = ` 
  mutation CreatePaychainAdmin($input: CreatePaychainAdminInput!){
    createPaychainAdmin(
      paychainAdmin: $input
    ) {
      id
      email
    }
  }
`;

export const updatePaychainAdmin = ` 
  mutation UpdatePaychainAdmin($input: UpdatePaychainAdminInput!){
    updatePaychainAdmin(
      paychainAdmin: $input
    ) {
      id
      email
    }
  }
`;

export const deletePaychainAdmin = ` 
  mutation DeletePaychainAdmin($email: String!){
    deletePaychainAdmin(
      email: $email
    ) {
      id
      email
    }
  }
`;

export const generateAuditFile = ` 
  mutation generateAuditFile($type: AuditFileType!){
    generateAuditFile(
      type: $type
    )
  }
`;
