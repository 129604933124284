import { CenterBanner, TableLoader, TopContentBar } from 'components';
import React from 'react';

import { history } from 'index';
import { STORE_EMPLOYER, STORE_HR, STORE_UI, STORE_USER } from 'appConstants';
import { inject, observer } from 'mobx-react';

import styles from './HrEmployeeListPage.module.scss';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import { EmployerStore, HrStore, UIStore, UserStore } from 'stores';
import { FindEmployeeApprovalCsvInfo } from '../../graphql/models/hr';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_HR]: HrStore;
  [STORE_USER]: UserStore;
  [STORE_EMPLOYER]: EmployerStore;
}

export interface State {
  enableSubmitBtn: boolean
  activeTab: string
  checked: boolean | undefined
  checkDirty: boolean
}


@inject(STORE_UI, STORE_HR, STORE_USER, STORE_EMPLOYER)
@observer

class HrEmployeeListPage extends React.Component<Props, State> {

  employerId = -1;

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    this.props[STORE_HR].loadingEmployeeFileInfo = true;
    const { username } = this.props[STORE_USER].user;
    await this.props[STORE_EMPLOYER].loadEmployerByHrEmail(username);
    this.employerId = Number(this.props[STORE_EMPLOYER].currentEmployer.id);

    await this.props[STORE_HR].loadNewSignUpsCount(this.employerId);
    this.props[STORE_HR].loadHrTabs();

    await this.props[STORE_HR].loadEmployeeFileInfo(this.employerId);
  }

  onTabAction = (val: string) => {
    history.push("/hr/" + val);
  };

  renderStatus(defaultCaption: string): JSX.Element {
    const hrStore = this.props[STORE_HR];

    if (hrStore.uploadingEmployeeFile) {
      return <td>Processing...</td>;
    } else if (hrStore.employeeFileUploadInfo && hrStore.employeeFileUploadInfo.error) {
      return <td className={styles.errorCol}>
        <span className={styles.error}>{hrStore.employeeFileUploadInfo.error}</span>
      </td>;
    }

    return <td>{defaultCaption}</td>;
  }

  renderFileUploadLink(caption: string, url: string, employerId: number): JSX.Element {
    const hrStore = this.props[STORE_HR];
    return <>
      <label className={styles.tableLink} htmlFor="uploadFile">{caption}</label>
      <input
        type="file"
        name="uploadFile"
        className={styles.uploadFile}
        id="uploadFile"
        accept=".csv"
        onChange={(e) => {
          hrStore.onChangeEmployeeFile(e, url, employerId)
        }}
      />
    </>
  }

  renderEmployeeFileInfo(
    employeeFileInfo: FindEmployeeApprovalCsvInfo,
    employerId: number,
    ): JSX.Element {
    const uiStore = this.props[STORE_UI];

    if (employeeFileInfo) {
      const { csvInfo } = employeeFileInfo;

      if (csvInfo) {
        return <tr>
          <td>{uiStore.getFormattedDateTime(csvInfo.uploadDate)}</td>
          <>{this.renderStatus('Success')}</>
          <td>{this.renderFileUploadLink('Reupload', employeeFileInfo.uploadLink, employerId)}</td>
        </tr>
      }
      return <tr>
        <td></td>
        <>{this.renderStatus('No file uploaded yet')}</>
        <td>{this.renderFileUploadLink('Upload', employeeFileInfo.uploadLink, employerId)}</td>
      </tr>
    }

    return <tr>
      <td></td>
      <td className={styles.errorCol}><span className={styles.error}>Unable to retrieve data. Please try again.</span></td>
      <td></td>
    </tr>
  }

  renderEmployees() {
    const hrStore = this.props[STORE_HR];

    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
          <tr>
            <th className={styles.colEmployeeUploadDate}>Upload date</th>
            <th>Status</th>
            <th className={styles.colEmployeeAction}>Action</th>
          </tr>
        </thead>
        <tbody>
        {hrStore.loadingEmployeeFileInfo ? <TableLoader columnSpan={3}/> : (
          this.renderEmployeeFileInfo(hrStore.employeeFileInfo, this.employerId)
        )}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <>
      <CenterBanner
       mainTitle={'HR - ' + this.props[STORE_EMPLOYER].currentEmployer.name}
       subTitle={this.props[STORE_EMPLOYER].currentEmployer.clientId}
       tabs={this.props[STORE_HR].hrTabs}
        activeTab={"employees"}
        onClickTab={this.onTabAction}
      />
        <div className={styles.cContent}>
        <TopContentBar
            mainTitle={"Employees"}
          />
        {this.renderEmployees()}
      </div>
      </>
    )
  }
}

export default HrEmployeeListPage;
