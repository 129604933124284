export const DEFAULT_MODAL_WIDTH = 400;
export const FORM_MODAL_WIDTH = 783;

export const CREATE_EMPLOYER_MODAL = 'CreateEmployerModal';
export const EDIT_EMPLOYER_MODAL = 'EditEmployerModal';
export const CREATE_ADMIN_USER_MODAL = 'CreateAdminUserModal';
export const EDIT_ADMIN_USER_MODAL = 'EditAdminUserModal';
export const CREATE_BANK_ACCOUNT_MODAL = 'CreateBankAccountModal';
export const EDIT_BANK_ACCOUNT_MODAL = 'EditBankAccountModal';
export const DELETE_BANK_ACCOUNT_MODAL = 'DeleteBankAccountModal';
export const CREATE_SUPER_ADMIN_MODAL = 'CreateSuperAdminModal';
export const EDIT_SUPER_ADMIN_MODAL = 'EditSuperAdminModal';
export const DELETE_ACCEPTANCE_MODAL = 'DeleteAcceptanceModal';
export const CREATE_EMPLOYER_USER_MODAL = 'CreateEmployerUserModal';
export const EDIT_EMPLOYER_USER_MODAL = 'EditEmployerUserModal';
export const CREATE_PAYROLL_GROUP_MODAL = 'CreatePayrollGroupModal';
export const EDIT_PAYROLL_GROUP_MODAL = 'EditPayrollGroupModal';
export const CSV_UPLOADED_MODAL = 'CsvUploadedModal';
export const TEST_CSV_MAPPING_MODEL = 'TestCsvMappingModal';
export const CONFIGURE_PAY_PERIOD_MODAL = 'ConfigurePayPeriod';
export const SUCCESS_MODAL = 'SuccessModal';
export const ERROR_MODAL = 'ErrorModal';
export const PAYROLL_MODAL = 'PayrollModal';
