import React from 'react';
import classNames from 'classnames';
import styles from './CustomSelect.module.scss';

interface SelectItems {
  title: string;
  value: string;
}

interface Props {
  className?: string;
  imgUrl?: string
  items: SelectItems[];
  onClickOption: (action: string) => void;
}

const initialState = {
  showItems: false as boolean,
};

type State = typeof initialState;

class CustomSelect extends React.PureComponent<Props, State>{
  state = initialState;

  showItems = (items: SelectItems[]) => {
    return items.map((item, key)=>
      <li onClick={(e)=>{this.props.onClickOption(item.value); this.hideItems(e);}} key={key}>
        {item.title}
      </li>
    );
  };

  hideItems(e: React.MouseEvent){
    this.setState({showItems: false});
    e.stopPropagation();
  };

  clickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    this.setState({showItems: true});
    e.stopPropagation();
  };

  render() {
    const {items} = this.props;
    const imageUrl = this.props.imgUrl ? this.props.imgUrl : "/images/i-details.svg";
    return (
      <div className={styles.cSelect}>
        {this.state.showItems &&<div onClick={(e)=>{this.hideItems(e)}} className={styles.coverLayer} />}
        <a href="/" className={styles.detailsLink} onClick={this.clickHandler}>
          <img src={imageUrl} alt=""/>
        </a>
        {this.state.showItems && <ul className={classNames(styles.cItems, this.props.className)}>
          {this.showItems(items)}
        </ul>}
      </div>
    )
  }
}
export default CustomSelect;

