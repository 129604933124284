import * as React from 'react';
import { CustomField, CustomButton, Loader } from 'components';
import { Form, Formik, FormikActions } from "formik";
import { inject, observer } from "mobx-react";
import { STORE_UI, STORE_ADMIN, VALIDATION_RULES, FORM_MODAL_WIDTH, SUCCESS_MODAL } from "appConstants";
import { UIStore, AdminStore } from "stores";
import classNames from "classnames";
import CreatePaychainAdminInput from "../../../graphql/mutations/inputs/CreatePaychainAdminInput";
import * as Yup from "yup";
import styles from './CreateSuperAdminModal.module.scss';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

export interface Props {
  [STORE_UI]: UIStore;
  [STORE_ADMIN]: AdminStore;
  onCancel: () => void;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: VALIDATION_RULES.firstName,
  lastName: VALIDATION_RULES.lastName,
  email: VALIDATION_RULES.email
});

@inject(STORE_UI, STORE_ADMIN)
@observer

export default class CreateSuperAdminModal extends React.Component <Props, {}> {

  componentDidMount(): void {
    this.props[STORE_ADMIN].clearErrorMsg();
  }

  openSuccessModal(): void {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: SUCCESS_MODAL,
      title: 'Add admin user',
    });
  }

  onSubmit = async ({ firstName, lastName, email }: FormValues, actions: FormikActions<FormValues>) => {
    this.props[STORE_UI].setLoadModalData();
    actions.setStatus();

    const superAdminData: CreatePaychainAdminInput = {
      email: email,
      firstName: firstName,
      lastName: lastName
    };

    await this.props[STORE_ADMIN].createSuperAdmin(superAdminData);
    this.props[STORE_UI].setLoadModalData(false);

    if(this.props[STORE_ADMIN].fetchError){
      actions.setStatus({ fetchError: this.props[STORE_ADMIN].fetchError })
    } else {
      this.props.onCancel();
      this.openSuccessModal();
    }
  };

  render() {

    const initialValues = {
      firstName: '',
      lastName: '',
      email: ''
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.onSubmit}
      >{ form => {
        const isDisabled = !form.dirty || !form.isValid;
        return <Form>

          <div className={classNames(styles.cPopupContent, styles.minHeight)}>
            <div>
              <div className={styles.cControlsLeft}>
                <CustomField
                  name="firstName"
                  title="First name"
                  placeholder="Enter first name..."
                  autoFocus
                  form={form}
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
                <CustomField
                  name="lastName"
                  title="Last name"
                  placeholder="Enter last name..."
                  form={form}
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
              </div>

              <div className={styles.cControlsLeft}>
                <CustomField
                  name="email"
                  title="Email"
                  placeholder="Enter email..."
                  form={form}
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
                <div className={styles.leftPosition} />
              </div>
              {form.status && <p className={styles.serverError}>{form.status.fetchError}</p>}
            </div>

            <div className={styles.cButtonRight}>
              {this.props[STORE_UI].loadModalData?<Loader className={styles.loader}/>:
                <CustomButton size={"xxs"} type="submit" buttonType="primary" disabled={isDisabled}>Create</CustomButton>
              }
            </div>

          </div>
        </Form>}}
      </Formik>
    );
  }
}
