import React from 'react';
import { inject, observer } from 'mobx-react';
import { PayrollGroupsModel } from 'graphql/models/employer';
import { RouteComponentProps } from 'react-router';
import { UIStore, EmployerStore, UserStore } from 'stores';
import { CenterBanner, TopContentBar, CustomSelect, TableLoader, NoData } from 'components';

import {
  STORE_UI,
  STORE_EMPLOYER,
  STORE_USER,
  EMPLOYER_DETAILS_TABS,
  CREATE_PAYROLL_GROUP_MODAL,
  EDIT_PAYROLL_GROUP_MODAL,
  FORM_MODAL_WIDTH,
  DELETE_ACCEPTANCE_MODAL,
} from 'appConstants';

import { history } from 'index';
import classNames from 'classnames';
import styles from './EmployerPayrollGroupsPage.module.scss';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
  [STORE_USER]: UserStore;
}

const selectItems = [
  {title: 'Edit', value: 'edit'},
  {title: 'Delete', value: 'delete'}
];

@inject(STORE_UI, STORE_EMPLOYER, STORE_USER)
@observer

class EmployerPayrollGroupsPage extends React.Component<Props, {}>{

  componentDidMount(): void {
    const {username} = this.props[STORE_USER].user;
    this.props[STORE_EMPLOYER].loadEmployerByAdminEmail(username, 'payrollGroups');
  }

  onTabAction = (val: string) => {
    history.push("/employer/"+val);
  };

  createNewPayrollGroup = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: CREATE_PAYROLL_GROUP_MODAL,
      title: 'New payroll group',
      props: {
        employer: this.props[STORE_EMPLOYER].currentEmployer,
        userName: this.props[STORE_USER].user.username,
      }
    });
  };

  editPayrollGroup = (payrollGroup: PayrollGroupsModel) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: EDIT_PAYROLL_GROUP_MODAL,
      title: 'Edit payroll group',
      props: {
        employer: this.props[STORE_EMPLOYER].currentEmployer,
        userName: this.props[STORE_USER].user.username,
        payrollGroup: payrollGroup
      }
    });
  };

  deletePayrollGroup = (payrollGroup: PayrollGroupsModel) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: DELETE_ACCEPTANCE_MODAL,
      title: 'Delete payroll group',
      props: {
        description: "Are you sure you want to delete this payroll group? You won’t be able to undo this."
      },
      eventProps: {
        onSubmit: () => this.onSubmitDeletePayrollGroup(payrollGroup.id)
      }
    });
  };

  async onSubmitDeletePayrollGroup(payrollGroupId: string) {
    this.props[STORE_UI].setLoadModalData();
    await this.props[STORE_EMPLOYER].deletePayrollGroup(payrollGroupId, this.props[STORE_USER].user.username);
    this.props[STORE_UI].setLoadModalData(false);

    if(!this.props[STORE_UI].fetchError){
      this.props[STORE_UI].closeModal();
    }
  }

  selectActions(action: string, payrollGroup: PayrollGroupsModel){
    switch(action) {
      case 'edit':  this.editPayrollGroup(payrollGroup);
        break;
      case 'delete':  this.deletePayrollGroup(payrollGroup);
        break;
    }
  }

  sortingCodeFormat(sortingCode: string){
    let arr =  sortingCode.match(/\w{2}/gm) || [];
    return arr.length ? arr.join('-') : "";
  }

  adminsEmailFormat(admins: {email: string}[]){
    let count = 2;
    let arr: string[] = [];

    admins.forEach((admin, index)=>{
      if (index < count) {
        arr.push(admin.email);
      }
      if (index === count) {
        arr.push("...");
      }
    });

    return arr.length ? arr.join(', ') : "";
  }

  renderPayrollGroupsItems(){
    let payrollGroups = this.props[STORE_EMPLOYER].currentEmployer.payrollGroups || [];
    if(payrollGroups.length){
      return payrollGroups.map((payrollGroup)=>
        <tr key={payrollGroup.id}>
          <td>{payrollGroup.name}</td>
          <td>{this.sortingCodeFormat(payrollGroup.bankAccount.sortingCode)+" "+payrollGroup.bankAccount.accountNumber}</td>
          <td>{this.adminsEmailFormat(payrollGroup.payrollAdmins)}</td>
          <td className={styles.actionCell}>
            <CustomSelect
              items={selectItems}
              onClickOption={(action)=>{this.selectActions(action, payrollGroup)}}
            />
          </td>
        </tr>
      );
    } else return <NoData columnSpan={4} />
  }

  renderPayrollGroups() {
    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
        <tr>
          <th className={styles.colPayrollName}>Payroll group name</th>
          <th>Bank account</th>
          <th>Payroll admin</th>
          <th className={styles.cuCol2}>Action</th>
        </tr>
        </thead>
        <tbody>
          {this.props[STORE_EMPLOYER].loadEmployerState?<TableLoader columnSpan={4} />:this.renderPayrollGroupsItems()}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <>
        <CenterBanner
          mainTitle={this.props[STORE_EMPLOYER].currentEmployer.name}
          subTitle={this.props[STORE_EMPLOYER].currentEmployer.clientId}
          tabs={EMPLOYER_DETAILS_TABS}
          activeTab={"payroll-groups"}
          onClickTab={this.onTabAction}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={"Payroll groups"}
            actionButtonName={this.props[STORE_EMPLOYER].loadEmployerState?"":"Add new"}
            actionButtonEvent={this.createNewPayrollGroup}
          />
          {this.renderPayrollGroups()}
        </div>
      </>
    );
  }

}
export default EmployerPayrollGroupsPage;