import { observable, action } from 'mobx';

import {
  STORE_UI,
  AuditFileType,
  MAX_AUDIT_DOWNLOAD_RETRIES,
  TIMEOUT_AUDIT_FULL_REQUESTS,
  TIMEOUT_AUDIT_RAL_REQUESTS,
  FORM_MODAL_WIDTH,
  ERROR_MODAL,
} from '../appConstants';
import { AdminModel } from '../graphql/models/admin';
import CreatePaychainAdminInput from '../graphql/mutations/inputs/CreatePaychainAdminInput';
import UpdatePaychainAdminInput from '../graphql/mutations/inputs/UpdatePaychainAdminInput';
import AdminService from '../services/adminService';

import { RootStore } from './RootStore';

export class AdminStore {
  @observable adminsList: Array<AdminModel> = [];
  @observable loadAdminsState = false;
  @observable fetchError = '';
  @observable auditLoadState = { Full: false, RejectedAndLeavers: false };
  auditConnectionCount = { Full: 0, RejectedAndLeavers: 0 };

  constructor(public rootStore: RootStore, public adminService: AdminService) {}

  @action clearErrorMsg() {
    this.fetchError = '';
  }

  @action async loadAdminsList() {
    this.loadAdminsState = true;
    const data = await this.adminService.getSuperAdminsList();
    this.loadAdminsState = false;
    this.adminsList = (data && data.allPaychainAdmins) || [];
  }

  @action async createSuperAdmin(superAdminData: CreatePaychainAdminInput) {
    try {
      await this.adminService.createSuperAdmin(superAdminData);
      this.fetchError = '';
      this.loadAdminsList();
    } catch (e: any) {
      this.fetchError = e.errors[0].message;
    }
  }

  @action async updateSuperAdmin(superAdminData: UpdatePaychainAdminInput) {
    try {
      await this.adminService.updateSuperAdmin(superAdminData);
      this.fetchError = '';
      this.loadAdminsList();
    } catch (e: any) {
      this.fetchError = e.errors[0].message;
    }
  }

  @action
  async deleteSuperAdmin(adminEmail: string) {
    try {
      await this.adminService.deleteSuperAdmin(adminEmail);
      this.rootStore[STORE_UI].setFetchError();
      this.loadAdminsList();
    } catch (e: any) {
      this.rootStore[STORE_UI].setFetchError(e.errors[0].message);
    }
  }

  downloadFile(filePath: string) {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
  }

  renderDownloadError() {
    this.rootStore[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: ERROR_MODAL,
      title: 'Error',
      props: {
        description: 'Something went wrong. Please try again later or contact the Service Desk.',
      },
    });
  }

  @action
  async getAuditFile(token: string, type: AuditFileType) {
    this.auditConnectionCount[type]++;

    const url = await this.adminService.getAuditFile(token);
    if (url !== null) {
      this.auditLoadState[type] = false;
      this.auditConnectionCount[type] = 0;
      this.downloadFile(url);
      return;
    }

    if (this.auditConnectionCount[type] < MAX_AUDIT_DOWNLOAD_RETRIES) {
      setTimeout(
        () => {
          return async () => {
            await this.getAuditFile(token, type);
          };
        },
        type === 'Full' ? TIMEOUT_AUDIT_FULL_REQUESTS : TIMEOUT_AUDIT_RAL_REQUESTS,
      );
    } else {
      this.auditLoadState[type] = false;
      this.auditConnectionCount[type] = 0;
      this.renderDownloadError();
    }
  }

  @action
  async generateAuditFile(type: AuditFileType) {
    this.auditLoadState[type] = true;
    try {
      const token = await this.adminService.generateAuditFile(type);
      this.getAuditFile(token, type);
    } catch (e: any) {
      this.auditConnectionCount[type] = 0;
      this.auditLoadState[type] = false;
      this.renderDownloadError();
    }
  }
}
