import React from 'react';
import { CenterBanner } from 'components';
import styles from './NotFoundPage.module.scss';

class NotFoundPage extends React.PureComponent<{}, {}>{
  render() {
    return (
      <>
        <CenterBanner
          mainTitle={"SalaryChain"}
        />
        <div className={styles.cContent}>
          Page Not Found
        </div>
      </>
    );
  }
}
export default NotFoundPage;