import React from 'react';
import { inject, observer } from 'mobx-react';
import { CenterBanner, TopContentBar, CustomSelect, TableLoader, NoData } from 'components';
import { EmployerAdminModel } from 'graphql/models/employer';
import { RouteComponentProps } from 'react-router';
import { EmployerStore, UIStore } from 'stores';

import {
  STORE_UI,
  CREATE_ADMIN_USER_MODAL,
  EDIT_ADMIN_USER_MODAL,
  EMPLOYER_TABS,
  FORM_MODAL_WIDTH, STORE_EMPLOYER, DELETE_ACCEPTANCE_MODAL, ERROR_MODAL
} from 'appConstants';

import { history } from 'index';
import styles from './EmployerAdminsPage.module.scss';
import classNames from 'classnames';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
}

const selectItems = [
  {title: 'Edit', value: 'edit'},
  {title: 'Delete', value: 'delete'}
];

@inject(STORE_UI, STORE_EMPLOYER)
@observer

class EmployerAdminsPage extends React.PureComponent<Props, {}>{

  componentDidMount(): void {
    const { employerId } = this.props.match.params;
    this.props[STORE_EMPLOYER].loadEmployer(employerId);
  }

  createNewAdminUser = (e: React.MouseEvent) => {
    e.preventDefault();

    let bankAccounts = this.props[STORE_EMPLOYER].currentEmployer.bankAccounts;
    if (bankAccounts && bankAccounts.length) {

      this.props[STORE_UI].openModal({
        width: FORM_MODAL_WIDTH,
        componentKey: CREATE_ADMIN_USER_MODAL,
        title: 'Add admin user',
        props: {
          employerId: this.props.match.params.employerId
        }
      });

    } else {

      this.props[STORE_UI].openModal({
        width: FORM_MODAL_WIDTH,
        componentKey: ERROR_MODAL,
        title: 'Error',
        props: {
          description: "Please create at least one bank account first"
        },
      });

    }

  };

  editUser = (employerAdmin: EmployerAdminModel) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: EDIT_ADMIN_USER_MODAL,
      title: 'Edit admin user',
      props: {
        employerId: this.props.match.params.employerId,
        employerAdminData: employerAdmin
      }
    });
  };

  deleteUser = (employerAdmin: EmployerAdminModel) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: DELETE_ACCEPTANCE_MODAL,
      title: 'Delete user',
      props: {
        description: "Are you sure you want to delete this admin user? You won’t be able to undo this."
      },
      eventProps: {
        onSubmit: () => this.onSubmitDeleteEmployerUser(employerAdmin.id, this.props.match.params.employerId)
      }
    });
  };

  onSubmitDeleteEmployerUser = async (employerUserId: string, employerId: string) => {
    this.props[STORE_UI].setLoadModalData();
    await this.props[STORE_EMPLOYER].deleteAdminUser(employerUserId, employerId);
    this.props[STORE_UI].setLoadModalData(false);

    if (!this.props[STORE_UI].fetchError) {
      this.props[STORE_UI].closeModal();
    }
  };

  selectActions(action: string, employerAdmin: EmployerAdminModel) {
    switch (action) {
      case 'edit':
        this.editUser(employerAdmin);
        break;
      case 'delete':
        this.deleteUser(employerAdmin);
        break;
      default:
    }
  }

  onTabAction = (val: string) => {
    const { employerId } = this.props.match.params;
    history.push("/admin/employers/"+employerId+"/"+val);
  };

  renderAdminUsersItems(){
    let employerAdmins = this.props[STORE_EMPLOYER].currentEmployer.employerUsers || [];
    if(employerAdmins.length){
      return employerAdmins.map((employerAdmin)=>
        <tr key={employerAdmin.id}>
          <td>{employerAdmin.firstName+' '+employerAdmin.lastName}</td>
          <td>{employerAdmin.email}</td>
          <td>
            <CustomSelect
              items={selectItems}
              onClickOption={(action)=>{this.selectActions(action, employerAdmin)}}
            />
          </td>
        </tr>
      );
    } else return <NoData columnSpan={3} />
  }

  showAdminUsers() {
    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
        <tr>
          <th className={styles.cuCol3}>Name</th>
          <th>Email</th>
          <th className={styles.cuCol2}>Action</th>
        </tr>
        </thead>
        <tbody>
          {this.props[STORE_EMPLOYER].loadEmployerState?<TableLoader columnSpan={3} />:this.renderAdminUsersItems()}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <>
        <CenterBanner
          mainTitle={this.props[STORE_EMPLOYER].currentEmployer.name}
          subTitle={this.props[STORE_EMPLOYER].currentEmployer.clientId}
          tabs={EMPLOYER_TABS}
          activeTab={"admins"}
          onClickTab={this.onTabAction}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={"Admin users"}
            actionButtonName={"Add new"}
            actionButtonEvent={this.createNewAdminUser}
          />
          {this.showAdminUsers()}
        </div>
      </>
    );
  }
}
export default EmployerAdminsPage;