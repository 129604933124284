import * as React from 'react';
import {CustomButton, Loader} from 'components/index';
import {inject, observer} from "mobx-react";
import {STORE_UI} from "appConstants";
import {UIStore} from "stores";
import classNames from "classnames";
import styles from './DeleteAcceptanceModal.module.scss';

export interface Props {
  [STORE_UI]: UIStore;
  onCancel: () => void;
  onSubmit: () => void;
  description: string;
}

@inject(STORE_UI)
@observer

export default class DeleteAcceptanceModal extends React.Component <Props, {}> {

  componentDidMount(): void {
    this.props[STORE_UI].setFetchError();
  }

  componentWillUnmount(): void {
    this.props.onCancel();
  }

  render() {
    return (
      <div className={classNames(styles.cPopupContent, styles.minHeight)}>
        <div>
          <div className={styles.cText}>
            {this.props.description}
          </div>
          <p className={classNames(styles.error, styles.errorOffset)}>{this.props[STORE_UI].fetchError}</p>
        </div>
        <div className={styles.cButtonRight}>
          {this.props[STORE_UI].loadModalData ? <Loader className={styles.loader}/> :
            <CustomButton size={"xxs"} autoFocus={true} onClick={this.props.onSubmit} buttonType="primary">Yes, delete</CustomButton>
          }
        </div>
      </div>
    );
  }
}
