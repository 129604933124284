import axios from 'axios';
import PayrollContributionInput from 'graphql/mutations/inputs/PayrollContributionsInput';

import { CsvUploadResult } from '../components/popups/CsvUploadedModal/CsvUploadModels';
import makeDefaultRequest from '../utils/api';
import {
  employersByPayrollAdminEmail,
  payrollGroup,
  payrollGroupName,
  newOrChangedEmployeeContributions,
  newOrChangedEmployeeContributionsCount,
  validatePayrollIntegrationCsvMapping,
} from '../graphql/queries/payrollsQuery';
import { EmployerModel } from '../graphql/models/employer';
import { EmployeeContributionModel } from '../graphql/models/employeeContribution';
import { PayrollGroupModel, PaymentCsvUploadResultModel } from '../graphql/models/payroll';
import {
  deletePaymentMutation,
  reviewEmployeeContributionsMutation,
  submitPaymentCsv,
  updatePayrollGroupPayPeriod,
  updatePayrollIntegrationConfig,
} from '../graphql/mutations/payrollsMutation';
import { UpdatePayrollGroupPayPeriodInput } from '../graphql/mutations/inputs/UpdatePayrollGroupPayPeriodInput';
import SubmitPaymentCsvInput from '../graphql/mutations/inputs/SubmitPaymentCsvInput';
import UpdatePayrollIntegrationConfigInput from '../graphql/mutations/inputs/UpdatePayrollIntegrationConfigInput';
import ValidatePayrollIntegrationCsvMappingInput from '../graphql/mutations/inputs/ValidatePayrollIntegrationCsvMappingInput';

export default class PayrollService {
  async getPayrollCompaniesList(payrollEmail: string) {
    const data = await makeDefaultRequest(employersByPayrollAdminEmail, { email: payrollEmail });
    const res = data.data as Record<'employersByPayrollAdminEmail', Array<EmployerModel>>;
    return res.employersByPayrollAdminEmail;
  }

  async getPayrollGroupById(payrollGroupId: string, requestedData?: string) {
    let queryName = payrollGroup;
    if (requestedData === 'groupName') {
      queryName = payrollGroupName;
    }
    const data = await makeDefaultRequest(queryName, { id: payrollGroupId });
    const res = data.data as Record<'payrollGroup', PayrollGroupModel>;
    return res.payrollGroup;
  }

  async updatePayrollGroupPayPeriod(payrollGroupPayPeriod: UpdatePayrollGroupPayPeriodInput) {
    return await makeDefaultRequest(updatePayrollGroupPayPeriod, { input: payrollGroupPayPeriod });
  }

  async getEmployeeNewContributions(payrollGroupId: string) {
    const data = await makeDefaultRequest(newOrChangedEmployeeContributions, { id: payrollGroupId });
    const res = data.data as Record<'newOrChangedEmployeeContributions', Array<EmployeeContributionModel>>;
    return res.newOrChangedEmployeeContributions;
  }

  async getEmployeeNewContributionsCount(payrollGroupId: string) {
    const data = await makeDefaultRequest(newOrChangedEmployeeContributionsCount, { id: payrollGroupId });
    const res = data.data as Record<'newOrChangedEmployeeContributions', Array<EmployeeContributionModel>>;
    return res.newOrChangedEmployeeContributions.length.toString();
  }

  async uploadPaymentFile(file: any, url: string) {
    const options = {
      headers: {
        'Content-Type': 'text/csv',
      },
    };
    return axios.put(url, file, options);
  }

  async submitPaymentCsv(input: SubmitPaymentCsvInput) {
    const response = await makeDefaultRequest(submitPaymentCsv, { input: input });
    const data = response.data as any;

    return data.submitPaymentCsv as CsvUploadResult;
  }

  async updatePayrollManualIntegration(configData: UpdatePayrollIntegrationConfigInput) {
    return await makeDefaultRequest(updatePayrollIntegrationConfig, { input: configData });
  }

  async validatePayrollSftpIntegrationCsvMapping(data: string, employerId: string) {
    const input: ValidatePayrollIntegrationCsvMappingInput = {
      employerId: employerId.toString(),
      fileContent: data,
    };
    const result = await makeDefaultRequest(validatePayrollIntegrationCsvMapping, { input });
    const container = result.data as Record<'validatePayrollIntegrationCsvMapping', PaymentCsvUploadResultModel>;
    return container.validatePayrollIntegrationCsvMapping;
  }

  async deletePaymentFile(id: string): Promise<boolean> {
    const result = await makeDefaultRequest(deletePaymentMutation, { id });
    return (result.data as { deletePayment: boolean })?.deletePayment;
  }

  async deletePauseContributions(payrollContributions: PayrollContributionInput): Promise<boolean> {
    const result = await makeDefaultRequest(reviewEmployeeContributionsMutation, payrollContributions );
    return (result.data as { deleteContribution: boolean })?.deleteContribution;
  }
}
