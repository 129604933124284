import axios, { AxiosResponse } from 'axios';

import {
  findEmployeeApprovalCsvInfoQuery,
  processEmployeeApprovalCsvFileMutation,
  validateHrSftpIntegrationCsvMapping,
} from '../graphql/queries/hrsQuery';
import { updateHrSftpIntegrationConfig } from '../graphql/mutations/hrsMutation';
import { findEmployeesQuery, countEmployeesQuery } from '../graphql/queries/hrsQuery';
import { updateEmployees } from '../graphql/mutations/hrsMutation';
import { HrSftpConfigInput } from '../graphql/mutations/inputs/UpdateHrSftpIntegrationInput';
import makeDefaultRequest from '../utils/api';
import {
  EmployeeModel,
  HrSftpIntegrationCsvMappingValidationInput,
  HrSftpIntegrationCsvMappingValidationContainer,
  FindEmployeeApprovalCsvInfo,
  EmployeeApprovalCsvUploadResult,
  FindEmployeeApprovalCsvInfoData, EmployeeApprovalCsvUploadResultData,
} from '../graphql/models/hr';
import EmployeeSearchCriteriaInput from '../graphql/mutations/inputs/EmployeeSearchCriteriaInput';
import UpdateEmployeeInput from '../graphql/mutations/inputs/UpdateEmployeeInput';

export default class HrService {
  async findEmployees(criteria: EmployeeSearchCriteriaInput, requestedData?: string) {
    let queryName = findEmployeesQuery;
    if (requestedData === 'countEmployees') {
      queryName = countEmployeesQuery;
    }
    const data = await makeDefaultRequest(queryName, { criteria: criteria });

    const { findEmployees } = data.data as Record<'findEmployees', Array<EmployeeModel>>;
    return findEmployees;
  }

  async updateEmployees(employees: Array<UpdateEmployeeInput>) {
    return await makeDefaultRequest(updateEmployees, { employees: employees });
  }

  async updateHrSftpIntegrationConfig(input: HrSftpConfigInput) {
    return await makeDefaultRequest(updateHrSftpIntegrationConfig, { input });
  }

  async validateHrSftpIntegrationCsvMapping(data: string, employerId: string) {
    const input: HrSftpIntegrationCsvMappingValidationInput = {
      employerId: employerId.toString(),
      fileContent: data,
    };
    const result = await makeDefaultRequest(validateHrSftpIntegrationCsvMapping, { input });
    return (result.data as HrSftpIntegrationCsvMappingValidationContainer).validateHrSftpIntegrationCsvMapping;
  }

  async findEmployeeFileInfo(employerId: number): Promise<FindEmployeeApprovalCsvInfo> {
    const { data } = await makeDefaultRequest(findEmployeeApprovalCsvInfoQuery, { employerId });
    return (data as FindEmployeeApprovalCsvInfoData).findEmployeeApprovalCsvInfo;
  }

  async uploadEmployeeFile(file: string | ArrayBuffer | null, url: string): Promise<AxiosResponse> {
    const options = {
      headers: {
        'Content-Type': 'text/csv',
      },
    };

    return axios.put(url, file, options);
  }

  async processEmployeeFile(employerId: number): Promise<EmployeeApprovalCsvUploadResult> {
    const { data } = await makeDefaultRequest(processEmployeeApprovalCsvFileMutation, { employerId });

    return (data as EmployeeApprovalCsvUploadResultData).processEmployeeApprovalCsvFile;
  }
}
