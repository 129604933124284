import { EmployeeStatus } from '../graphql/models/employeeStatus';

export const HR_NSU_STATUS_OPTIONS = [
  { value: EmployeeStatus.New, label: EmployeeStatus.New },
  { value: EmployeeStatus.Approved, label: EmployeeStatus.Approved },
  { value: EmployeeStatus.Rejected, label: EmployeeStatus.Rejected },
  { value: EmployeeStatus.Leaver, label: EmployeeStatus.Leaver },
];

export const HR_APPROVED_STATUS_OPTIONS = [
  { value: EmployeeStatus.Approved, label: EmployeeStatus.Approved },
  { value: EmployeeStatus.New, label: EmployeeStatus.New },
  { value: EmployeeStatus.Rejected, label: EmployeeStatus.Rejected },
  { value: EmployeeStatus.Leaver, label: EmployeeStatus.Leaver },
];

export const HR_REJECTED_STATUS_OPTIONS = [
  { value: EmployeeStatus.Rejected, label: EmployeeStatus.Rejected },
  { value: EmployeeStatus.Approved, label: EmployeeStatus.Approved },
];

export const HR_LEAVERS_STATUS_OPTIONS = [
  { value: EmployeeStatus.Leaver, label: EmployeeStatus.Leaver },
  { value: EmployeeStatus.Approved, label: EmployeeStatus.Approved },
];
