import { observable, computed, action, runInAction } from 'mobx';
import { Auth } from 'aws-amplify';

import { AuthRole, AuthState, STORE_ROUTER } from '../appConstants';

import { RootStore } from './RootStore';

interface AuthError {
  message: string;
}

export class UserStore {
  rootStore: RootStore;
  @observable authState: AuthState = AuthState.LOADING;
  @observable authError?: AuthError | null = null;
  @observable user: any = null;
  @observable userRoles = '';
  @observable currentRole = '';
  @observable homeUrl = '';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  setCurrentRole(path: string) {
    switch (path) {
      case '/admin':
        this.currentRole = AuthRole.ROLE_PAYCHAIN_ADMIN;
        this.homeUrl = '/admin/employers';
        break;
      case '/employer':
        this.currentRole = AuthRole.ROLE_EMPLOYER_ADMIN;
        this.homeUrl = '/employer/users-roles';
        break;
      case '/hr':
        this.currentRole = AuthRole.ROLE_HR_ADMIN;
        this.homeUrl = '/hr/new-sign-ups';
        break;
      case '/payroll':
        this.currentRole = AuthRole.ROLE_PAYROLL_ADMIN;
        this.homeUrl = '/payroll/home';
        break;
    }
  }

  @action
  async appInit() {
    await this.checkAuthState();
  }

  @action
  async checkAuthState() {
    try {
      const user = await Auth.currentAuthenticatedUser();

      runInAction(() => {
        this.user = user;
        this.user.username = user.signInUserSession.idToken.payload['paychain:email'];
        this.userRoles = user.signInUserSession.idToken.payload['paychain:roles'];
        this.authState = AuthState.SIGNED_IN;
      });
    } catch (error) {
      runInAction(() => {
        this.user = null;
        this.authState = AuthState.SIGN_IN;
        this.authError = null;
      });
    }
  }

  @action
  async updateToken() {
    try {
      const user = await Auth.currentAuthenticatedUser();

      runInAction(() => {
        user.refreshSession(user.signInUserSession.refreshToken, () => {
          this.user.username = user.signInUserSession.idToken.payload['paychain:email'];
          this.userRoles = user.signInUserSession.idToken.payload['paychain:roles'];
          this.rootStore[STORE_ROUTER].push('/');
        });
      });
    } catch (error) {
      // TODO: handle error scenario
    }
  }

  @action
  async signOut(authError?: AuthError) {
    const providerName =
      this.user !== null &&
      this.user.signInUserSession !== null &&
      this.user.signInUserSession.idToken.payload.identities
        ? this.user.signInUserSession.idToken.payload.identities[0].providerName
        : '';
    await Auth.signOut({ global: true });
    this.user = null;
    this.userRoles = '';
    this.authState = AuthState.SIGN_IN;
    this.authError = authError;

    if (providerName !== 'sso-saml-unu') {
      this.rootStore[STORE_ROUTER].push('/login');
    }
  }

  @action
  async federatedSignIn() {
    try {
      // eslint-disable-next-line
      // @ts-ignore
      await Auth.federatedSignIn({ provider: 'sso-saml-unu' });
    } catch (e) {
      // TODO: handle error scenario
    }
  }

  @computed get isAuthenticated(): boolean {
    return this.authState === AuthState.SIGNED_IN;
  }

  @computed get isLoading(): boolean {
    return this.authState === AuthState.LOADING;
  }

  @action
  signIn = (user: any) => {
    this.user = user;
    this.user.username = user.signInUserSession.idToken.payload['paychain:email'];
    this.userRoles = user.signInUserSession.idToken.payload['paychain:roles'];
    this.authState = AuthState.SIGNED_IN;
  };
}

export default UserStore;
