import * as React from 'react';
import {CustomField, CustomButton, Loader} from 'components/index';
import {Form, Formik, FormikActions} from "formik";
import {STORE_UI, STORE_EMPLOYER, VALIDATION_RULES } from "appConstants";
import {inject, observer} from "mobx-react";
import styles from './EditAdminUserModal.module.scss';
import {UIStore, EmployerStore} from "stores";
import classNames from "classnames";
import {RouteComponentProps} from "react-router";
import UpdateEmployerUserInput from "../../../graphql/mutations/inputs/UpdateEmployerUserInput";
import * as Yup from "yup";
import {EmployerAdminModel} from "../../../graphql/models/employer";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
  onCancel: () => void;
  employerId: string;
  employerAdminData: EmployerAdminModel;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: VALIDATION_RULES.firstName,
  lastName: VALIDATION_RULES.lastName,
});

@inject(STORE_UI, STORE_EMPLOYER)
@observer

export default class EditAdminUserModal extends React.Component <Props, {}> {

  componentDidMount(): void {
    this.props[STORE_EMPLOYER].clearErrorMsg();
  }

  onSubmit = async ({ firstName, lastName }: FormValues, actions: FormikActions<FormValues>) => {
    this.props[STORE_UI].setLoadModalData();
    actions.setStatus();
    const employerAdminData: UpdateEmployerUserInput = {
      id: this.props.employerAdminData.id,
      firstName: firstName,
      lastName: lastName,
      roles: ['EmployerAdmin']
    };
    await this.props[STORE_EMPLOYER].updateAdminUser(employerAdminData, this.props.employerId);
    this.props[STORE_UI].setLoadModalData(false);
    if(this.props[STORE_EMPLOYER].fetchError){
      actions.setStatus({ fetchError: this.props[STORE_EMPLOYER].fetchError })
    } else {
      this.props.onCancel();
    }
  };

  render() {
    const {firstName, lastName, email} = this.props.employerAdminData;
    return (
      <Formik
        initialValues={{firstName, lastName, email}}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.onSubmit}
      >{ form => {
        const isDisabled = !form.dirty || !form.isValid;
        return <Form>
          <div className={classNames(styles.cPopupContent, styles.minHeight)}>
            <div>
              <div className={styles.cControlsLeft}>
                <CustomField
                  name="firstName"
                  title="First name"
                  placeholder="Enter first name..."
                  form={form}
                  autoFocus
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
                <CustomField
                  name="lastName"
                  title="Last name"
                  placeholder="Enter last name..."
                  form={form}
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
              </div>

              <div className={styles.cControlsLeft}>
                <CustomField
                  disabled={true}
                  name="email"
                  title="Email"
                  placeholder="Enter email..."
                  form={form}
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
                <div className={styles.leftPosition} />
              </div>
              {form.status && <p className={styles.error}>{form.status.fetchError}</p>}
            </div>

            <div className={styles.cButtonRight}>
              {this.props[STORE_UI].loadModalData?<Loader className={styles.loader}/>:
                <CustomButton size={"xxs"} type="submit" buttonType="primary" disabled={isDisabled}>Save</CustomButton>
              }
            </div>
          </div>
        </Form>}}
      </Formik>
    );
  }
}
