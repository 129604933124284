export const updatePayrollGroupPayPeriod = ` 
  mutation updatePayrollGroupPayPeriod($input: UpdatePayrollGroupPayPeriodInput!){
    updatePayrollGroupPayPeriod(
      payrollGroupPayPeriod: $input
    ) {
      id
    }
  }
`;

export const submitPaymentCsv = ` 
  mutation submitPaymentCsv($input: SubmitPaymentCsvInput!){
    submitPaymentCsv(
      input: $input
    ) {
      resultItems {
        csvItem {
          name
          nino
          regularAmount
          singleAmount
        }
        errors
      }
      success
    }
  }
`;

export const updatePayrollIntegrationConfig = ` 
  mutation updatePayrollIntegrationConfig($input: UpdatePayrollIntegrationConfigInput){
    updatePayrollIntegrationConfig(
      input: $input
    ) {
      csvMapping {
       columnKey
       columnIndex
      }
    }
  }
`;

export const deletePaymentMutation = `
  mutation deletePayment($id: ID!)
  {
    deletePayment(id: $id)
  }
`;

export const reviewEmployeeContributionsMutation = `
  mutation reviewEmployeeContributions($payrollGroupId: ID!, $employeeContributionIds: [ID!]!)
  {
    reviewEmployeeContributions(payrollGroupId: $payrollGroupId, employeeContributionIds: $employeeContributionIds)
  }
`;
