import React from 'react';
import styles from './TableLoader.module.scss';

const TableLoader = ({ columnSpan }: { columnSpan: number } ) => {
  return (
    <tr>
      <td colSpan={columnSpan} className={styles.cTableLoader} >
        Loading...
      </td>
    </tr>
  );
}

export default TableLoader;
