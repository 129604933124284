import * as React from 'react';
import {inject, observer} from "mobx-react";
import {CustomButton, Loader} from 'components/index';
import {STORE_EMPLOYER, STORE_UI} from "../../../appConstants";
import {EmployerStore, UIStore} from "../../../stores";
import styles from './DeleteBankAccountModal.module.scss';
import classNames from "classnames";

export interface Props {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
  onCancel: () => void;
  employerId: string;
  bankAccountId: string;
}

@inject(STORE_UI, STORE_EMPLOYER)
@observer

export default class DeleteBankAccountModal extends React.Component <Props, {}> {
  componentDidMount(): void {
    this.props[STORE_EMPLOYER].clearErrorMsg();
  }

  deleteBankAccount = async () => {
    this.props[STORE_UI].setLoadModalData();
    await this.props[STORE_EMPLOYER].deleteBankAccount(this.props.bankAccountId, this.props.employerId);
    this.props[STORE_UI].setLoadModalData(false);
    if(!this.props[STORE_EMPLOYER].fetchError){
      this.props.onCancel();
    }
  };

  render() {
    return (
      <div className={classNames(styles.cPopupContent, styles.minHeight)}>
        <div>
          <div className={styles.cText}>
            Are you sure you want to delete this account? This action cannot be reversed.
          </div>
          <p className={styles.error}>{this.props[STORE_EMPLOYER].fetchError}</p>
        </div>
        <div className={styles.cButtonRight}>
          {this.props[STORE_UI].loadModalData ? <Loader className={styles.loader}/> :
            <CustomButton size={"xxs"} autoFocus={true} onClick={this.deleteBankAccount} buttonType="primary">Yes, delete</CustomButton>
          }
        </div>
      </div>
    );
  }
}
