import React from 'react';
import {RouteComponentProps} from 'react-router';
import {HeaderIntro, FooterIntro, CustomButton} from 'components';
import {inject, observer} from 'mobx-react';
import {UserStore} from 'stores';
import {STORE_USER} from 'appConstants';
import {history} from 'index';
import styles from './IntroPage.module.scss';

export interface Props extends RouteComponentProps<any> {
  [STORE_USER]: UserStore;
}

@inject(STORE_USER)
@observer

class IntroPage extends React.PureComponent<Props, {}> {

  superAdminContent() {
    return (
      <div className={styles.cMiddle}>
        <div className={styles.topText}>
          As a super admin user you can create and edit companies, add products, add company bank account details,
          and add and remove company admin users.
        </div>
        <div>
          <p className={styles.subTitle}>To add a new company:</p>
          <ol>
            <li>Click “Create new”, enter company name and payroll product</li>
            <li>Add company bank account details</li>
            <li>Invite main contact person from that company as an admin user - they will get an invite email and
              can then manage their own organisation
            </li>
          </ol>
        </div>
      </div>
    )
  }

  hrContent() {
    return (
      <div className={styles.cMiddle}>
        <div className={styles.topText}>
          You have been invited to join SalaryChain as a HR admin. This means you will be able to approve employees
          joining the schemes, and to verify they are eligible.
        </div>
        <div>
          <p className={styles.subTitle}>As a HR admin you will be expected to:</p>
          <ul>
            <li>Approve or reject employees who have signed up for the scheme, before each pay period. We will send you
              a reminder email about this
            </li>
            <li>Periodically remove employees who have left employment or no longer eligible for the scheme, by changing
              their status to “Leaver”
            </li>
          </ul>
        </div>
      </div>
    )
  }

  payrollContent() {
    return (
      <div className={styles.cMiddle}>
        <div className={styles.topText}>
          <p>
            You have been invited to join SalaryChain as a Payroll admin. This means you will be able to see all new
            events from employees - ie. new joiners and changes of contribution amounts.
          </p>
          <p className={styles.offsetTop}>
            You can also upload a CSV file of all individual contributions, when you do your regular payroll run, so
            that we can debit the money from the employer and invest on the behalf of each employee.
          </p>
        </div>
        <div>
          <p className={styles.subTitle}>As a Payroll admin you will be expected to:</p>
          <ul>
            <li>Copy the amounts from new joiners or change events, and put them into your payroll software before each
              payroll run
            </li>
            <li>Upload a CSV file containing all individual contributions to us after you’ve done the payroll run. You
              can map the CSV file on PayChain as you wish, but we will need all amounts to be in £ amounts - no
              percentages of salary.
            </li>
          </ul>
        </div>
      </div>
    )
  }

  employerContent() {
    return (
      <div className={styles.cMiddle}>
        <div className={styles.topText}>
          <p>
            SalaryChain is a solution to allow your employees contribute to savings products such as ISAs and investment
            accounts, directly from payroll.
          </p>
          <p className={styles.offsetTop}>
            As an admin user you can invite HR and payroll users, and create payroll groups. This shouldn’t take more
            than a couple of minutes.
          </p>
        </div>
        <div>
          <p className={styles.subTitle}>As an admin user, you can</p>
          <ul>
            <li>Create new users in your organisation, and assign HR, payroll admin roles to them</li>
            <li>Set up payroll groups to manage payroll of many different pay dates (and different bank accounts if
              needed)
            </li>
          </ul>
        </div>
      </div>
    )
  }

  renderMainContent() {
    let path = this.props.match.path;
    switch (path) {
      case '/admin/intro':
        return this.superAdminContent();

      case '/employer/intro':
        return this.employerContent();

      case '/payroll/intro':
        return this.payrollContent();

      case '/hr/intro':
        return this.hrContent();

      default:
        return this.superAdminContent();
    }

  }

  redirectToHome = () => {
    let keyName = 'firstLogin_' + this.props[STORE_USER].user.username;
    localStorage.setItem(keyName, "no");
    history.push("/");
  };

  render() {
    return (
      <div className={styles.pageWrapper}>
        <HeaderIntro/>
        <div className={styles.cPageContent}>
          <div className={styles.сTitle}>
            <img src="/images/check_board.svg" alt="Welcome to SalaryChain!"/>
            <h3>Welcome to SalaryChain!</h3>
          </div>

          {this.renderMainContent()}

          <div className={styles.cBottom}>
            <CustomButton
              onClick={this.redirectToHome}
              className={styles.cuBtnOffset}
              size={"xxs"}
              type="button"
              buttonType="rightArrow"
            >
              Continue
            </CustomButton>
          </div>
        </div>
        <FooterIntro/>
      </div>
    );
  }
}

export default IntroPage;