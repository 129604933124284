import * as React from 'react';
import * as Yup from 'yup';
import {CustomField, CustomButton, CustomFormSelect, Loader} from 'components';
import {Form, Formik, FormikActions, FieldArray} from "formik";
import {inject, observer} from "mobx-react";
import classNames from 'classnames';
import {SelectOption} from 'interfaces';
import {STORE_UI, STORE_EMPLOYER, VALIDATION_RULES} from "appConstants";
import {UIStore, EmployerStore} from "stores";
import {RouteComponentProps} from "react-router";
import styles from './CreateEmployerUserModal.module.scss';
import CreateEmployerUserInput from "../../../graphql/mutations/inputs/CreateEmployerUserInput";


interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  roles: RolesValues[];
}

interface RolesValues {
  id: string,
  label: string,
  value: string,
  selectedControl: string
}

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_EMPLOYER]: EmployerStore;
  onCancel: () => void;
  employerId: string;
  userName: string;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: VALIDATION_RULES.firstName,
  lastName: VALIDATION_RULES.lastName,
  email: VALIDATION_RULES.email,
  roles: Yup.array().of(
    Yup.object().shape({
        value: VALIDATION_RULES.role,
      }
    )
  )
});


@inject(STORE_UI, STORE_EMPLOYER)
@observer

export default class CreateEmployerUserModal extends React.Component <Props, {}> {

  initialValues: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    roles: [
        {id: "0", label: "", value: "", selectedControl: ""}
      ]
  };

  selectCount: number = 1;

  rolesList = [
      {id: "0", label: 'Admin', value: 'EmployerAdmin', selectedControl: ""},
      {id: "1", label: 'HR', value: 'HrAdmin', selectedControl: ""},
      {id: "2", label: 'Payroll', value: 'PayrollAdmin', selectedControl: ""}
    ];

  onSubmit = async ({ email, firstName, lastName, roles }: FormValues, actions: FormikActions<FormValues>) => {
    this.props[STORE_UI].setLoadModalData();
    actions.setStatus();

    let rolesArray = roles.map((role: any)=>role.value);
    const employerUserData: CreateEmployerUserInput = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      roles: rolesArray
    };

    await this.props[STORE_EMPLOYER].createAdminUser(employerUserData, this.props.employerId, this.props.userName);
    this.props[STORE_UI].setLoadModalData(false);

    if(this.props[STORE_EMPLOYER].fetchError){
      actions.setStatus({ fetchError: this.props[STORE_EMPLOYER].fetchError })
    } else {
      this.props.onCancel();
    }
  };

  selectHandler(name: string, value: SelectOption, form: any) {
    form.setFieldValue(name, value);

    let oldIndex = this.rolesList.findIndex(item => item.selectedControl === name);
    if(oldIndex!==-1){
      this.rolesList[oldIndex].selectedControl = "";
    }

    let currentIndex = this.rolesList.findIndex(item => item.value === value.value);
    this.rolesList[currentIndex].selectedControl = name;
  };

  getRolesList(name: string) {
    return this.rolesList.filter((item) => {
      return item.selectedControl === name || item.selectedControl === ""
    });
  }

  renderAddRoleButton(push: (obj: any) => void, roles: RolesValues[]) {
    if (this.selectCount < this.rolesList.length && roles[roles.length - 1].value !== "") {
      return (
        <a
          className={styles.cuLink}
          onClick={(e) => {
            e.preventDefault();
            push({id: ++this.selectCount, label: "", value: "", selectedControl: ""})
          }
          }
          href="/"
        >+ Add another</a>
      )
    } else return ;
  }

  renderRemoveRoleButton(remove: (index: number) => void, index: number, controlName: string) {
    if(this.selectCount > 1) {
      return (
        <a
          className={classNames(styles.deleteBtn)}
          onClick={(e) => {
            this.rolesList.forEach((item, index) => {
              if (item.selectedControl === controlName) {
                this.rolesList[index].selectedControl = "";
              }
            });
            e.preventDefault();
            remove(index);
            this.selectCount--;
          }
          }
          href="/"
        >-</a>
      )
    } else return ;
  }

  render() {
    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.onSubmit}
      >{ form => {
        const isDisabled = !form.dirty || !form.isValid;
        return <Form>
          <div className={classNames(styles.cPopupContent, styles.minHeight)}>
            <div>
              <div className={styles.cControlsLeft}>
                <CustomField
                  name="firstName"
                  title="First name"
                  placeholder="Enter first name..."
                  form={form}
                  autoFocus
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
                <CustomField
                  name="lastName"
                  title="Last name"
                  placeholder="Enter last name..."
                  form={form}
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
              </div>

              <div className={styles.cControlsLeft}>
                <CustomField
                  name="email"
                  title="Email"
                  placeholder="Enter email..."
                  form={form}
                  className={styles.leftPosition}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                />
                <div className={styles.leftPosition} />
              </div>
              <FieldArray name="roles">{({push, remove}) => (
                  <div>
                    <label htmlFor="roles[0]">
                      Role(s)
                    </label>

                    {
                      form.values.roles.map((role: any, index: number) => {
                        this.selectCount = 1+index;
                        const controlName = `roles[${index}]`;
                        let options = this.getRolesList(controlName);
                        return (
                          <div className={styles.cControlsLeft} key = {role.id}>
                            <CustomField
                              name = {controlName}
                              title=""
                              placeholder="Enter role..."
                              form={form}
                              component={CustomFormSelect}
                              defaultValue={options ? options.find(option => option.selectedControl === controlName) : ''}
                              className={styles.leftPositionPadd}
                              options={this.getRolesList(controlName)}
                              onSelect={(name, value)=>this.selectHandler(name, value, form)}
                              onBlur={()=>{form.setFieldTouched(controlName)}}
                              onKeyDown={(e:KeyboardEvent)=>{this.props[STORE_UI].handleFormKeyPress(e, form)}}
                            />
                            {this.renderRemoveRoleButton(remove, index, controlName)}
                          </div>
                        )
                      })
                    }

                    <div className={styles.cLinks}>
                      {this.renderAddRoleButton(push, form.values.roles)}
                    </div>
                  </div>
                )}
              </FieldArray>

              {form.status && <p className={styles.error}>{form.status.fetchError}</p>}
            </div>
            <div className={styles.cButtonRight}>
              {this.props[STORE_UI].loadModalData?<Loader className={styles.loader}/>:
              <CustomButton size={"xxs"} type="submit" buttonType="primary" disabled={isDisabled}>Create</CustomButton>
              }
            </div>
          </div>
        </Form>}}
      </Formik>
    );
  }
}
