import makeDefaultRequest from '../utils/api';
import {
  allEmployers,
  employerById,
  employerByEmployerAdminUsername,
  employerByIdPayrollGroups,
  employerUsersRolesByEmployerUsername,
  employerByIdUsersRoles,
  employerByIdBankAccounts,
  employerBankAccountsByEmployerUsername,
  employerByPayrollAdminEmailQuery,
  employerByHrEmailQuery,
  employerByIdName,
  employerNameByPayrollAdminEmail,
  integrationsByPayrollAdminEmail,
  employerHrSftpConfigByHrEmailQuery,
} from '../graphql/queries/employersQuery';
import {
  createEmployer,
  updateEmployer,
  deleteEmployer,
  createEmployerUser,
  updateEmployerUser,
  deleteEmployerUser,
  createBankAccount,
  updateBankAccount,
  deleteBankAccount,
  createPayrollGroup,
  updatePayrollGroup,
  deletePayrollGroup,
} from '../graphql/mutations/employersMutation';
import { EmployerModel } from '../graphql/models/employer';
import CreateEmployerInput from '../graphql/mutations/inputs/CreateEmployerInput';
import UpdateEmployerInput from '../graphql/mutations/inputs/UpdateEmployerInput';
import CreateEmployerUserInput from '../graphql/mutations/inputs/CreateEmployerUserInput';
import UpdateEmployerUserInput from '../graphql/mutations/inputs/UpdateEmployerUserInput';
import CreateBankAccountInput from '../graphql/mutations/inputs/CreateBankAccountInput';
import UpdateBankAccountInput from '../graphql/mutations/inputs/UpdateBankAccountInput';
import CreatePayrollGroupsInput from '../graphql/mutations/inputs/CreatePayrollGroupsInput';
import UpdatePayrollGroupsInput from '../graphql/mutations/inputs/UpdatePayrollGroupsInput';

export default class EmployerService {
  async getEmployersList() {
    const data = await makeDefaultRequest(allEmployers);
    return data.data as Record<'allEmployers', Array<EmployerModel>>;
  }

  async createEmployer(employerData: CreateEmployerInput) {
    return await makeDefaultRequest(createEmployer, { input: employerData });
  }

  async updateEmployer(employerData: UpdateEmployerInput) {
    return await makeDefaultRequest(updateEmployer, { input: employerData });
  }

  async deleteEmployer(employerId: string) {
    return await makeDefaultRequest(deleteEmployer, { employerId: employerId });
  }

  async getEmployer(employerId: string, requestedData?: string) {
    let queryName = employerById;
    switch (requestedData) {
      case 'employerUsers':
        queryName = employerByIdUsersRoles;
        break;

      case 'bankAccounts':
        queryName = employerByIdBankAccounts;
        break;

      case 'payrollGroups':
        queryName = employerByIdPayrollGroups;
        break;

      case 'employerName':
        queryName = employerByIdName;
        break;
    }

    const data = await makeDefaultRequest(queryName, { id: employerId });
    const { employer } = data.data as Record<'employer', EmployerModel>;
    return employer;
  }

  async getEmployerByPayrollAdminEmail(employerId: string, username: string, requestedData?: string) {
    let queryName = employerByPayrollAdminEmailQuery;

    switch (requestedData) {
      case 'employerName':
        queryName = employerNameByPayrollAdminEmail;
        break;

      case 'integrations':
        queryName = integrationsByPayrollAdminEmail;
        break;
    }

    const data = await makeDefaultRequest(queryName, {
      employerId: employerId,
      email: username,
    });
    const { employerByPayrollAdminEmail } = data.data as Record<'employerByPayrollAdminEmail', EmployerModel>;
    return employerByPayrollAdminEmail;
  }

  async getEmployerByHrEmail(username: string, requestedData?: string) {
    const queries: any = {
      hrSftpIntegrationConfig: employerHrSftpConfigByHrEmailQuery,
    };
    const query = queries[requestedData as string] || employerByHrEmailQuery;
    const data = await makeDefaultRequest(query, { email: username });
    const { employerByHrAdminEmail } = data.data as Record<'employerByHrAdminEmail', EmployerModel>;
    return employerByHrAdminEmail;
  }

  async createEmployerUser(employerId: string, employerUserData: CreateEmployerUserInput) {
    return await makeDefaultRequest(createEmployerUser, { employerId: employerId, input: employerUserData });
  }

  async updateEmployerUser(employerId: string, employerAdminData: UpdateEmployerUserInput) {
    return await makeDefaultRequest(updateEmployerUser, { input: employerAdminData, employerId: employerId });
  }

  async deleteEmployerUser(employerId: string, employerUserId: string) {
    return await makeDefaultRequest(deleteEmployerUser, { employerId: employerId, employerUserId: employerUserId });
  }

  async createBankAccount(employerId: string, bankAccountData: CreateBankAccountInput) {
    return await makeDefaultRequest(createBankAccount, { employerId: employerId, input: bankAccountData });
  }

  async updateBankAccount(bankAccountData: UpdateBankAccountInput) {
    return await makeDefaultRequest(updateBankAccount, { input: bankAccountData });
  }

  async deleteBankAccount(bankAccountId: string) {
    return await makeDefaultRequest(deleteBankAccount, { bankAccountId: bankAccountId });
  }

  async getEmployerByAdminEmail(adminEmail: string, requestedData?: string) {
    let queryName = employerByEmployerAdminUsername;
    switch (requestedData) {
      case 'employerUsers':
        queryName = employerUsersRolesByEmployerUsername;
        break;

      case 'bankAccounts':
        queryName = employerBankAccountsByEmployerUsername;
        break;

      case 'payrollGroups':
        queryName = employerByEmployerAdminUsername;
        break;
    }

    const data = await makeDefaultRequest(queryName, { email: adminEmail });
    const { employerByEmployerAdminEmail } = data.data as Record<'employerByEmployerAdminEmail', EmployerModel>;
    return employerByEmployerAdminEmail;
  }

  async createPayrollGroup(payrollGroupData: CreatePayrollGroupsInput) {
    return await makeDefaultRequest(createPayrollGroup, { input: payrollGroupData });
  }

  async updatePayrollGroup(payrollGroupData: UpdatePayrollGroupsInput) {
    return await makeDefaultRequest(updatePayrollGroup, { input: payrollGroupData });
  }

  async deletePayrollGroup(payrollGroupId: string) {
    return await makeDefaultRequest(deletePayrollGroup, { id: payrollGroupId });
  }
}
