import React from 'react';
import { inject, observer } from 'mobx-react';
import { CenterBanner, TableLoader, NoData } from 'components';
import { RouteComponentProps } from 'react-router';
import { PayrollStore, UIStore, UserStore } from 'stores';
import {
  STORE_UI,
  STORE_PAYROLL,
  STORE_USER
} from 'appConstants';
import styles from './PayrollCompaniesPage.module.scss';
import classNames from 'classnames';
import {history} from "../../index";


export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_PAYROLL]: PayrollStore;
  [STORE_USER]: UserStore;
}

@inject(STORE_UI, STORE_PAYROLL, STORE_USER)
@observer

class PayrollCompaniesPage extends React.PureComponent<Props, {}>{

  componentDidMount(): void {
    const {username} = this.props[STORE_USER].user;
    this.props[STORE_PAYROLL].loadPayrollCompaniesList(username);
  }
 
  renderPayrollsList(){
    if(this.props[STORE_PAYROLL].payrollCompaniesList.length){
      const imageUrl = "/images/arrow-btn.svg";
      return this.props[STORE_PAYROLL].payrollCompaniesList.map((item, index)=>
        <tr onClick={() => { history.push("/payroll/"+item.id+"/groups") }}  key={index}>
          <td>
            <strong>{item.name}</strong>
          </td>
          <td>
            <span className={styles.btnLink}>
              <img src={imageUrl} alt=""/>
            </span>
          </td>
        </tr>
      );
    } else return <NoData columnSpan={2} />
  }

  showPayrollUsers() {
    return (
      <table className={classNames(styles.cuTable)}>
        <thead>
        <tr>
          <th className={styles.companyCol}>Company name</th>
          <th className={styles.nextCol}>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
          {this.props[STORE_PAYROLL].loadPayrollCompanies ? <TableLoader columnSpan={2} /> : this.renderPayrollsList()}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <>
        <CenterBanner
          homeUrl={this.props[STORE_USER].homeUrl}
          mainTitle="Payroll"
        />
        <div className={styles.cContent}>
          <h2 className={styles.heading}>Choose company</h2>
          {this.showPayrollUsers()}
        </div>
      </>
    );
  }
}

export default PayrollCompaniesPage;