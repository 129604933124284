import * as React from 'react';
import {CustomButton, Loader} from 'components/index';
import {inject, observer} from "mobx-react";
import {STORE_UI, ADMIN_EMAIL} from "appConstants";
import {UIStore} from "stores";
import classNames from "classnames";
import styles from './ErrorModal.module.scss';

export interface Props {
  [STORE_UI]: UIStore;
  onCancel: () => void;
  onSubmit: () => void;
  description?: string;
  type?: string;
}

@inject(STORE_UI)
@observer

export default class ErrorModal extends React.Component <Props, {}> {

  componentDidMount(): void {
    this.props[STORE_UI].setFetchError();
  }

  renderCustomError() {
    if (this.props.type === "auditDownload") {
      return (
        <p className={styles.error}>
          Something went wrong. Please try again later or contact administrator by <a className={styles.errLink}
                                                                                      href={"mailto:" + ADMIN_EMAIL}>email</a>
        </p>
      )
    }
    return;
  }

  renderUserNotFoundError() {
    return (
      <p className={styles.error}>
        User not found, please contact support via <a className={styles.errLink}
                                                      href={"mailto:" + ADMIN_EMAIL}>email</a>
      </p>
    )
  }

  renderLoginError() {
    return (
      <p className={styles.error}>
        Unknown error occurred, please contact support via <a className={styles.errLink}
                                                              href={"mailto:" + ADMIN_EMAIL}>email</a>
      </p>
    )
  }

  render() {
    return (
      <div className={classNames(styles.cPopupContent, styles.minHeight)}>
        <div>
          <div className={styles.cText}>
            {this.props.description}
          </div>
          <p className={styles.error}>{this.props[STORE_UI].fetchError}</p>
          {this.props.type === "auditDownload" && this.renderCustomError()}
          {this.props.type === "login" && this.renderLoginError()}
          {this.props.type === "userNotFound" && this.renderUserNotFoundError()}
        </div>
        <div className={styles.cButtonRight}>
          {this.props[STORE_UI].loadModalData ? <Loader className={styles.loader}/> :
            <CustomButton
              size={"xxs"}
              autoFocus={true}
              onClick={this.props.onCancel}
              buttonType="primary"
            >
              Close
            </CustomButton>
          }
        </div>
      </div>
    );
  }
}
