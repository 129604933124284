import React from 'react';
import styles from "./NoData.module.scss";

const NoData = ({ columnSpan }: { columnSpan?: number } ) => {
  return (
    <tr>
      <td className={styles.noData} colSpan={columnSpan}>
        No Data
      </td>
    </tr>
  );
}

export default NoData;
