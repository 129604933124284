/* eslint-disable no-underscore-dangle */
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api/lib-esm/types';

export interface ErrorList {
  errors: Array<Error>;
}

enum EndpointsNames {
  Default = 'default',
  Admin = 'admin',
}

const findSetting = (settingName: string) => {
  return (API as any)._options.API.endpoints.find((x: any) => x.name === settingName).endpoint;
};
const makeRequest = async (query: string, data?: object) => {
  return (await API.graphql(graphqlOperation(query, data))) as GraphQLResult;
};

const makeDefaultRequest = async (query: string, data?: object) => {
  (API as any)._graphqlApi._options.aws_appsync_graphqlEndpoint = findSetting(EndpointsNames.Default);
  return makeRequest(query, data);
};

export const makeAdminRequest = async (query: string, data?: object) => {
  (API as any)._graphqlApi._options.aws_appsync_graphqlEndpoint = findSetting(EndpointsNames.Admin);
  return makeRequest(query, data);
};

export default makeDefaultRequest;
/* eslint-disable no-underscore-dangle */
