import React from 'react';

const Loader = ({ className }: { className?: string } ) => {
  // TODO: update component up to design
  return (
    <div className={className} >
      Loading...
    </div>
  );
}

export default Loader;
