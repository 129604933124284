import * as React from 'react';
import {Route, RouteComponentProps, Switch} from 'react-router';
import { Header, Footer } from 'components';
import { inject, observer } from 'mobx-react';
import {RouterStore, UIStore, UserStore} from 'stores';
import {AuthRole, STORE_ROUTER, STORE_UI, STORE_USER} from 'appConstants';
import UserService from "services/userService";

import EmployersPage from "pages/EmployersPage";

import EmployerAdminsPage from "pages/EmployerAdminsPage";
import EmployerBankAccountPage from 'pages/EmployerBankAccountPage';
import SuperAdminsPage from "pages/SuperAdminsPage";
import AdminDownloadsPage from "pages/AdminDownloadsPage";

import EmployerUsersRolesPage from "pages/EmployerUsersRolesPage";
import EmployerPayrollGroupsPage from "pages/EmployerPayrollGroupsPage";
import EmployerBankDetailsPage from 'pages/EmployerBankDetailsPage';

import HrSftpIntegrationPage from 'pages/HrSftpIntegrationPage';

import PayrollCompaniesPage from "pages/PayrollCompaniesPage";
import PayrollGroupsPage from "pages/PayrollGroupsPage";
import PayrollIntegrationPage from "pages/PayrollIntegrationPage";
import PayrollGroupNewContributionsPage from "pages/PayrollGroupNewContributionsPage";
import PayrollGroupPaymentsPage from "pages/PayrollGroupPaymentsPage";

import NotFoundPage from "pages/NotFoundPage";

import styles from './CommonContainer.module.scss';
import HrEmployeesPage from "pages/HrEmployeesPage";
import HrEmployeeListPage from 'pages/HrEmployeeListPage';

export interface Props extends RouteComponentProps<{}>{
  [STORE_UI]: UIStore;
  [STORE_USER]: UserStore;
  [STORE_ROUTER]: RouterStore;
}

const userService = new UserService();

@inject(STORE_UI, STORE_USER, STORE_ROUTER)
@observer

class CommonContainer extends React.PureComponent<Props, {}> {
  async componentDidMount() {
    await this.props[STORE_USER].checkAuthState();
    this.setChosenRole(this.props.match.path);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    this.setChosenRole(this.props.match.path);
  }

  setChosenRole(path: string) {
    this.props[STORE_USER].setCurrentRole(path);
  }

  logout = () =>{
    this.props[STORE_USER].signOut();
  };

  render() {
    const { userRoles } = this.props[STORE_USER];
    return (
      <div className={styles.pageWrapper}>
        <Header
          routPath = {this.props.match.path}
          showProfileMenu = {true}
          isPaychainAdmin = {userService.hasRole(userRoles, [AuthRole.ROLE_PAYCHAIN_ADMIN])}
          isEmployerAdmin = {userService.hasRole(userRoles, [AuthRole.ROLE_EMPLOYER_ADMIN])}
          isHRAdmin = {userService.hasRole(userRoles, [AuthRole.ROLE_HR_ADMIN])}
          isPayrollAdmin = {userService.hasRole(userRoles, [AuthRole.ROLE_PAYROLL_ADMIN])}
          logout={this.logout}
        />

        <Switch>
          {userService.hasRole(userRoles, [AuthRole.ROLE_EMPLOYER_ADMIN]) &&
          <Route exact path="/employer/users-roles" component={EmployerUsersRolesPage}/>}
          {userService.hasRole(userRoles, [AuthRole.ROLE_EMPLOYER_ADMIN]) &&
          <Route exact path="/employer/payroll-groups" component={EmployerPayrollGroupsPage}/>}
          {userService.hasRole(userRoles, [AuthRole.ROLE_EMPLOYER_ADMIN]) &&
          <Route exact path="/employer/bank-accounts" component={EmployerBankDetailsPage}/>}

          {userService.hasRole(userRoles, [AuthRole.ROLE_PAYCHAIN_ADMIN]) &&
          <Route exact path="/admin/employers" component={EmployersPage}/>}
          {userService.hasRole(userRoles, [AuthRole.ROLE_PAYCHAIN_ADMIN]) &&
          <Route path="/admin/employers/:employerId/admins" component={EmployerAdminsPage}/>}
          {userService.hasRole(userRoles, [AuthRole.ROLE_PAYCHAIN_ADMIN]) &&
          <Route path="/admin/employers/:employerId/bank-accounts" component={EmployerBankAccountPage}/>}
          {userService.hasRole(userRoles, [AuthRole.ROLE_PAYCHAIN_ADMIN]) &&
          <Route path="/admin/super-admins" component={SuperAdminsPage}/>}
          {userService.hasRole(userRoles, [AuthRole.ROLE_PAYCHAIN_ADMIN]) &&
          <Route path="/admin/downloads" component={AdminDownloadsPage}/>}

          {userService.hasRole(userRoles, [AuthRole.ROLE_HR_ADMIN]) &&
          <Route exact path="/hr/sftp-integration" component={HrSftpIntegrationPage}/>}

          {userService.hasRole(userRoles, [AuthRole.ROLE_HR_ADMIN]) &&
          <Route exact path="/hr/employees" component={HrEmployeeListPage}/>}

          {userService.hasRole(userRoles, [AuthRole.ROLE_HR_ADMIN]) &&
          <Route exact path="/hr/:tabId" component={HrEmployeesPage}/>}

          {userService.hasRole(userRoles, [AuthRole.ROLE_PAYROLL_ADMIN]) &&
          <Route exact path="/payroll/home" component={PayrollCompaniesPage}/>}
          {userService.hasRole(userRoles, [AuthRole.ROLE_PAYROLL_ADMIN]) &&
          <Route path="/payroll/:employerId/groups" component={PayrollGroupsPage}/>}
          {userService.hasRole(userRoles, [AuthRole.ROLE_PAYROLL_ADMIN]) &&
          <Route path="/payroll/:employerId/integration" component={PayrollIntegrationPage}/>}
          {userService.hasRole(userRoles, [AuthRole.ROLE_PAYROLL_ADMIN]) &&
          <Route path="/payroll/:employerId/group/:groupId/new-contributions"
                 component={PayrollGroupNewContributionsPage}/>}
          {userService.hasRole(userRoles, [AuthRole.ROLE_PAYROLL_ADMIN]) &&
          <Route path="/payroll/:employerId/group/:groupId/payments" component={PayrollGroupPaymentsPage}/>}
          <Route component={NotFoundPage}/>
        </Switch>

        <Footer />
      </div>
    );
  }
}

export default CommonContainer;
