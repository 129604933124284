import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  CenterBanner,
  CustomButton,
  CustomField,
  CustomFormSelect,
  Loader,
  TopContentBar
} from 'components';
import { Form, Formik, FormikActions, FormikProps } from "formik";
import { RouteComponentProps } from 'react-router';
import { EmployerStore, PayrollStore, UIStore, UserStore } from 'stores';
import { payrollIntegrationConfigModel } from 'graphql/models/employer';
import UpdatePayrollIntegrationConfigInput from "graphql/mutations/inputs/UpdatePayrollIntegrationConfigInput";

import {
  MAX_INDEX_OF_CSV_COLUMN_PAYROLL_INTEGRATION,
  PAYROLL_GROUPS_TABS,
  STORE_EMPLOYER,
  STORE_PAYROLL,
  STORE_UI,
  STORE_USER,
  VALIDATION_RULES
} from 'appConstants';

import { history } from 'index';
import styles from './PayrollIntegrationPage.module.scss';
import { SelectOption } from "../../interfaces";

import * as Yup from "yup";

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_PAYROLL]: PayrollStore;
  [STORE_EMPLOYER]: EmployerStore;
  [STORE_USER]: UserStore;
  imgUrl?: string;
}

interface FormValues {
  payrollIntegrationMethod: SelectOption;
  name: SelectOption,
  nino: SelectOption,
  regularAmount: SelectOption,
  lumpSumAmount: SelectOption,
  publicKey: string,
}

@inject(STORE_UI, STORE_PAYROLL, STORE_EMPLOYER, STORE_USER)
@observer

class PayrollIntegrationPage extends React.Component<Props, {}> {
  constructor(props: any) {
    super(props);
    this.createPositionOptions();

    this.initialValues = {
      payrollIntegrationMethod: this.methodOptions[0],
      name: this.positionOptions[0],
      nino: this.positionOptions[1],
      regularAmount: this.positionOptions[2],
      lumpSumAmount: this.positionOptions[3],
      publicKey: "",
    };

    this.currentValues = this.initialValues;
    this.props[STORE_PAYROLL].setLoadIntegrationsPage(true);
  }

  csvColumnIndexValidation = (fieldName: string) => Yup.object().shape({
    value: Yup.string().nullable().test(`${fieldName} validation`, 'must be unique', (value) => {
      const values = Object.keys(this.currentValues).filter(x => x !== fieldName).map(x => String((this.currentValues as any)[x].value || ''));
      return values.indexOf(value as string) === -1;
    })
  });

  VALIDATION_SCHEMA_SFTP = Yup.object().shape({
    publicKey: VALIDATION_RULES.publicKey,
    name: this.csvColumnIndexValidation('name'),
    nino: this.csvColumnIndexValidation('nino'),
    regularAmount: this.csvColumnIndexValidation('regularAmount'),
    lumpSumAmount: this.csvColumnIndexValidation('lumpSumAmount'),
  });

  VALIDATION_SCHEMA_MANUAL = Yup.object().shape({
    name: this.csvColumnIndexValidation('name'),
    nino: this.csvColumnIndexValidation('nino'),
    regularAmount: this.csvColumnIndexValidation('regularAmount'),
    lumpSumAmount: this.csvColumnIndexValidation('lumpSumAmount'),
  });

  state = {
    validationSchema: this.VALIDATION_SCHEMA_SFTP
  };
  breadCrumbs = [{ title: "", linkTo: "" }];
  positionOptions: SelectOption[] = [];
  methodOptions: SelectOption[] = [
    { 'label': 'CSV Upload - SFTP', 'value': 'SFTP' },
    { 'label': 'Manual', 'value': 'Manual' }
  ];

  initialValues: FormValues;
  isCsvData: boolean | undefined = false;
  username: string = "";
  currentValues: FormValues;

  async componentDidMount() {
    const { employerId } = this.props.match.params;
    const { username } = this.props[STORE_USER].user;
    await this.props[STORE_EMPLOYER].loadEmployerByPayrollAdminEmail(employerId, username, "integrations");

    const connectionMethod = this.props[STORE_EMPLOYER].currentEmployer.payrollIntegrationConfig?.connectionMethod || 'SFTP'

    const { clientId } = this.props[STORE_EMPLOYER].currentEmployer;
    this.username = clientId ? "payroll-" + clientId : "";
    let csvMappingData: payrollIntegrationConfigModel | undefined = this.props[STORE_EMPLOYER].currentEmployer.payrollIntegrationConfig;
    this.isCsvData = csvMappingData && csvMappingData.csvMapping && !!csvMappingData.csvMapping.length;
    this.setInitialValues(connectionMethod, csvMappingData);
    this.props[STORE_PAYROLL].setLoadIntegrationsPage();
  }

  setInitialValues(connectionMethod: string, csvMappingData: payrollIntegrationConfigModel | undefined) {
    const connectionMethodSelect = this.methodOptions.find(o => o.value === connectionMethod) ?? this.methodOptions[0]

    this.initialValues.payrollIntegrationMethod = connectionMethodSelect

    if (csvMappingData && csvMappingData.csvMapping) {
      csvMappingData.csvMapping.forEach((item) => {
        if (item.columnKey === "name" || item.columnKey === "nino" || item.columnKey === "regularAmount" || item.columnKey === "lumpSumAmount") {
          this.initialValues[item.columnKey] = { value: item.columnIndex.toString(), label: item.columnIndex.toString() }
        }
      });
    }

    if (csvMappingData && csvMappingData.sftpSettings && csvMappingData.sftpSettings && csvMappingData.sftpSettings.publicKey) {
      this.initialValues.publicKey = csvMappingData.sftpSettings.publicKey;
    }
  }

  createPositionOptions() {
    for (let i = 1; i <= MAX_INDEX_OF_CSV_COLUMN_PAYROLL_INTEGRATION; i++) {
      this.positionOptions.push({ 'label': i.toString(), 'value': i.toString() });
    }
  }

  onTabAction = (val: string) => {
    const { employerId } = this.props.match.params;
    history.push("/payroll/" + employerId + "/" + val);
  };

  onSubmit = async (formValues: FormValues, actions: FormikActions<FormValues>) => {
    const { name, nino, lumpSumAmount, regularAmount, payrollIntegrationMethod: { value: connectionMethod } } = formValues;

    this.props[STORE_PAYROLL].setLoadIntegrations(true);
    actions.setStatus();

    const { employerId } = this.props.match.params;

    const csvMapping = [
      { columnKey: "name", columnIndex: +name.value },
      { columnKey: "nino", columnIndex: +nino.value },
      { columnKey: "lumpSumAmount", columnIndex: +lumpSumAmount.value },
      { columnKey: "regularAmount", columnIndex: +regularAmount.value }
    ];

    let configData: UpdatePayrollIntegrationConfigInput = { employerId, connectionMethod, csvMapping };

    if (formValues.payrollIntegrationMethod.value === "SFTP") {
      configData.sftpSettings = { publicKey: formValues.publicKey, username: this.username };
    }

    await this.props[STORE_PAYROLL].updatePayrollManualIntegration(configData);

    this.props[STORE_PAYROLL].setLoadIntegrations(false);

    if (this.props[STORE_PAYROLL].fetchError) {
      actions.setStatus({ fetchError: this.props[STORE_PAYROLL].fetchError })
    } else {
      actions.resetForm(formValues);
      this.isCsvData = true;
    }

    this.forceUpdate();
  };

  testMappingHandler = async () => {
    const { id } = this.props[STORE_EMPLOYER].currentEmployer;
    await this.props[STORE_PAYROLL].handleMappingTest(id)
  };

  showFormButtons(form: any) {
    const isDisabled = !form.dirty || !form.isValid
    return (
      <div className={styles.btnsContainer}>
        <div className={styles.btnsWrap}>
          <CustomButton
            buttonType='primary'
            type='button'
            onClick={this.testMappingHandler}
            disabled={false}
          >
            Test mapping
          </CustomButton>

          <CustomButton
            buttonType='primary'
            type='submit'
            disabled={isDisabled}
          >
            {this.isCsvData ? "Update" : "Save"}
          </CustomButton>
        </div>
      </div>
    )
  }

  showSFTPText() {
    let sftpUrl = "";
    const { payrollIntegrationConfig } = this.props[STORE_EMPLOYER].currentEmployer;
    if (payrollIntegrationConfig) {
      sftpUrl = payrollIntegrationConfig.sftpUrl;
    }

    return (
      <>
        <div className={styles.cTextBox}>
          <label htmlFor="">SFTP address</label>
          <div title={sftpUrl} className={styles.textBox}>{sftpUrl}</div>
        </div>

        <div className={styles.cTextBox}>
          <label htmlFor="">SFTP username</label>
          <div className={styles.textBox}>{this.username}</div>
        </div>
      </>
    )
  }

  showSFTPInputs(form: FormikProps<FormValues>) {
    return (
      <div className={styles.inputsRow}>
        <div className={styles.cTextBox}>
          <CustomField
            name="publicKey"
            title="Public key"
            placeholder="Enter public key..."
            form={form}
            className={styles.leftPosition}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            onKeyDown={(e: KeyboardEvent) => {
              this.props[STORE_UI].handleFormKeyPress(e, form)
            }}
          />
        </div>

        <div className={styles.cTextBox} />
      </div>
    )
  }

  setValidationSchema(methodType: string) {
    if (methodType === "SFTP") {
      this.setState({ validationSchema: this.VALIDATION_SCHEMA_SFTP });
    } else {
      this.setState({ validationSchema: this.VALIDATION_SCHEMA_MANUAL });
    }
  }

  setMethodVal(name: string, value: SelectOption, form: FormikProps<FormValues>) {
    form.setFieldValue(name, value);
    this.setValidationSchema(value.value);
  }

  showPayrollIntegrationForm() {
    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={this.state.validationSchema}
        onSubmit={this.onSubmit}
      >
        {form => {
          this.currentValues = form.values;
          return (
            <Form className={styles.formWrap}>
              <div className={styles.topFormPart}>
                <div className={styles.inputsRow}>
                  <div className={styles.cTextBox}>
                    <CustomField
                      name="payrollIntegrationMethod"
                      title="Connection method"
                      placeholder="Choose method..."
                      form={form}
                      component={CustomFormSelect}
                      className={styles.rowItem}
                      options={this.methodOptions}
                      onSelect={(name, value) => {
                        this.setMethodVal(name, value, form)
                      }}
                      onBlur={() => {
                        form.setFieldTouched("payrollIntegrationMethod")
                      }}
                      onKeyDown={(e: KeyboardEvent) => {
                        this.props[STORE_UI].handleFormKeyPress(e, form)
                      }}
                    />
                  </div>
                  {form.values.payrollIntegrationMethod.value === 'SFTP' && this.showSFTPText()}
                </div>
                {form.values.payrollIntegrationMethod.value === 'SFTP' && this.showSFTPInputs(form)}
              </div>
              <TopContentBar
                mainTitle={"CSV mapping"}
              />

              <div className={styles.mappingWrap}>
                <div className={styles.formCol}>
                  <div className={styles.selectGroup}>
                    <span>Member name</span>
                    <CustomField
                      name="name"
                      title="CSV position"
                      placeholder=""
                      form={form}
                      component={CustomFormSelect}
                      className={styles.selectSmall}
                      options={this.positionOptions}
                      onSelect={form.setFieldValue}
                      onBlur={() => {
                        form.setFieldTouched("name")
                      }}
                      onKeyDown={(e: KeyboardEvent) => {
                        this.props[STORE_UI].handleFormKeyPress(e, form)
                      }}
                      instantlyShowErrorMsg={true}
                    />
                  </div>

                  <div className={styles.selectGroup}>
                    <span>NI number</span>
                    <CustomField
                      name="nino"
                      title="CSV position"
                      placeholder=""
                      form={form}
                      component={CustomFormSelect}
                      className={styles.selectSmall}
                      options={this.positionOptions}
                      onSelect={form.setFieldValue}
                      onBlur={() => {
                        form.setFieldTouched("nino")
                      }}
                      onKeyDown={(e: KeyboardEvent) => {
                        this.props[STORE_UI].handleFormKeyPress(e, form)
                      }}
                      instantlyShowErrorMsg={true}
                    />
                  </div>

                </div>

                <div className={styles.formCol}>
                  <div className={styles.selectGroup}>
                    <span>Regular amount</span>
                    <CustomField
                      name="regularAmount"
                      title="CSV position"
                      placeholder=""
                      form={form}
                      component={CustomFormSelect}
                      className={styles.selectSmall}
                      options={this.positionOptions}
                      onSelect={form.setFieldValue}
                      onBlur={() => {
                        form.setFieldTouched("regularAmount")
                      }}
                      onKeyDown={(e: KeyboardEvent) => {
                        this.props[STORE_UI].handleFormKeyPress(e, form)
                      }}
                      instantlyShowErrorMsg={true}
                    />
                  </div>

                  <div className={styles.selectGroup}>
                    <span>Lump sum amount</span>
                    <CustomField
                      name="lumpSumAmount"
                      title="CSV position"
                      placeholder=""
                      form={form}
                      component={CustomFormSelect}
                      className={styles.selectSmall}
                      options={this.positionOptions}
                      onSelect={form.setFieldValue}
                      onBlur={() => {
                        form.setFieldTouched("lumpSumAmount")
                      }}
                      onKeyDown={(e: KeyboardEvent) => {
                        this.props[STORE_UI].handleFormKeyPress(e, form)
                      }}
                      instantlyShowErrorMsg={true}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.errorBox}>
                {form.status && <p className={styles.error}>{form.status.fetchError}</p>}
              </div>
              <div className={styles.cButtons}>
                {this.props[STORE_PAYROLL].loadInstructions ? <Loader /> : this.showFormButtons(form)}
              </div>
            </Form>
          )
        }}
      </Formik>
    );
  }

  render() {
    this.breadCrumbs = [{ title: this.props[STORE_EMPLOYER].currentEmployer.name, linkTo: "" }];
    return (
      <>
        <CenterBanner
          mainTitle={"Payroll - " + this.props[STORE_EMPLOYER].currentEmployer.name}
          tabs={PAYROLL_GROUPS_TABS}
          activeTab={"integration"}
          onClickTab={this.onTabAction}
          breadCrumbs={this.breadCrumbs}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={"Payroll integration"}
          />
          {this.props[STORE_PAYROLL].loadInstructionsPage ?
            <div className={styles.cLoader}><Loader /></div> : this.showPayrollIntegrationForm()}
        </div>
      </>
    );
  }
}

export default PayrollIntegrationPage;
