import makeDefaultRequest, { makeAdminRequest } from '../utils/api';
import { allPaychainAdmins, getAuditFile } from '../graphql/queries/adminsQuery';
import {
  createPaychainAdmin,
  updatePaychainAdmin,
  deletePaychainAdmin,
  generateAuditFile,
} from '../graphql/mutations/adminsMutation';
import CreatePaychainAdminInput from '../graphql/mutations/inputs/CreatePaychainAdminInput';
import UpdatePaychainAdminInput from '../graphql/mutations/inputs/UpdatePaychainAdminInput';
import { AdminModel } from '../graphql/models/admin';
import { AuditFileType } from '../appConstants';

export default class EmployerService {
  async getSuperAdminsList() {
    const data = await makeAdminRequest(allPaychainAdmins);
    return data.data as Record<'allPaychainAdmins', Array<AdminModel>>;
  }

  async createSuperAdmin(superAdminData: CreatePaychainAdminInput) {
    return await makeAdminRequest(createPaychainAdmin, { input: superAdminData });
  }

  async updateSuperAdmin(superAdminData: UpdatePaychainAdminInput) {
    return await makeAdminRequest(updatePaychainAdmin, { input: superAdminData });
  }

  async deleteSuperAdmin(superAdminEmail: string) {
    return await makeAdminRequest(deletePaychainAdmin, { email: superAdminEmail });
  }

  async generateAuditFile(type: AuditFileType) {
    const data = await makeDefaultRequest(generateAuditFile, { type: type });
    const container = data.data as Record<'generateAuditFile', string>;
    return container.generateAuditFile;
  }

  async getAuditFile(token: string) {
    const data = await makeDefaultRequest(getAuditFile, { token: token });
    const container = data.data as Record<'getAuditFile', string>;
    return container.getAuditFile;
  }
}
